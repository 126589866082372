import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {messages} from "@/messages";
import {createI18n} from 'vue-i18n';
import mitt from "mitt";


const emitter = mitt();
const i18n = createI18n({
    locale: 'nl-BE', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

const mixins = {
    methods: {
        unsignedRandom(maxval, i) {
            return Math.abs((Math.floor(Math.random() * Math.pow(2, 32))
                ^ (this.eventseed[i % 4] >>> (i % 23)))
                % (maxval + 1));
        },

        randomString(length, chars) {
            var result = '';
            for (var i = length; i > 0; --i)
                result += chars[this.unsignedRandom(chars.length - 1, i)];
            return result;
        },
        setOpacity(rgb, opacity) {
            rgb.a = opacity
            let rgba = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
            return rgba
        },
        hexToRgb(hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                a: 1
            } : null;
        },
        adjustColor(color, amount) {
            return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
        },
        getColorByVitamin(vitamin) {
            let color = 'koraalrood'
            switch (vitamin.toLowerCase()) {
                case 'energiebronnen':
                case 'energy sources':
                case 'job resources':
                case 'ressources énergétiques':
                case 'eb':
                    color = 'groen';
                    break;
                case 'uitdagingen':
                case 'challenges':
                case 'défis':
                case 'uit':
                    color = 'turkoois';
                    break;
                case 'hinderpalen':
                case 'obstacles':
                case 'hin':
                    color = 'koraalrood';
                    break;
                case 'persoonlijkehulpbronnen':
                case 'pers. hulpbronnen':
                case 'pers. bronnen':
                case 'persoonlijke hulpbronnen':
                case 'personal resources':
                case 'Ressources personnelles':
                case 'phb':
                    color = 'okergeel';
                    break;
                case 'motivatie':
                case 'motivation':
                case 'mot':
                    color = 'paars';
                    break;
                case 'attitudes':
                case 'at':
                    color = 'blauw';
                    break;
                case 'prestatie':
                case 'performance':
                case 'pr':
                    color = 'magenta';
                    break;
                case 'unhealthiness':
                case 'insalubrité':
                case 'ongezondheid':
                case 'ong':
                    color = 'groengrijs';
                    break;
                case 'betrokkenheid':
                case 'engagement':
                case 'eng':
                    color = 'blauw-50';
                    break;
                case 'welzijn':
                case 'wellbeing':
                case 'wb':
                    color = 'groen-200';
                    break;

            }
            return color;
        },
        getColorCode(colorName) {
            let color = '#000';
            switch (colorName) {
                case 'groen':
                    color = '#BAE193';
                    break;
                case 'groen-50':
                    color = '#E1F7CC';
                    break;
                case 'groen-200' :
                    color = '#91BF62';
                    break;
                case 'turkoois' :
                    color = '#7BCDC7';
                    break;
                case 'turkoois-50':
                    color = '#C9EDEA';
                    break;
                case 'turkoois-200' :
                    color = '#32A299';
                    break;
                case 'paars':
                    color = '#B99FD1';
                    break;
                case 'paars-50':
                    color = '#E3D8ED';
                    break;
                case 'paars-200' :
                    color = '#8B61B2';
                    break;
                case 'magenta':
                    color = '#F0A5C4';
                    break;
                case 'magenta-25':
                    color = '#FFF2F7';
                    break;
                case 'magenta-50':
                    color = '#F9DBE7';
                    break;
                case 'magenta-200':
                    color = '#C9638D';
                    break;
                case 'blauw' :
                    color = '#99C7EF';
                    break;
                case 'blauw-50':
                    color = '#D6E9F9';
                    break;
                case 'blauw-200' :
                    color = '#3C85C6';
                    break;
                case 'koraalrood':
                    color = '#FF9B87';
                    break;
                case 'koraalrood-50':
                    color = '#FFD7CF';
                    break;
                case 'koraalrood-200':
                    color = '#E26C54';
                    break;
                case 'okergeel':
                    color = '#FAD173';
                    break;
                case 'okergeel-25':
                    color = '#FFF7E5';
                    break;
                case 'okergeel-50':
                    color = '#FDEDC7';
                    break;
                case 'okergeel-200':
                    color = '#D8A93D';
                    break;
                case 'groengrijs' :
                    color = '#9FBEB6';
                    break;
                case 'groengrijs-50':
                    color = '#D8E5E2';
                    break;
                case 'groengrijs-200':
                    color = '#629588';
                    break;
            }
            return color;
        },
    },
    computed: {
        isAuthenticated() {
            //needs to go through store because localstorage is not reactive
            return this.$store.getters.isAuthenticated
        },
        mainCompany() {
            return this.$store.getters.getMainCompany || {}
        },
        user() {
            return this.$store.getters.getUser
        },
        axios() {
            return this.$store.getters.getAxios
        }
    },
    data() {
        return {
            seedevents:0,
            eventseed: [ ((new Date()).getTime()*41 ^ (Math.random() * Math.pow(2,32)))>>>0,
                ((new Date()).getTime()*41 ^ (Math.random() * Math.pow(2,32)))>>>0,
                ((new Date()).getTime()*41 ^ (Math.random() * Math.pow(2,32)))>>>0,
                ((new Date()).getTime()*41 ^ (Math.random() * Math.pow(2,32)))>>>0 ],
            mitt: emitter,
            sitesettings: {
                minimumSegmentCount: 3
            },
            colors: [
                'blauw',
                'turkoois',
                'groengrijs',
                'groen',
                'paars',
                'magenta',
                'koraalrood',
                'okergeel',
            ],
        }
    }
}


const app = createApp(App);
app.mixin(mixins);
app.use(store)
    .use(router)
    .use(i18n)
    .mount('#app')

if (localStorage.lang) {
    i18n.global.locale = localStorage.lang
} else {
    i18n.global.locale = 'en'
}



