<template>
  <form @submit.prevent="submitMessage">
    <select
      v-model="teamid"
      class="w-100 mb-3"
      v-if="type === 'team' && !currentTeam"
      name=""
      id=""
    >
      <option disabled value="">{{ $t("message.choose_team") }}</option>
      <option v-bind:key="team" v-for="team in teams" :value="team.id">
        {{ team.title }}
      </option>
    </select>
    <tiptap class="mb-4" v-model="body"></tiptap>
    <!--    <textarea placeholder="Description ..." name="" id="" cols="30" rows="5" v-model="body"-->
    <!--      class="w-100 mb-4"></textarea>-->
    <div class="mb-4 d-flex justify-content-end align-items-center">
      <div class="col-auto">
        <div
          v-for="tag in tags"
          :key="tag.id"
          class="mx-2 d-inline-block bgc-theme c-200 rounded-pill py-2 px-4"
        >
          {{ tag.tagtext }}
          <button @click="removeTag(tag.id)" class="border-0 bg-transparent">
            <img src="../../assets/icons/icon-navigation-close.svg" alt="" />
          </button>
        </div>
      </div>
      <select-search
        class="mx-2 py-2"
        :ajax="true"
        :query="tagQuery"
        :label="'tagtext'"
        :value="'id'"
        :placeholder="$t('message.add_tag')"
        @selectOption="addTag"
      ></select-search>
      <!--                  <button class="btn-sm btn me-3">
                          <img src="../assets/icons/icon-navigation-add.svg" alt="">
                          Tag
                        </button>-->
      <button
        class="btn btn-primary"
        :class="!isValid && 'disabled'"
        :disabled="!isValid"
      >
        <div
          v-show="loading"
          style="height: 12px; width: 12px"
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
        <span v-show="!loading" class="text-sentence">{{ $t("message.save") }}</span>
      </button>
    </div>
  </form>
</template>

<script>
import qs from "qs";
import SelectSearch from "@/views/components/simple/SelectSearch";
import Tiptap from "@/views/components/simple/Tiptap";

export default {
  components: { Tiptap, SelectSearch },
  props: {
    type: {
      default() {
        return "team";
      },
    },
    defer: {
      default() {
        return false;
      },
    },
    id: null,
    checkup: null,
    currentTeam: null,
  },
  created() {
    if (this.currentTeam) {
      this.teamid = this.currentTeam;
    }
    this.getManagedTeams();
    if (this.id) {
      this.getMessage();
    }
  },
  emits: ["submitMessageForm"],
  data() {
    return {
      loading: false,
      teams: [],
      body: "",
      teamid: "",
      tags: [],
    };
  },
  computed: {
    isValid() {
      return this.replacedbody && (this.type == "team" ? this.teamid : true);
    },
    replacedbody() {
      return this.body.replace(/(<(?!\/)[^>]+>)+(<\/[^>]+>)+/, "");
    },
    isbase() {
      return this.type === "company";
    },
    tagIDs() {
      return this.tags.map((entity) => entity.id);
    },
    tagQuery() {
      let params = [];
      for (const id of this.tagIDs) {
        params.push("id_nin=" + id);
      }

      return "/tags?" + params.join("&");
    },
  },
  methods: {
    addTag(e) {
      this.tags.push(e);
    },
    removeTag(id) {
      const index = this.tagIDs.indexOf(id);
      this.tags.splice(index, 1);
    },
    async getManagedTeams() {
      const q = qs.stringify({
        _where: [
          {
            _or: [{ isbase_ne: true }, { isbase_null: true }],
          },
          {
            _or: [{ issegment_ne: true }, { issegment_null: true }],
          },
        ],
      });
      const { data: teams } = await this.axios.get("/swarms/overlords?" + q);
      this.teams = teams;
    },
    async getMessage() {
      const { data: message } = await this.axios.get("/messages/" + this.id);
      this.body = message.body;
      this.teamid = message.teamid;
      this.tags = message.tags;
    },
    async submitMessage() {
      this.loading = true;
      if (!this.body.length) {
        return;
      }
      const message = {
        body: this.body,
        tags: this.tagIDs,
        isbase: this.isbase,
        teamid: this.teamid.toString(),
        checkupentry: this.checkup,
      };
      let result;
      if (this.id) {
        result = await this.axios.put("/messages/" + this.id, message);
      } else {
        result = await this.axios.post("/messages", message);
      }
      this.loading = false;
      this.body = "";
      this.tags = [];
      this.$emit("submitMessageForm", result.data);
    },
  },
};
</script>