<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div class="p-3 border-bottom-0 pb-0 modal-header bg-white">
      <button
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div v-if="retrieved && submission.length > 0" class="modal-body">
      <select
        v-model="submissionIndex"
        v-if="submission.length > 1"
        name=""
        id="checkupSelect"
        class="mb-4 text-sentence"
      >
        <option v-for="(sub, i) in submission" :key="i" :value="i">
          {{ checkup[i].title }} -
          {{ moment(sub.updated_at).format("DD/MM/YYYY") }}
        </option>
      </select>
      <div v-else class="title overline mb-4">
        {{ checkup[submissionIndex].title }}
      </div>
      <!-- ANALYTICS -->
      <div class="row metric-cards mb-4">
        <div class="col-md-3">
          <div class="card rounded-8 overflow-hidden">
            <div class="card-body text-center">
              <progress-chart
                class="mx-auto mb-2"
                :pct="activeWellbeing"
                :f-size="'md'"
                :color="getColorByVitamin('wellbeing')"
                border-width="6"
                style="width: 64px; height: 64px"
              ></progress-chart>
              <div class="title overline mb-2">
                <span class="text-sentence">{{ $t("message.WB") }} {{ $t("message.user") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card rounded-8 overflow-hidden">
            <div class="card-body text-center">
              <progress-chart
                class="mx-auto mb-2"
                :pct="ew.wb"
                :f-size="'md'"
                :color="getColorByVitamin('wellbeing')"
                border-width="6"
                style="width: 64px; height: 64px"
              ></progress-chart>
              <div class="title overline mb-2">
                <span class="text-sentence">{{ $t("message.WB") }} {{ $t("message.company") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card rounded-8 overflow-hidden">
            <div class="card-body text-center">
              <progress-chart
                class="mx-auto mb-2"
                :pct="activeEngagement"
                :f-size="'md'"
                :color="getColorByVitamin('engagement')"
                border-width="6"
                style="width: 64px; height: 64px"
              ></progress-chart>
              <div class="title overline mb-2">
                <span class="text-sentence">{{ $t("message.ENG") }} {{ $t("message.user") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card rounded-8 overflow-hidden">
            <div class="card-body text-center">
              <progress-chart
                class="mx-auto mb-2"
                :pct="ew.eng"
                :f-size="'md'"
                :color="getColorByVitamin('engagement')"
                border-width="6"
                style="width: 64px; height: 64px"
              ></progress-chart>
              <div class="title overline mb-2">
                <span class="text-sentence">{{ $t("message.ENG") }} {{ $t("message.company") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Questions and comments -->
      <div v-if="activeSubmission" class="d-flex mt-2">
        <search @search="handleSearch"></search>
        <select v-model="activeList" name="" id="listSelect">
          <option value="questions" v-if="activeSubmission.questions && activeSubmission.questions.length">{{ $t("message.questions") }}</option>
          <option v-if="activeSubmission.notes && activeSubmission.notes.length" value="notes">{{ $t("message.comments") }}</option>
        </select>
        <select
          v-if="activeList == 'questions'"
          v-model="activeVitaminIndex"
          name=""
          id="vitaminselect"
          class="ms-2"
          :class="'bgc-' + activeSubmission.questions[activeVitaminIndex].color + ' c-50'"
        >
          <option
            :value="i"
            v-for="(vitamin, i) in activeSubmission.questions"
            :key="vitamin.id"
            class="bg-white"
          >
            {{ $i18n.locale !== $i18n.fallbackLocale ? vitamin['title_' + $i18n.locale] : vitamin.title }}
          </option>
        </select>
      </div>
      <div v-if="activeList == 'questions'" class="border-start pt-4">
        <ul v-for="factor in activeSubmission.questions[activeVitaminIndex].factors" :key="factor.id">
          <div>
            <li class="" :class="'c-' + activeSubmission.questions[activeVitaminIndex].color">
              {{ $i18n.locale !== $i18n.fallbackLocale ? factor['title_' + $i18n.locale] : factor.title }}
            </li>
            <li
              class="list-questions"
              :class="'c-' + activeSubmission.questions[activeVitaminIndex].color"
              v-for="question in factor.questions"
              :key="question.id"
            >
              <p class="c-body mb-0">{{ $i18n.locale !== $i18n.fallbackLocale ? question['title_' + $i18n.locale] : question.title }}</p>
              <p class="c-body mt-1 fst-italic text-sentence">
                {{ question.answer ? $t(`message.${question.answer}`) : $t('message.no_answer') }}
              </p>
            </li>
          </div>
        </ul>
      </div>
      <div v-else class="border-start pt-4">
        <div
        >
          <ul>
            <li
              v-for="factor in activeSubmission.notes"
              :key="factor.id"
              class="mb-3"
            >
              {{ $i18n.locale !== $i18n.fallbackLocale ? factor['title_' + $i18n.locale] : factor.title }}
              <div v-for="(note, i) in factor.notes" :key="i" class="bgc-theme c-200 c-body rounded p-2">
                <div
                    class="text-sm mb-3"
                    @click="updateNote(note)"
                    style="cursor: pointer"
                    v-if="note.isOwn"
                >
                  <img
                      v-show="note.isShared"
                      src="@/assets/icons/icon-toggle-check-box.svg"
                      alt="checkbox"
                      class="me-2"
                      style="height: 12pt"
                  />
                  <img
                      v-show="!note.isShared"
                      src="@/assets/icons/icon-toggle-check-box_off.svg"
                      class="me-2"
                      alt="checkbox"
                      style="height: 12pt"
                  />
                  <span class="text-sentence">{{ $t('message.ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager')}}</span>
                </div>
                {{ note.content }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--      <pre>{{ submission }}</pre>-->
    </div>
    <div
      v-else-if="retrieved"
      class="modal-body d-flex justify-content-center align-items-center"
    >
      <p class="text-sentence">{{ $t("message.thisUserHasNoSubmissions") }}</p>
    </div>
    <div
      v-else
      class="modal-body d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("message.loading") }}...</span>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "../simple/Search.vue";
import ProgressChart from "@/views/components/ProgressChart";
import moment from "moment";
// import qs from 'qs';

export default {
  props: ["submissionid", "teamid", "userid", "activeId"],
  components: { Search, ProgressChart },
  data() {
    return {
      checkup: [],
      submission: null,
      retrieved: false,
      activeVitaminIndex: 0,
      activeList: "questions",
      submissionIndex: 0,
      ew: null,
      search: "",
      moment: moment,
    };
  },
  watch: {
    activeSubmission(value) {
      if (value && (!value.questions || !value.questions.length)) {
        this.activeList = "notes"
      } else {
        this.activeList = "questions"
      }
    }
  },
  computed: {
    activeVitamin() {
      if (this.retrieved && this.submission.length) {
        return this.user.id === parseInt(this.submission[this.submissionIndex].authorid) ?
            this.submission[this.submissionIndex].submission.questions[this.activeVitaminIndex] :
            this.submission[this.submissionIndex].sharedsubmission.questions[this.activeVitaminIndex];
      }
      return null;
    },
    activeSubmission() {
      if (this.retrieved && this.submission.length) {
        return this.user.id === parseInt(this.submission[this.submissionIndex].authorid) ?
            this.submission[this.submissionIndex].submission :
            this.submission[this.submissionIndex].sharedsubmission
      }
      return null
    },
    activeWellbeing() {
      return this.submission[this.submissionIndex].data
        ? (this.submission[this.submissionIndex].data.data.WB / 7) * 100
        : 0;
    },
    activeEngagement() {
      return this.submission[this.submissionIndex].data
        ? (this.submission[this.submissionIndex].data.data.ENG / 7) * 100
        : 0;
    },
  },
  methods: {
    async getCheckups() {
      if (this.submissionid) {
        const { data: submission } = await this.axios.get(
          "/checkupsubmissions/getprivate/" + this.user.id + "?submissionid=" + this.submissionid
        );
        this.submission = submission
      } else {
        const { data: submissions } = await this.axios.get('/checkupsubmissions/getprivate/' + this.userid);
        this.submission = submissions;
      }
      const { data: ew } = await this.axios.get(
        "/analytics-items?type=descriptives&isactive=true&subtype=ew"
      );
      this.ew = {
        eng: ew[0] ? (ew[0].data.ENG[8] / 7) * 100 : 0,
        wb: ew[0] ? (ew[0].data.WB[8] / 7) * 100 : 0,
      };
      if (this.submission.length) {
        this.checkup = this.submission.map(item => item.checkupentry.checkup);
      }
      this.retrieved = true;
    },
    handleSearch(val) {
      this.search = val;
    },
    activeQuestions(factor) {
      if (this.search != "") {
        return factor.questions.filter((question) =>
          question.title.includes(this.search)
        );
      }
      return factor.questions;
    },
    activeNotes(factor) {
      if (this.search != "") {
        return factor;
      }
    },
    async updateNote(note) {
      let sub = this.submission[this.submissionIndex];
      // note.isShared = !note.isShared;
      delete note.isOwn;
      const index = sub.submission.notes.findIndex(
        (item) => item.index == note.index
      );

      const sharedIndex = sub.sharedsubmission.notes.findIndex(
        (item) => item.index == note.index
      );
      if (sharedIndex != -1) {
        note.isShared = false;
        sub.sharedsubmission.notes.splice(sharedIndex, 1);
      } else {
        note.isShared = true;
        sub.sharedsubmission.notes.push(note);
      }
      sub.submission.notes.splice(index, 1, note);
      await this.axios.put("/checkupsubmissions/" + sub.id, sub);
    },
  },
  async mounted() {
    await this.getCheckups();
    if (this.activeId) {
      this.submissionIndex = this.checkup.findIndex(item => item.id == this.activeId);
    }
  },
};
</script>

<style scoped lang="scss">
.list-questions {
  list-style: circle;
}
</style>