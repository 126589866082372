<template>
  <div class="position-relative">
    <!--    {{activePage}}-->
    <!--    {{totalRows}}-->
    <div v-if="loadingFinished" class="paginated-display">
      <!--    {{request}}-->
      <slot></slot>
      <nav v-if="pageLength > 1" aria-label="Page navigation example" class="mt-3 d-flex justify-content-between align-items-center">
        <div v-if="loadingFinished && (totalRows > 0)" class="flex-grow-1">
          <slot name="paginationFooterLeft"></slot>
        </div>
        <ul class="pagination justify-content-center align-items-center flex-grow-0 mb-0">
          <li
            v-if="pageLength > 1 && activePage > 1"
            class="page-item"
            @click.prevent.stop="previousPage()"
          >
            <a class="btn mx-1" href="#" aria-label="Previous">
              <span aria-hidden="true">
                <img
                  class="d-block img-fluid"
                  src="../../../assets/icons/previous.svg"
                  alt=""
                />
              </span>
            </a>
          </li>
          <li v-if="pageLength > 1">{{ activePage }}/{{ pageLength }}</li>
          <li
            v-if="pageLength > 1 && activePage < pageLength"
            class="page-item"
            @click.prevent.stop="nextPage()"
          >
            <a class="btn mx-1" href="#" aria-label="Next">
              <span aria-hidden="true">
                <img
                  class="d-block img-fluid"
                  src="../../../assets/icons/next.svg"
                  alt=""
                />
              </span>
            </a>
          </li>
        </ul>
        <div v-if="loadingFinished && (totalRows > perPage)" class="flex-grow-1 d-flex justify-content-end">
          <select class="input-lg"  name="" id="" v-model="itemsOnPage">
            <option :value="perPage">{{ perPage }}</option>
            <option v-if="perPage < 10" :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
          </select>
        </div>

      </nav>
    </div>

    <div
      v-else
      class="
        position-absolute
        top-50
        start-50
        translate-middle
        container-spinner
      "
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("message.loading") }}...</span>
      </div>
    </div>
    <div
      v-if="loadingFinished && totalRows === 0"
      class="d-flex justify-content-center"
    >
      <p class="text-sentence">{{ $t("message.no_data_available") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    async totalRows(newval, oldval) {
      if (!oldval) {
        return;
      }
      this.pageIndex = 0;
      const content = await this.getPageContent();
      this.$emit("change", content);
    },
    async request() {
      // console.log('request changed', this.request)
      const content = await this.getPageContent();
      this.$emit("change", content);
    },
    loaded(value) {
      this.loadingFinished = value;
    },
    async itemsOnPage() {
      this.pageIndex = 0;
      const content = await this.getPageContent();
      this.$emit("change", content);
    }
  },
  props: [
    "request",
    "totalRows",
    "perPage",
    "deferLoading",
    "loaded",
    "content",
    // 'modelValue' //currentpage
  ],
  // emits: ['update:modelValue'],
  emits: ["change"],
  methods: {
    async getPageContent() {
      this.loadingFinished = false;
      if (this.content) {
        const content = this.content;
        this.loadingFinished = true;
        if (!Array.isArray(content)) {
          return Object.values(content).slice(this.start, this.start + (this.itemsOnPage || this.perPage));
        } else {
          return content.slice(this.start, this.start + (this.itemsOnPage || this.perPage))
        }
      } else {
        let s = "&";
        if (this.request.indexOf("?") < 0) {
          s = "?";
        }
        const request = await this.axios.get(
            this.request + `${s}_start=${this.start}&_limit=${this.itemsOnPage || this.perPage}`
        );
        if (!this.deferLoading) {
          this.loadingFinished = true;
        } else {
          this.loadingFinished = this.loaded;
        }
        return request.data;
      }
    },
    async previousPage() {
      this.pageIndex--;
      const content = await this.getPageContent();
      this.$emit("change", content);
    },
    async nextPage() {
      this.pageIndex++;
      const content = await this.getPageContent();
      this.$emit("change", content);
    },
  },
  async mounted() {
    const content = await this.getPageContent();
    this.itemsOnPage = this.perPage;
    this.$emit("change", content);
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalRows / (this.itemsOnPage || this.perPage));
    },
    activePage() {
      return this.pageIndex + 1;
    },
    start() {
      return this.pageIndex * (this.itemsOnPage || this.perPage);
    },
  },
  data() {
    return {
      loadingFinished: false,
      pageIndex: 0,
      itemsOnPage: this.perPage,
    };
  },
};
</script>
<style scoped lang="scss">
li {
  &:before,
  &:after {
    display: none;
  }
}
</style>