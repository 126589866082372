export const messages = {
    en: {
        message: {
            'Helemaal oneens': 'Strongly disagree',
            'Oneens': 'Disagree',
            'Eerder oneens': 'Somewhat disagree',
            'Noch eens, noch oneens': 'Neither agree nor disagree',
            'Eerder eens': 'Somewhat agree',
            'Eens': 'Agree',
            'Helemaal eens': 'Strongly agree',
            'Nooit': 'Never',
            'Sporadisch': 'Very rarely',
            'Af en toe': 'Rarely',
            'Regelmatig': 'Occasionally',
            'Dikwijls': 'Frequently',
            'Zeer dikwijls': 'Very frequently',
            'Altijd': 'Always',
            'Helemaal ontevreden': 'Very dissatisfied',
            'Ontevreden': 'Dissatisfied',
            'Eerder ontevreden': 'Somewhat dissatisfied',
            'Noch tevreden, noch ontevreden': 'Neither satisfied nor dissatisfied',
            'Eerder tevreden': 'Somewhat satisfied',
            'Tevreden': 'Satisfied',
            'Helemaal tevreden': 'Very satisfied',
            loading_app: 'Loading the application',
            influenced_by: 'Is influenced by',
            influences: 'Has influence on',
            for: 'for',
            apply: 'apply',
            cancel: 'cancel',
            save: 'save',
            saved: 'saved',
            add: 'add',
            company: 'Company',
            filter: 'filter',
            import: 'import',
            personal: 'personal',
            user: 'user',
            team: 'Team',
            segment: 'segment',
            company_tree: 'company tree',
            segment_tree: 'segment tree',
            checkup: 'checkup',
            checkups: 'checkups',
            all: 'All',
            any: 'any',
            none: 'none',
            users_match: 'users match',
            of_the_following_conditions: 'of the following conditions',
            planned: 'planned',
            completed: 'Completed',
            calendar: 'calendar',
            profile: 'profile',
            personal_data: 'personal data',
            account: 'account',
            firstname: 'first name',
            first_name: 'first name',
            lastname: 'last name',
            last_name: 'last name',
            birthdate: 'birthdate',
            gender: 'Gender',
            male: 'male',
            female: 'female',
            other: 'other',
            not_married: 'not married',
            married: 'married',
            widow: 'widow',
            widower: 'widower',
            education: 'education',
            transportation: 'transportation',
            contact_data: 'contact data',
            street: 'street',
            nr: 'nr',
            bus: 'bus',
            postal_code: 'postal code',
            city: 'city',
            country: 'Country',
            number_of_children: 'number of children',
            search: 'search',
            duration: 'duration',
            graceperiod: 'grace period',
            repeatafter: 'repeat after',
            days: 'days',
            block: 'block',
            add_a_team_or_segment: 'add a team or segment',
            team_or_segment: 'team or segment',
            add_a_checkup_block: 'add a checkup block',
            action: 'Action',
            available: 'available',
            presets: 'presets',
            add_a_filter: 'add a filter',
            add_title: 'add title',
            manager: 'manager',
            permissions: 'permissions',
            general: 'general',
            car: 'car',
            bike: 'bike',
            maritalstate: 'maritalstate',
            notmarried: 'notmarried',
            widowed: 'widowed',
            transport: 'transport',
            jobtitle: 'jobtitle',
            developer: 'developer',
            owner: 'owner',
            projectmanager: 'project manager',
            privacy_notifications: 'privacy notifications',
            field_options: 'field options',
            faq: 'faq',
            name: 'name',
            teams: 'teams',
            teams_tree: 'teams tree',
            base_team_tooltip: 'This is a base team. It sits at the top level in the tree.',
            orphan_team_tooltip: 'This team is orphaned. It has no parent and will not be visible in the tree.',
            primary: 'primary',
            secondary: 'secondary',
            bachelor: 'bachelor',
            master: 'master',
            phd: 'phd',
            foot: 'foot',
            min_max: 'min/max',
            mean: 'mean',
            public: 'public',
            email: 'E-mail',
            standard_deviation: 'standard deviation',
            change_password: 'change password',
            confirm_password: 'confirm password',
            checkup_analytics: 'checkup analytics',
            min: 'min',
            team_average: 'team average',
            max: 'max',
            heatmap: 'heatmap',
            network: 'network',
            users: 'users',
            settings: 'settings',
            privacy: 'privacy',
            emails: 'emails',
            privacy_data: 'privacy data',
            field_settings: 'field settings',
            roles_permissions: 'roles permissions',
            histogram: 'histogram',
            not_translated: 'not localized',
            translate: 'translate',
            no_actions_available: 'no actions available.',
            no_data_available: 'no data available.',
            no_messages_available: 'no messages available.',
            no_longer_employed_since: 'no longer employed as of',
            country_AD: 'Andorra',
            country_AE: 'United Arab Emirates',
            country_AF: 'Afghanistan',
            country_AG: 'Antigua and Barbuda',
            country_AI: 'Anguilla',
            country_AL: 'Albania',
            country_AM: 'Armenia',
            country_AO: 'Angola',
            country_AQ: 'Antarctica',
            country_AR: 'Argentina',
            country_AS: 'American Samoa',
            country_AT: 'Austria',
            country_AU: 'Australia',
            country_AW: 'Aruba',
            country_AX: 'Åland Islands',
            country_AZ: 'Azerbaijan',
            country_BA: 'Bosnia and Herzegovina',
            country_BB: 'Barbados',
            country_BD: 'Bangladesh',
            country_BE: 'Belgium',
            country_BF: 'Burkina Faso',
            country_BG: 'Bulgaria',
            country_BH: 'Bahrain',
            country_BI: 'Burundi',
            country_BJ: 'Benin',
            country_BL: 'Saint Barthélemy',
            country_BM: 'Bermuda',
            country_BN: 'Brunei Darussalam',
            country_BO: 'Bolivia, Plurinational State of',
            country_BQ: 'Bonaire, Sint Eustatius and Saba',
            country_BR: 'Brazil',
            country_BS: 'Bahamas',
            country_BT: 'Bhutan',
            country_BV: 'Bouvet Island',
            country_BW: 'Botswana',
            country_BY: 'Belarus',
            country_BZ: 'Belize',
            country_CA: 'Canada',
            country_CC: 'Cocos (Keeling) Islands',
            country_CD: 'Congo, the Democratic Republic of the',
            country_CF: 'Central African Republic',
            country_CG: 'Congo',
            country_CH: 'Switzerland',
            country_CI: 'Côte d\'Ivoire',
            country_CK: 'Cook Islands',
            country_CL: 'Chile',
            country_CM: 'Cameroon',
            country_CN: 'China',
            country_CO: 'Colombia',
            country_CR: 'Costa Rica',
            country_CU: 'Cuba',
            country_CV: 'Cape Verde',
            country_CW: 'Curaçao',
            country_CX: 'Christmas Island',
            country_CY: 'Cyprus',
            country_CZ: 'Czech Republic',
            country_DE: 'Germany',
            country_DJ: 'Djibouti',
            country_DK: 'Denmark',
            country_DM: 'Dominica',
            country_DO: 'Dominican Republic',
            country_DZ: 'Algeria',
            country_EC: 'Ecuador',
            country_EE: 'Estonia',
            country_EG: 'Egypt',
            country_EH: 'Western Sahara',
            country_ER: 'Eritrea',
            country_ES: 'Spain',
            country_ET: 'Ethiopia',
            country_FI: 'Finland',
            country_FJ: 'Fiji',
            country_FK: 'Falkland Islands (Malvinas)',
            country_FM: 'Micronesia, Federated States of',
            country_FO: 'Faroe Islands',
            country_FR: 'France',
            country_GA: 'Gabon',
            country_GB: 'United Kingdom',
            country_GD: 'Grenada',
            country_GE: 'Georgia',
            country_GF: 'French Guiana',
            country_GG: 'Guernsey',
            country_GH: 'Ghana',
            country_GI: 'Gibraltar',
            country_GL: 'Greenland',
            country_GM: 'Gambia',
            country_GN: 'Guinea',
            country_GP: 'Guadeloupe',
            country_GQ: 'Equatorial Guinea',
            country_GR: 'Greece',
            country_GS: 'South Georgia and the South Sandwich Islands',
            country_GT: 'Guatemala',
            country_GU: 'Guam',
            country_GW: 'Guinea-Bissau',
            country_GY: 'Guyana',
            country_HK: 'Hong Kong',
            country_HM: 'Heard Island and McDonald Islands',
            country_HN: 'Honduras',
            country_HR: 'Croatia',
            country_HT: 'Haiti',
            country_HU: 'Hungary',
            country_ID: 'Indonesia',
            country_IE: 'Ireland',
            country_IL: 'Israel',
            country_IM: 'Isle of Man',
            country_IN: 'India',
            country_IO: 'British Indian Ocean Territory',
            country_IQ: 'Iraq',
            country_IR: 'Iran, Islamic Republic of',
            country_IS: 'Iceland',
            country_IT: 'Italy',
            country_JE: 'Jersey',
            country_JM: 'Jamaica',
            country_JO: 'Jordan',
            country_JP: 'Japan',
            country_KE: 'Kenya',
            country_KG: 'Kyrgyzstan',
            country_KH: 'Cambodia',
            country_KI: 'Kiribati',
            country_KM: 'Comoros',
            country_KN: 'Saint Kitts and Nevis',
            country_KP: 'Korea, Democratic People\'s Republic of',
            country_KR: 'Korea, Republic of',
            country_KW: 'Kuwait',
            country_KY: 'Cayman Islands',
            country_KZ: 'Kazakhstan',
            country_LA: 'Lao People\'s Democratic Republic',
            country_LB: 'Lebanon',
            country_LC: 'Saint Lucia',
            country_LI: 'Liechtenstein',
            country_LK: 'Sri Lanka',
            country_LR: 'Liberia',
            country_LS: 'Lesotho',
            country_LT: 'Lithuania',
            country_LU: 'Luxembourg',
            country_LV: 'Latvia',
            country_LY: 'Libya',
            country_MA: 'Morocco',
            country_MC: 'Monaco',
            country_MD: 'Moldova, Republic of',
            country_ME: 'Montenegro',
            country_MF: 'Saint Martin (French part)',
            country_MG: 'Madagascar',
            country_MH: 'Marshall Islands',
            country_MK: 'Macedonia, the former Yugoslav Republic of',
            country_ML: 'Mali',
            country_MM: 'Myanmar',
            country_MN: 'Mongolia',
            country_MO: 'Macao',
            country_MP: 'Northern Mariana Islands',
            country_MQ: 'Martinique',
            country_MR: 'Mauritania',
            country_MS: 'Montserrat',
            country_MT: 'Malta',
            country_MU: 'Mauritius',
            country_MV: 'Maldives',
            country_MW: 'Malawi',
            country_MX: 'Mexico',
            country_MY: 'Malaysia',
            country_MZ: 'Mozambique',
            country_NA: 'Namibia',
            country_NC: 'New Caledonia',
            country_NE: 'Niger',
            country_NF: 'Norfolk Island',
            country_NG: 'Nigeria',
            country_NI: 'Nicaragua',
            country_NL: 'Netherlands',
            country_NO: 'Norway',
            country_NP: 'Nepal',
            country_NR: 'Nauru',
            country_NU: 'Niue',
            country_NZ: 'New Zealand',
            country_OM: 'Oman',
            country_PA: 'Panama',
            country_PE: 'Peru',
            country_PF: 'French Polynesia',
            country_PG: 'Papua New Guinea',
            country_PH: 'Philippines',
            country_PK: 'Pakistan',
            country_PL: 'Poland',
            country_PM: 'Saint Pierre and Miquelon',
            country_PN: 'Pitcairn',
            country_PR: 'Puerto Rico',
            country_PS: 'Palestinian Territory, Occupied',
            country_PT: 'Portugal',
            country_PW: 'Palau',
            country_PY: 'Paraguay',
            country_QA: 'Qatar',
            country_RE: 'Réunion',
            country_RO: 'Romania',
            country_RS: 'Serbia',
            country_RU: 'Russian Federation',
            country_RW: 'Rwanda',
            country_SA: 'Saudi Arabia',
            country_SB: 'Solomon Islands',
            country_SC: 'Seychelles',
            country_SD: 'Sudan',
            country_SE: 'Sweden',
            country_SG: 'Singapore',
            country_SH: 'Saint Helena, Ascension and Tristan da Cunha',
            country_SI: 'Slovenia',
            country_SJ: 'Svalbard and Jan Mayen',
            country_SK: 'Slovakia',
            country_SL: 'Sierra Leone',
            country_SM: 'San Marino',
            country_SN: 'Senegal',
            country_SO: 'Somalia',
            country_SR: 'Suriname',
            country_SS: 'South Sudan',
            country_ST: 'Sao Tome and Principe',
            country_SV: 'El Salvador',
            country_SX: 'Sint Maarten (Dutch part)',
            country_SY: 'Syrian Arab Republic',
            country_SZ: 'Swaziland',
            country_TC: 'Turks and Caicos Islands',
            country_TD: 'Chad',
            country_TF: 'French Southern Territories',
            country_TG: 'Togo',
            country_TH: 'Thailand',
            country_TJ: 'Tajikistan',
            country_TK: 'Tokelau',
            country_TL: 'Timor-Leste',
            country_TM: 'Turkmenistan',
            country_TN: 'Tunisia',
            country_TO: 'Tonga',
            country_TR: 'Turkey',
            country_TT: 'Trinidad and Tobago',
            country_TV: 'Tuvalu',
            country_TW: 'Taiwan, Province of China',
            country_TZ: 'Tanzania, United Republic of',
            country_UA: 'Ukraine',
            country_UG: 'Uganda',
            country_UM: 'United States Minor Outlying Islands',
            country_US: 'United States',
            country_UY: 'Uruguay',
            country_UZ: 'Uzbekistan',
            country_VA: 'Holy See (Vatican City State)',
            country_VC: 'Saint Vincent and the Grenadines',
            country_VE: 'Venezuela, Bolivarian Republic of',
            country_VG: 'Virgin Islands, British',
            country_VI: 'Virgin Islands, U.S.',
            country_VN: 'Viet Nam',
            country_VU: 'Vanuatu',
            country_WF: 'Wallis and Futuna',
            country_WS: 'Samoa',
            country_YE: 'Yemen',
            country_YT: 'Mayotte',
            country_ZA: 'South Africa',
            country_ZM: 'Zambia',
            country_ZW: 'Zimbabwe',
            search_users: 'Search users',
            descriptives: 'descriptives',
            manageusers: 'manage users',
            manageteams: 'manage teams',
            managesegments: 'manage segments',
            managesettings: 'manage settings',
            manageanalytics: 'manage analytics',
            managecompany: 'manage company',
            managepermissions: 'manage permissions',
            manageschedule: 'manage schedule',
            edit_this_x_action: 'edit this {type} action',
            add_a_x_action: 'add a {type} action',
            label: 'label',
            edit_entry_for: 'Edit entry for',
            value: 'Value',
            total_users: 'total users',
            download_template: 'Download template',
            click_to_add_csv: 'Import .csv file',
            import_users: 'Import users',
            x_invalid_rows: '{amt} invalid rows',
            x_records_processed: '{amt} records processed.',
            x_users_imported_successfully: '{amt} user imported successfully.',
            x_users_could_not_be_imported: '{amt} users could not be imported.',
            the_following_errors_occurred: 'The following errors occurred',
            row: 'row',
            close: 'close',
            first_x_records: 'First {amt} records',
            cannot_be_empty: 'cannot be empty',
            cannot_be_empty_or_must_be_unique: 'cannot be empty or must be unique',
            must_be_dmyyyy_or_iso: 'must be d/m/yyyy or iso format',
            field_value_restrictions: 'must be any of: {values}',
            invalid_country: 'invalid country',
            $ERR_REQ: 'Required',
            $ERR_INVALID: 'Invalid',
            $ERR_UNIQ: 'Must be unique',
            import_teams: 'Import teams',
            title: 'title',
            description: 'Description',
            root: 'root team',
            color: 'color',
            parents: 'parent teams',
            current: 'current',
            former: 'former',
            orphaned: 'orphaned',
            orphan_user_tooltip: 'This user is orphaned. They do not belong to any team.',
            no_parents_in_base: 'A base team should not have parent teams.',
            x_teams_imported_successfully: '{amt} teams imported successfully.',
            x_teams_could_not_be_imported: '{amt} teams could not be imported.',
            number: 'number',
            password_mail_sent: 'if the email exists in our system, we\'ve sent a password reset link to {email}',
            postalcode: 'postal code',
            stateprovince: 'state/province',
            seniority: 'seniority',
            passwords_do_not_match: 'passwords do not match',
            password_is_required: 'password is required',
            firstname_is_required: 'firstname is required',
            lastname_is_required: 'lastname is required',
            fill_in_all_fields: 'please fill in all fields',
            search_teams_or_segments: 'search teams or segments',
            search_blocks: 'search blocks',
            employmentstart: 'employment start',
            employmentend: 'employment end',
            terminationreason: 'reason for termination',
            children: 'children',
            submitting: 'submitting',
            reset_your_password: 'reset your password',
            professional: 'professional',
            starts_on: 'starts on',
            ended_on: 'Ended on',
            fill_all_fields: 'Please fill all fields',
            gender_m: 'male',
            gender_f: 'female',
            gender_x: 'other',
            tags: 'Tags',
            EB: 'Job resources',
            Eng: 'Engagement',
            ENG: 'Engagement',
            HIN: 'Obstacles',
            PHB: 'Personal resources',
            UIT: 'Challenges',
            WB: 'Wellbeing',
            AU: 'Autonomy',
            SL: 'Social support manager',
            SC: 'Social support colleagues',
            VAR: 'Skill utilisation',
            ZIN: 'Meaningfulness',
            TI: 'Task identity',
            LEE: 'Learning opportunities',
            FB: 'Feedback',
            WD: 'Work pace and amount of work',
            COG: 'Cognitive load',
            COM: 'Job complexity',
            EMO: 'Emotional exhaustion',
            ONZ: 'Job insecurity',
            ROA: 'Role ambiguity',
            ROC: 'Role conflict',
            BUR: 'Bureaucracy',
            CON: 'Interpersonal conflicts',
            POL: 'Politics',
            OPT: 'Optimism',
            VEE: 'Resilience',
            ZV1: 'Self-confidence',
            SE: 'Personal resources',
            MOT:'Motivation',
            AT:'Attitudes',
            PR:'Performance',
            ONG:'Unhealthiness',
            VIT:'Vitality',
            TOE:'Dedication',
            FLOW: 'Flow',
            TEVRE: 'Job satisfaction',
            BETROK: 'Organizational commitment',
            CPRES: 'Contextual performance',
            TPRES:'Task performance',
            UITPU:'Emotional exhaustion',
            PIEKE: 'Worrying',
            SLAAP: 'Sleep quality',
            drag_to_calendar: 'drag to calendar',
            scheduled_deadline: 'scheduled deadline',
            relative_importance_analyses: 'Relative Importance Analysis',
            ria: 'Relative Importance Analysis',
            please_log_in: 'please log in',
            add_subteams: 'Also include child teams',
            download_import_keys: 'Download import keys',
            invalid_reset_code: 'The password reset token you are trying to use is no longer valid. If you still need to reset your password, go back to login to request a new code.',
            start_date: 'Start date',
            end_date: 'End date',
            action_shared_with_team: 'This action is shared with members in your team.',
            drop_your_image_here: 'Drop your image here',
            delete: 'Delete',
            img_max_size: 'Image should be smaller than {amt}x{amt} pixels',
            unsaved_tooltip: 'This change has not been saved yet.',
            field_required: '{field} is required.',
            phone: 'phone',
            already_logged_in: 'You are already logged in. You need to log out to access this link.',
            x_users_in_segment: 'A segment needs {amt} people to be created.',
            baseline_measurement: 'Baseline measurement.',
            since_last_checkup: 'Since last checkup',
            percentiles: 'Percentiles',
            feedback: 'Feedback',
            checkup_feedback: 'Checkup feedback',
            geef_feedback: 'give feedback',
            bedankt: 'thank you',
            je_antwoorden_blijven_prive: 'your answers remain private',
            wil_je_ze_met_je_manager_delen_ontgrendel_dan_het_slotje: 'do you want to share them with your manager? click to unlock',
            je_antwoorden_worden_gedeeld_met_je_manager: 'your answers are shared with your manager',
            wil_je_prive_houden_vergrendel_dan_het_slotje: 'do you want to keep them private? click to lock',
            feedback_na_afronden: 'want to give feedback after finishing?',
            afronden: 'finish',
            einde: 'end',
            je_bent_er_bijna: 'you\'re almost there',
            bent_er_bijna_copy: 'your submission is almost complete',
            vragen_ingevuld: 'questions answered',
            nog_in_te_vullen: 'questions remaining',
            resterende_dagen: 'days left',
            je_antwoorden_zijn_prive: 'your answers are private',
            je_antwoorden_zijn_zichtbaar: 'your answers are visible',
            afwerken: 'finish',
            amt_vragen: '{amt} questions',
            wil_je_voor_jezelf_iets_noteren_in_verband_met: 'do you want to comment about {title}',
            ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager: 'I want to share these notes and discuss them with my manager',
            ga_terug_naar_dashboard: 'back to dashboard',
            noteer_hier: 'Write something down here...',
            notify_user_account: 'Notify this user',
            geschatte_duur: 'Estimated duration',
            volledig_ingevuld: 'Completed',
            bekijken: 'View',
            afgesloten: 'Closed',
            nog_geen_inzendingen_gedetecteerd: 'No submissions detected',
            deadline_voorbij_zonder_inzending: 'Deadline passed without submission',
            ingevuld_op: 'Completed on',
            password_too_short: 'password needs to contain at least {amt} characters',
            forgot_password: 'Forgot your password?',
            send: 'send',
            password: 'password',
            log_in: 'log in',
            logging_in: 'logging in',
            default_branch_tooltip: 'This is your default company branch. It cannot be deleted',
            add_branch: 'Add branch',
            logo_upload: 'Choose a logo ({size})',
            main_branch: 'Main branch',
            saving: 'saving',
            enable_actions: 'Enable actions',
            require_review: 'Manager review required',
            show_results_before_end: 'Show results before the end of a checkup period',
            minimum_users_segment: 'Minimum amount of users in segments',
            default_submission_anonimity: 'Default submission anonimity',
            private: 'private',
            enable_analytics: 'Enable analytics',
            enable_heatmap: 'Enable heatmap',
            enable_descriptives: 'Enable descriptives',
            enable_network: 'Enable network',
            enable_histograms: 'Enable histograms',
            enable_ria: 'Enable Relative Importance Analysis',
            min_users_population_analytics: 'Minimum amount of users for population analytics',
            user_tags: 'User tags',
            message_tags: 'Message tags',
            statistics: 'statistics',
            small: 'small',
            large: 'large',
            personal_data_period: 'Personal data storage duration',
            loading: 'Loading',
            message: 'Message',
            subject: 'subject',
            users_with_permissions: 'users with this permission',
            give_user_permission: 'Give user permission',
            enable_actions_description: 'This enables users to create actions on the platform',
            require_review_description: 'A personal review from a manager is required',
            minimum_users_segment_description: 'Segments with less than this amount of users cannot be made.',
            default_submission_anonimity_description: 'Defines if submissions are private or visible to managers by default.',
            enable_analytics_description: 'Enables the analytics page and its different components',
            min_users_population_analytics_description: 'Smaller segments will use the default analytics',
            user_tags_description: 'Tags that can be applied to user accounts',
            add_tag: 'Add tags',
            message_tags_description: 'Tags that can be applied to messages',
            personal_data_period_description: 'Amount of days personal data will be stored in the application',
            send_email_to: 'Send email to',
            confirm_delete: 'Are you sure you want to delete {title}?',
            confirm_deletion: 'Confirm deletion',
            checkup_has_entry: 'Checkup has a started entry',
            checkup_will_be_copied: '"{title}" has an entry that has already started. Continuing will create a copy of this checkup with your changes.',
            save_copy: 'Save a copy',
            connection_error: 'Something went wrong.',
            connection_error_body: 'There are some connection issues. Please contact your administrator.',
            edit_new_message: 'Edit this {type} message',
            add_new_message: 'Add a {type} message',
            choose_team: 'Choose a team',
            add_a_manager: 'Add a manager',
            segment_contains: 'Segment contains',
            pick_a_color: 'Pick a color',
            no_answer: 'No answer',
            share_action_with_team: 'Share action with the team',
            dashboard:'dashboard',
            vitamins:'vitamins',
            actions:'actions',
            schedule:'schedule',
            tree:'tree',
            segments:'segments',
            analytics:'analytics',
            logout:'log-out',
            overview:'Overview',
            absoluteScores:'Absolute scores',
            viewSubmission:'View submission',
            complete:'Complete',
            deadline:'Deadline',
            start:'Start',
            noActiveCheckups:'No active checkups',
            zoomedIn:'Zoomed in',
            yourOverallPerformance:'Your overall performance',
            personalActions:'Personal actions',
            teamActions:'Team actions',
            companyActions:'Company actions',
            teamMessages:'Team messages',
            companyMessages:'Company messages',
            addToPersonalCalendar:'Add to personal calendar',
            thereAreNoVitaminsAvailable:'There are no vitamins available',
            edit: 'Edit',
            editEntry:'Edit entry',
            selected:'selected',
            writtenBy:'Written by',
            editMessage:'Edit message',
            deleteMessage:'Delete message',
            goBack:'Go back',
            addingMembers:'Adding members',
            confirm:'Confirm',
            selectYourAnalysis:'Select your analysis',
            average:'Average',
            questions:'Questions',
            newPassword:'New password',
            submit:'Submit',
            resetting:'Resetting',
            goToLogin:'Go to login',
            by:'By',
            readMore:'Read more',
            checkupEnds: 'Checkup: Ends',
            prive:'Private',
            shared: 'Shared',
            active:'Active',
            starts:'Starts',
            ends:'Ends',
            grace:'Grace',
            selectAnOption:'Select an option',
            deleteUserAccount:'Delete user account',
            thisIsAMainBranch:'This is a main branch',
            parentTeams:'Parent teams',
            myTeams:'My teams',
            standard:'Standard',
            allMyTeams:'All my teams',
            allCompanyTeams:'All company teams',
            comments: 'Comments',
            thisUserHasNoSubmissions:'This user has no submissions',
            operator:'Operator',
            manages:'Manages',
            jobLevel:'Job level',
            saveAsAPreset:'Save as a preset',
            showAll:'Show all',
            factor:'Factor',
            searchToAdd: 'Search {userListType} to add ...',
            enterATitle:'Enter a title',
            newTag:'New tag',
            unlimited:'Unlimited',
            all_questions: 'All questions',
            unanswered_only: 'Unanswered',
            managers: 'managers',
            language: 'language',
            user_has_been_saved: 'This user has been saved successfully',
            user_has_been_deleted: 'This user has been deleted',
            company_branch: 'Company branch',
            no_branch: 'No branch',
            position: 'position',
            delete_teams: 'delete {amt} teams',
            select_all: 'select all',
            all_teams: 'all teams',
            import_managers: 'import managers',
            participation: 'participation',
            sendResetMail: 'Send password reset mail',
            password_reset_mail_sent: "The mail has been sent to {email}",
            definitions: 'definitions',
            processed_on: 'processed on',
            this_message: 'this message',
            analytics_team_too_small: 'Analytics for this team are unavailable because it does not reach the minimum team size',
            minimum_users_team: 'Minimum amount of users for team visibility',
            minimum_users_team_message: 'The amount of users needed for a team to show up in analytics',
        }
    },
    "nl-BE": {
        message: {
            'Helemaal oneens': 'Helemaal oneens',
            'Oneens': 'Oneens',
            'Eerder oneens': 'Eerder oneens',
            'Noch eens, noch oneens': 'Noch eens, noch oneens',
            'Eerder eens': 'Eerder eens',
            'Eens': 'Eens',
            'Helemaal eens': 'Helemaal eens',
            'Nooit': 'Nooit',
            'Sporadisch': 'Sporadisch',
            'Af en toe': 'Af en toe',
            'Regelmatig': 'Regelmatig',
            'Dikwijls': 'Dikwijls',
            'Zeer dikwijls': 'Zeer dikwijls',
            'Altijd': 'Altijd',
            'Helemaal ontevreden': 'Helemaal ontevreden',
            'Ontevreden': 'Ontevreden',
            'Eerder ontevreden': 'Eerder ontevreden',
            'Noch tevreden, noch ontevreden': 'Noch tevreden, noch ontevreden',
            'Eerder tevreden': 'Eerder tevreden',
            'Tevreden': 'Tevreden',
            'Helemaal tevreden': 'Helemaal tevreden',
            loading_app: 'Het platform wordt geladen',
            influenced_by: 'Wordt beïnvloed door',
            influences: 'Heeft invloed op',
            for: 'voor',
            apply: 'toepassen',
            cancel: 'annuleren',
            save: 'opslaan',
            saved: 'opgeslagen',
            add: 'toevoegen',
            company: 'bedrijf',
            filter: 'filter',
            import: 'importeren',
            personal: 'persoonlijk',
            user: 'gebruiker',
            team: 'team',
            segment: 'segment',
            company_tree: 'bedrijfsstructuur',
            segment_tree: 'segment structuur',
            checkup: 'checkup',
            checkups: 'checkups',
            all: 'Alle',
            any: 'minstens één',
            none: 'geen',
            users_match: 'gebruikers beantwoorden aan',
            of_the_following_conditions: 'van de onderstaande voorwaarden',
            planned: 'gepland',
            completed: 'afgerond',
            calendar: 'kalender',
            profile: 'profiel',
            personal_data: 'persoonlijke gegevens',
            account: 'account',
            firstname: 'voornaam',
            first_name: 'voornaam',
            lastname: 'naam',
            last_name: 'naam',
            birthdate: 'geboortedatum',
            gender: 'Geslacht',
            male: 'man',
            female: 'vrouw',
            other: 'andere',
            not_married: 'ongehuwd',
            married: 'gehuwd',
            widow: 'weduwe',
            widower: 'weduwnaar',
            education: 'onderwijsniveau',
            transportation: 'transport',
            contact_data: 'contactgegevens',
            street: 'straat',
            nr: 'nr',
            bus: 'bus',
            postal_code: 'postcode',
            city: 'plaats',
            country: 'Land',
            number_of_children: 'aantal kinderen',
            search: 'zoeken',
            duration: 'duur',
            graceperiod: 'respijt periode',
            repeatafter: 'herhaal na',
            days: 'dagen',
            block: 'block',
            add_a_team_or_segment: 'voeg een team of segement toe',
            team_or_segment: 'team of segment',
            add_a_checkup_block: 'Voeg een checkup block toe',
            action: 'actie',
            available: 'beschikbaar',
            presets: 'standaard',
            add_a_filter: 'Voeg een filter toe',
            add_title: 'Voeg een titel toe',
            manager: 'manager',
            permissions: 'toestemmingen',
            general: 'algemeen',
            car: 'auto',
            bike: 'fiets',
            maritalstate: 'burgerlijke staat',
            notmarried: 'ongehuwd',
            widowed: 'weduw(e/naar)',
            transport: 'transport',
            jobtitle: 'functietitel',
            developer: 'ontwikkelaar',
            owner: 'eigenaar',
            projectmanager: 'projectmanager',
            privacy_notifications: 'privacy meldingen',
            field_options: 'veld opties',
            faq: 'faq',
            name: 'naam',
            teams: 'teams',
            teams_tree: 'teams structuur',
            base_team_tooltip: 'Dit is een basis team. Het zit op het hoogste level van de structuur.',
            orphan_team_tooltip: 'Dit is een afzonderlijk team. Het heeft geen parents en zal niet zichtbaar zijn in de tree.',
            primary: 'primair',
            secondary: 'secundair',
            bachelor: 'bachelor',
            master: 'master',
            phd: 'phd',
            foot: 'foot',
            min_max: 'min/max',
            mean: 'gemiddeld',
            public: 'publiek',
            email: 'E-mail',
            standard_deviation: 'standaard deviatie',
            change_password: 'verander wachtwoord',
            confirm_password: 'bevestig wachtwoord',
            checkup_analytics: 'checkup analyses',
            min: 'min',
            team_average: 'team gemiddelde',
            max: 'max',
            heatmap: 'heatmap',
            network: 'netwerk',
            users: 'gebruikers',
            settings: 'instellingen',
            privacy: 'privacy',
            emails: 'emails',
            privacy_data: 'privacy data',
            field_settings: 'veld instellingen',
            roles_permissions: 'toegangsrechten',
            histogram: 'histogram',
            not_translated: 'niet gelocaliseerd',
            translate: 'vertaal',
            no_actions_available: 'geen acties beschikbaar.',
            no_data_available: 'geen data beschikbaar.',
            no_messages_available: 'geen bericht beschikbaar.',
            no_longer_employed_since: 'niet langer te werk gesteld sinds',
            country_AD: 'Andorra',
            country_AE: 'United Arab Emirates',
            country_AF: 'Afghanistan',
            country_AG: 'Antigua and Barbuda',
            country_AI: 'Anguilla',
            country_AL: 'Albania',
            country_AM: 'Armenia',
            country_AO: 'Angola',
            country_AQ: 'Antarctica',
            country_AR: 'Argentina',
            country_AS: 'American Samoa',
            country_AT: 'Austria',
            country_AU: 'Australia',
            country_AW: 'Aruba',
            country_AX: 'Åland Islands',
            country_AZ: 'Azerbaijan',
            country_BA: 'Bosnia and Herzegovina',
            country_BB: 'Barbados',
            country_BD: 'Bangladesh',
            country_BE: 'Belgium',
            country_BF: 'Burkina Faso',
            country_BG: 'Bulgaria',
            country_BH: 'Bahrain',
            country_BI: 'Burundi',
            country_BJ: 'Benin',
            country_BL: 'Saint Barthélemy',
            country_BM: 'Bermuda',
            country_BN: 'Brunei Darussalam',
            country_BO: 'Bolivia, Plurinational State of',
            country_BQ: 'Bonaire, Sint Eustatius and Saba',
            country_BR: 'Brazil',
            country_BS: 'Bahamas',
            country_BT: 'Bhutan',
            country_BV: 'Bouvet Island',
            country_BW: 'Botswana',
            country_BY: 'Belarus',
            country_BZ: 'Belize',
            country_CA: 'Canada',
            country_CC: 'Cocos (Keeling) Islands',
            country_CD: 'Congo, the Democratic Republic of the',
            country_CF: 'Central African Republic',
            country_CG: 'Congo',
            country_CH: 'Switzerland',
            country_CI: 'Côte d\'Ivoire',
            country_CK: 'Cook Islands',
            country_CL: 'Chile',
            country_CM: 'Cameroon',
            country_CN: 'China',
            country_CO: 'Colombia',
            country_CR: 'Costa Rica',
            country_CU: 'Cuba',
            country_CV: 'Cape Verde',
            country_CW: 'Curaçao',
            country_CX: 'Christmas Island',
            country_CY: 'Cyprus',
            country_CZ: 'Czech Republic',
            country_DE: 'Germany',
            country_DJ: 'Djibouti',
            country_DK: 'Denmark',
            country_DM: 'Dominica',
            country_DO: 'Dominican Republic',
            country_DZ: 'Algeria',
            country_EC: 'Ecuador',
            country_EE: 'Estonia',
            country_EG: 'Egypt',
            country_EH: 'Western Sahara',
            country_ER: 'Eritrea',
            country_ES: 'Spain',
            country_ET: 'Ethiopia',
            country_FI: 'Finland',
            country_FJ: 'Fiji',
            country_FK: 'Falkland Islands (Malvinas)',
            country_FM: 'Micronesia, Federated States of',
            country_FO: 'Faroe Islands',
            country_FR: 'France',
            country_GA: 'Gabon',
            country_GB: 'United Kingdom',
            country_GD: 'Grenada',
            country_GE: 'Georgia',
            country_GF: 'French Guiana',
            country_GG: 'Guernsey',
            country_GH: 'Ghana',
            country_GI: 'Gibraltar',
            country_GL: 'Greenland',
            country_GM: 'Gambia',
            country_GN: 'Guinea',
            country_GP: 'Guadeloupe',
            country_GQ: 'Equatorial Guinea',
            country_GR: 'Greece',
            country_GS: 'South Georgia and the South Sandwich Islands',
            country_GT: 'Guatemala',
            country_GU: 'Guam',
            country_GW: 'Guinea-Bissau',
            country_GY: 'Guyana',
            country_HK: 'Hong Kong',
            country_HM: 'Heard Island and McDonald Islands',
            country_HN: 'Honduras',
            country_HR: 'Croatia',
            country_HT: 'Haiti',
            country_HU: 'Hungary',
            country_ID: 'Indonesia',
            country_IE: 'Ireland',
            country_IL: 'Israel',
            country_IM: 'Isle of Man',
            country_IN: 'India',
            country_IO: 'British Indian Ocean Territory',
            country_IQ: 'Iraq',
            country_IR: 'Iran, Islamic Republic of',
            country_IS: 'Iceland',
            country_IT: 'Italy',
            country_JE: 'Jersey',
            country_JM: 'Jamaica',
            country_JO: 'Jordan',
            country_JP: 'Japan',
            country_KE: 'Kenya',
            country_KG: 'Kyrgyzstan',
            country_KH: 'Cambodia',
            country_KI: 'Kiribati',
            country_KM: 'Comoros',
            country_KN: 'Saint Kitts and Nevis',
            country_KP: 'Korea, Democratic People\'s Republic of',
            country_KR: 'Korea, Republic of',
            country_KW: 'Kuwait',
            country_KY: 'Cayman Islands',
            country_KZ: 'Kazakhstan',
            country_LA: 'Lao People\'s Democratic Republic',
            country_LB: 'Lebanon',
            country_LC: 'Saint Lucia',
            country_LI: 'Liechtenstein',
            country_LK: 'Sri Lanka',
            country_LR: 'Liberia',
            country_LS: 'Lesotho',
            country_LT: 'Lithuania',
            country_LU: 'Luxembourg',
            country_LV: 'Latvia',
            country_LY: 'Libya',
            country_MA: 'Morocco',
            country_MC: 'Monaco',
            country_MD: 'Moldova, Republic of',
            country_ME: 'Montenegro',
            country_MF: 'Saint Martin (French part)',
            country_MG: 'Madagascar',
            country_MH: 'Marshall Islands',
            country_MK: 'Macedonia, the former Yugoslav Republic of',
            country_ML: 'Mali',
            country_MM: 'Myanmar',
            country_MN: 'Mongolia',
            country_MO: 'Macao',
            country_MP: 'Northern Mariana Islands',
            country_MQ: 'Martinique',
            country_MR: 'Mauritania',
            country_MS: 'Montserrat',
            country_MT: 'Malta',
            country_MU: 'Mauritius',
            country_MV: 'Maldives',
            country_MW: 'Malawi',
            country_MX: 'Mexico',
            country_MY: 'Malaysia',
            country_MZ: 'Mozambique',
            country_NA: 'Namibia',
            country_NC: 'New Caledonia',
            country_NE: 'Niger',
            country_NF: 'Norfolk Island',
            country_NG: 'Nigeria',
            country_NI: 'Nicaragua',
            country_NL: 'Netherlands',
            country_NO: 'Norway',
            country_NP: 'Nepal',
            country_NR: 'Nauru',
            country_NU: 'Niue',
            country_NZ: 'New Zealand',
            country_OM: 'Oman',
            country_PA: 'Panama',
            country_PE: 'Peru',
            country_PF: 'French Polynesia',
            country_PG: 'Papua New Guinea',
            country_PH: 'Philippines',
            country_PK: 'Pakistan',
            country_PL: 'Poland',
            country_PM: 'Saint Pierre and Miquelon',
            country_PN: 'Pitcairn',
            country_PR: 'Puerto Rico',
            country_PS: 'Palestinian Territory, Occupied',
            country_PT: 'Portugal',
            country_PW: 'Palau',
            country_PY: 'Paraguay',
            country_QA: 'Qatar',
            country_RE: 'Réunion',
            country_RO: 'Romania',
            country_RS: 'Serbia',
            country_RU: 'Russian Federation',
            country_RW: 'Rwanda',
            country_SA: 'Saudi Arabia',
            country_SB: 'Solomon Islands',
            country_SC: 'Seychelles',
            country_SD: 'Sudan',
            country_SE: 'Sweden',
            country_SG: 'Singapore',
            country_SH: 'Saint Helena, Ascension and Tristan da Cunha',
            country_SI: 'Slovenia',
            country_SJ: 'Svalbard and Jan Mayen',
            country_SK: 'Slovakia',
            country_SL: 'Sierra Leone',
            country_SM: 'San Marino',
            country_SN: 'Senegal',
            country_SO: 'Somalia',
            country_SR: 'Suriname',
            country_SS: 'South Sudan',
            country_ST: 'Sao Tome and Principe',
            country_SV: 'El Salvador',
            country_SX: 'Sint Maarten (Dutch part)',
            country_SY: 'Syrian Arab Republic',
            country_SZ: 'Swaziland',
            country_TC: 'Turks and Caicos Islands',
            country_TD: 'Chad',
            country_TF: 'French Southern Territories',
            country_TG: 'Togo',
            country_TH: 'Thailand',
            country_TJ: 'Tajikistan',
            country_TK: 'Tokelau',
            country_TL: 'Timor-Leste',
            country_TM: 'Turkmenistan',
            country_TN: 'Tunisia',
            country_TO: 'Tonga',
            country_TR: 'Turkey',
            country_TT: 'Trinidad and Tobago',
            country_TV: 'Tuvalu',
            country_TW: 'Taiwan, Province of China',
            country_TZ: 'Tanzania, United Republic of',
            country_UA: 'Ukraine',
            country_UG: 'Uganda',
            country_UM: 'United States Minor Outlying Islands',
            country_US: 'United States',
            country_UY: 'Uruguay',
            country_UZ: 'Uzbekistan',
            country_VA: 'Holy See (Vatican City State)',
            country_VC: 'Saint Vincent and the Grenadines',
            country_VE: 'Venezuela, Bolivarian Republic of',
            country_VG: 'Virgin Islands, British',
            country_VI: 'Virgin Islands, U.S.',
            country_VN: 'Viet Nam',
            country_VU: 'Vanuatu',
            country_WF: 'Wallis and Futuna',
            country_WS: 'Samoa',
            country_YE: 'Yemen',
            country_YT: 'Mayotte',
            country_ZA: 'South Africa',
            country_ZM: 'Zambia',
            country_ZW: 'Zimbabwe',
            search_users: 'Zoek gebruikers',
            descriptives: 'beschrijvingen',
            manageusers: 'beheer gebruikers',
            manageteams: 'beheer teams',
            managesegments: 'beheer segmenten',
            managesettings: 'beheer instellingen',
            manageanalytics: 'beheer analyses',
            managecompany: 'beheer bedrijf',
            managepermissions: 'beheer toestemmingen',
            manageschedule: 'beheer planning',
            edit_this_x_action: 'bewerk deze {type} actie',
            add_a_x_action: 'Voeg een {type} actie toe',
            label: 'label',
            edit_entry_for: 'bewerk inzending voor',
            value: 'Waarde',
            total_users: 'totale gebruikers',
            download_template: 'Download template',
            click_to_add_csv: 'Importeer .csv bestand',
            import_users: 'Importeer gebruikers',
            x_invalid_rows: '{amt} ongeldige rijen',
            x_records_processed: '{amt} records verwerkt.',
            x_users_imported_successfully: '{amt} gebruikers succesvol geimporteerd.',
            x_users_could_not_be_imported: '{amt} gebruikers konden niet geimporteerd worden.',
            the_following_errors_occurred: 'De volgende fouten hebben zich voorgedaan',
            row: 'rij',
            close: 'sluit',
            first_x_records: 'Eerste {amt} records',
            cannot_be_empty: 'kan niet leeg zijn',
            cannot_be_empty_or_must_be_unique: 'kan niet leeg zijn of moet uniek zijn',
            must_be_dmyyyy_or_iso: 'moet d/m/yyyy of iso formaat zijn',
            field_value_restrictions: 'moet een van volgende zijn: {values}',
            invalid_country: 'ongeldig land',
            $ERR_REQ: 'Verplicht',
            $ERR_INVALID: 'Ongeldig',
            $ERR_UNIQ: 'Moet uniek zijn',
            import_teams: 'Importeer teams',
            title: 'titel',
            description: 'Beschrijving',
            root: 'root team',
            color: 'kleur',
            parents: 'parent teams',
            current: 'huidig',
            former: 'Vorige',
            orphaned: 'afzonderlijk',
            orphan_user_tooltip: 'Dit is een afzonderlijke gebruiker. Ze behoren niet tot een team.',
            no_parents_in_base: 'Een basis team zou geen parent team moeten hebben.',
            x_teams_imported_successfully: '{amt} teams succesvol geimporteerd.',
            x_teams_could_not_be_imported: '{amt} teams konden niet geimporteerd worden.',
            number: 'nummer',
            password_mail_sent: 'als de e-mail bestaat in ons systeem, hebben we een wachtwoord reset link gestuurd naar {email}',
            postalcode: 'postcode',
            stateprovince: 'staat/provincie',
            seniority: 'anciënniteit',
            passwords_do_not_match: 'wachtwoorden komen niet overeen',
            password_is_required: 'wachtwoorden zijn vereist',
            firstname_is_required: 'voornaam is vereist',
            lastname_is_required: 'achternaam is vereist',
            fill_in_all_fields: 'Gelieve alle velden in te vullen',
            search_teams_or_segments: 'Zoek teams of segmenten',
            search_blocks: 'zoek blokken',
            employmentstart: 'start tewerkstelling',
            employmentend: 'einde tewerkstelling',
            terminationreason: 'reden voor beëindiging',
            children: 'subs',
            submitting: 'Verzenden',
            reset_your_password: 'reset uw wachtwoord',
            professional: 'professioneel',
            starts_on: 'starts on',
            ended_on: 'Beëindigt op',
            fill_all_fields: 'Gelieve alle velden in te vullen',
            gender_m: 'man',
            gender_f: 'vrouw',
            gender_x: 'ander',
            tags: 'Tags',
            EB: 'Energiebronnen',
            Eng: 'Engagement',
            ENG: 'Engagement',
            HIN: 'Hinderpalen',
            PHB: 'Pers. hulpbronnen',
            UIT: 'Uitdagingen',
            WB: 'Wellbeing',
            AU: 'Autonomie',
            SL: 'Soc. steun leidinggevende',
            SC: 'Soc. steun collega\'s',
            VAR: 'Vaardigheidsbenutting',
            ZIN: 'Zinvolheid',
            TI: 'Taakidentiteit',
            LEE: 'Leermogelijkheden',
            FB: 'Feedback',
            WD: 'Werktempo- en hoeveelheid',
            COG: 'Cognitieve belasting',
            COM: 'Job complexiteit',
            EMO: 'Emotionele belasting',
            ONZ: 'Jobonzekerheid',
            ROA: 'Rolambiguiteit',
            ROC: 'Rolconflict',
            BUR: 'Bureaucratie',
            CON: 'Interpersoonlijke conflicten',
            POL: 'Politiek',
            OPT: 'Optimisme',
            VEE: 'Veerkracht',
            ZV1: 'Zelfvertrouwen',
            SE: 'Persoonlijke efficientie',
            MOT:'Motivatie',
            AT:'Attitudes',
            PR:'Prestatie',
            ONG:'Onwelbevinden',
            VIT:'Vitaliteit',
            TOE:'Toewijding',
            FLOW: 'Flow',
            TEVRE: 'Tevredenheid',
            BETROK: 'Organisatiebetrokkenheid',
            CPRES: 'Contextuele prestaties',
            TPRES:'Taak prestatie',
            UITPU:'Emotionele uitputting',
            PIEKE: 'Piekeren',
            SLAAP: 'Slaapkwaliteit',
            drag_to_calendar: 'Sleep naar de kalender',
            scheduled_deadline: 'geplande deadline',
            relative_importance_analyses: 'Relative Importance Analysis',
            ria: 'Relative Importance Analysis',
            please_log_in: 'Gelieve in te loggen',
            add_subteams: 'Voeg ook subteams toe',
            download_import_keys: 'Download import sleutels',
            invalid_reset_code: 'Het wachtwoord reset token dat u probeert te gebruiken is niet meer geldig. Als u nogsteeds u wachtwoord wilt resetten, ga dan terug naar de login om een nieuwe code aan te vragen.',
            start_date: 'Start datum',
            end_date: 'Eind datum',
            action_shared_with_team: 'Deze actie wordt gedeeld met andere leden in uw team.',
            drop_your_image_here: 'Plaats u afbeelding hier',
            delete: 'Verwijderen',
            img_max_size: 'Afbeelding moet kleiner zijn dan {amt}x{amt} pixels',
            unsaved_tooltip: 'Deze verandering is nog niet opgeslagen.',
            field_required: '{field} is vereist.',
            phone: 'telefoon',
            already_logged_in: 'U bent reeds ingelogd. U moet u uitloggen om toegang te krijgen tot deze link.',
            x_users_in_segment: 'Een segment heeft {amt} personen nodig om gemaakt te worden.',
            baseline_measurement: 'Nulmeting.',
            since_last_checkup: 'Sinds de laatste checkup',
            percentiles: 'Percentielen',
            feedback: 'Feedback',
            checkup_feedback: 'Checkup feedback',
            geef_feedback: 'geef feedback',
            bedankt: 'bedankt',
            je_antwoorden_blijven_prive: 'je antwoorden blijven prive',
            wil_je_ze_met_je_manager_delen_ontgrendel_dan_het_slotje: 'wil je ze met je manager delen? ontgrendel dan het slotje',
            je_antwoorden_worden_gedeeld_met_je_manager: 'je antwoorden worden gedeeld met je manager',
            wil_je_prive_houden_vergrendel_dan_het_slotje: 'wil je prive houden vergrendel dan het slotje',
            feedback_na_afronden: 'feedback na afronden',
            afronden: 'afronden',
            einde: 'einde',
            je_bent_er_bijna: 'je bent er bijna',
            bent_er_bijna_copy: 'bent er bijna copy',
            vragen_ingevuld: 'vragen ingevuld',
            nog_in_te_vullen: 'nog in te vullen',
            resterende_dagen: 'resterende dagen',
            je_antwoorden_zijn_prive: 'je antwoorden zijn prive',
            je_antwoorden_zijn_zichtbaar: 'je antwoorden zijn zichtbaar',
            afwerken: 'afwerken',
            amt_vragen: '{amt} vragen',
            wil_je_voor_jezelf_iets_noteren_in_verband_met: 'wil je voor jezelf iets noteren in verband met {title}',
            ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager: 'ik wil deze notities delen en bespreken met mijn manager',
            ga_terug_naar_dashboard: 'ga terug naar dashboard',
            noteer_hier: 'Schrijf hier iets...',
            notify_user_account: 'Informeer deze gebruiker',
            geschatte_duur: 'Geschatte duur',
            volledig_ingevuld: 'Volledig ingevuld',
            bekijken: 'Bekijken',
            afgesloten: 'Afgesloten',
            nog_geen_inzendingen_gedetecteerd: 'Nog geen inzendingen gedetecteerd',
            deadline_voorbij_zonder_inzending: 'Deadline voorbij zonder inzending',
            ingevuld_op: 'Ingevuld op:',
            password_too_short: 'wachtwoord moet minstens {amt} karakters bevatten',
            forgot_password: 'Wachtwoord vergeten?',
            send: 'verzenden',
            password: 'wachtwoord',
            log_in: 'log in',
            logging_in: 'inloggen',
            default_branch_tooltip: 'Dit is uw standaard bedrijfstak. Deze kan niet worden verwijderd',
            add_branch: 'Nieuwe bedrijfstak',
            logo_upload: 'Voeg een logo toe ({size})',
            main_branch: 'Hoofdtak',
            saving: 'opslaan',
            enable_actions: 'Acties inschakelen',
            require_review: 'Controle door manager vereist',
            show_results_before_end: 'Resultaten tonen voor het einde van een checkup periode',
            minimum_users_segment: 'Minimum aantal gebruikers in een segment',
            default_submission_anonimity: 'Standaard anonimiteit van inzending',
            private: 'privé',
            enable_analytics: 'Statistieken inschakelen',
            enable_heatmap: 'Heatmap inschakelen',
            enable_descriptives: 'Descriptives inschakelen',
            enable_network: 'Netwerkdiagram inschakelen',
            enable_histograms: 'Histogrammen inschakelen',
            enable_ria: 'Relative Importance Analysis inschakelen',
            min_users_population_analytics: 'Minimum aantal gebruikers voor populatiestatistieken',
            user_tags: 'Gebruikerslabels',
            message_tags: 'Berichtenlabels',
            statistics: 'statistieken',
            small: 'klein',
            large: 'groot',
            personal_data_period: 'Opslagtermijn persoonlijke gegevens',
            loading: 'Laden',
            message: 'bericht',
            subject: 'onderwerp',
            users_with_permissions: 'gebruikers met deze rechten',
            give_user_permission: 'Geef rechten aan gebruiker',
            enable_actions_description: 'Dit geeft gebruikers toegang om acties te creëren op het platform',
            require_review_description: 'Een persoonlijke review van een manager is verplicht.',
            minimum_users_segment_description: 'Segementen met minder dan deze hoeveelheid gebruikers kunnen niet gemaakt worden.',
            default_submission_anonimity_description: 'Definieert of inzendingen standaard privé of zichtbaar zijn voor managagers',
            enable_analytics_description: 'Schakelt de analytics pagina en zijn verschillende componenten in.',
            min_users_population_analytics_description: 'Kleinere segementen zullen de standaard analytics gebruiken',
            user_tags_description: 'Tags die op user accounts kunnen toegepast worden',
            add_tag: 'Voeg tags toe',
            message_tags_description: 'Tags die op berichten toegevoegd kunnen worden.',
            personal_data_period_description: 'Aantal dagen dat persoonlijke data opgeslagen wordt in de applicatie',
            send_email_to: 'Verstuur email naar',
            confirm_delete: 'Ben je zeker dat je { title } wilt verwijderen?',
            confirm_deletion: 'Bevestig verwijdering',
            checkup_has_entry: 'Checkup heeft een reeds begonnen inzending',
            checkup_will_be_copied: '"{title}" heeft een inzending die reeds begonnen is. Wanneer u verdergaat maakt u een kopie van de checkup met uw wijzigingen.',
            save_copy: 'Slaag een kopie op',
            connection_error: 'Er is iets misgegaan.',
            connection_error_body: 'Er zijn een aantal connectie problemen. Gelieve contact op te nemen met uw administrator.',
            edit_new_message: 'Bewerk dit {type} bericht',
            add_new_message: 'Voeg een {type} bericht toe',
            choose_team: 'Voeg een team toe ',
            add_a_manager: 'Voeg een manager toe',
            segment_contains: 'Segment bestaat uit',
            pick_a_color: 'Kies een kleur',
            no_answer: 'Geen antwoord',
            share_action_with_team: 'Deel deze actie met het team',
            dashboard:'dashboards',
            vitamins:'vitamines',
            actions:'acties',
            schedule:'planning',
            tree: "structuur",
            segments:'segmenten',
            analytics:'analytics',
            logout:'uitloggen',
            overview:'Overzicht',
            absoluteScores:'Absolute scores',
            viewSubmission:'Bekijk ingave',
            complete:'Compleet',
            deadline:'Deadline',
            start:'Start',
            noActiveCheckups:'Geen actieve checkups',
            zoomedIn:'Ingezoomd',
            yourOverallPerformance:'Uw algemene scores',
            personalActions:'Persoonlijke acties',
            teamActions:'Team acties',
            companyActions:'Bedrijfsacties',
            teamMessages:'Team berichten',
            companyMessages:'Bedrijfsberichten',
            addToPersonalCalendar:'Voeg toe aan persoonlijke kalender',
            thereAreNoVitaminsAvailable:'Er zijn geen vitamines beschikbaar',
            edit: 'Aanpassen',
            editEntry:'Wijzig ingave',
            selected: 'geselecteerd',
            writtenBy:'Geschreven door',
            editMessage:'Pas bericht aan',
            deleteMessage:'Verwijder bericht',
            goBack:'Vorige',
            addingMembers:'Leden toevoegen',
            confirm:'Bevestig',
            selectYourAnalysis:'Selecteer uw analyse',
            average:'Gemiddelde',
            questions:'Vragen',
            newPassword:'Nieuw paswoord',
            submit:'Bevestigen',
            resetting:'Resetting',
            goToLogin:'Ga naar login',
            by:'Door',
            readMore:'Lees meer',
            checkupEnds: 'Checkup: Eindigt',
            prive:'Privé',
            shared:'Gedeeld',
            active:'Actief',
            starts:'Start op',
            ends:'Eindigt',
            grace:'Respijt',
            selectAnOption:'Selecteer een optie',
            deleteUserAccount:'Verwijder een gebruiker',
            thisIsAMainBranch:'Dit is de hoofdtak',
            parentTeams:'Parent teams',
            myTeams:'Mijn teams',
            standard:'Standaard',
            allMyTeams:'Al mijn teams',
            allCompanyTeams:'Alle bedrijfsteams',
            comments:'Opmerkingen',
            thisUserHasNoSubmissions:'Deze gebruiker heeft geen ingaves',
            operator:'Operator',
            manages:'Beheert',
            jobLevel:'Job level',
            saveAsAPreset:'Sla op als standaard',
            showAll:'Toon alle',
            factor:'Factor',
            searchToAdd: 'Zoek {userListType} om toe te voegen ...',
            enterATitle:'Voeg een titel toe',
            newTag:'Nieuwe tag',
            unlimited:'Ongelimiteerd',
            all_questions: 'Alle vragen',
            unanswered_only: 'onbeantwoord',
            managers: 'managers',
            language: 'taal',
            user_has_been_saved: 'Deze gebruiker is succesvol opgeslagen',
            user_has_been_deleted: 'Deze gebruiker is verwijderd',
            company_branch: 'Bedrijfstak',
            no_branch: 'Geen bedrijfstak',
            position: 'positie',
            delete_teams: 'verwijder {amt} teams',
            select_all: 'alle selecteren',
            all_teams: 'alle teams',
            import_managers: 'managers importeren',
            participation: 'participatie',
            sendResetMail: 'Verstuur wachtwoord reset mail',
            password_reset_mail_sent: "De e-mail is verstuurd naar {email}",
            definitions: 'definities',
            processed_on: 'verwerkt op',
            this_message: 'dit bericht',
            analytics_team_too_small: 'Analytics voor dit team zijn niet beschikbaar omdat het niet de minimale teamgrootte bereikt',
            minimum_users_team: 'Minimum aantal gebruikers voor zichtbaarheid team',
            minimum_users_team_message: 'Aantal gebruikers nodig om een team in statistieken te zien',
        }
    },
    "fr-FR": {
        message: {
            'Helemaal oneens': 'Pas du tout d\'accord',
            'Oneens': 'Pas d\'accord',
            'Eerder oneens': 'Plutôt pas d\'accord',
            'Noch eens, noch oneens': 'Ni en désaccord ni d\'accord',
            'Eerder eens': 'Plutôt d\'accord',
            'Eens': 'D\'accord',
            'Helemaal eens': 'Tout à fait d\'accord',
            'Nooit': 'Jamais',
            'Sporadisch': 'Occasionnellement',
            'Af en toe': 'Parfois',
            'Regelmatig': 'Régulièrement',
            'Dikwijls': 'Souvent',
            'Zeer dikwijls': 'Très souvent',
            'Altijd': 'Toujours',
            'Helemaal ontevreden': 'Totalement insatisfait',
            'Ontevreden': 'Insatisfait',
            'Eerder ontevreden': 'Plutôt insatisfait',
            'Noch tevreden, noch ontevreden': 'Ni satisfait ni insatisfait',
            'Eerder tevreden': 'Plutôt satisfait',
            'Tevreden': 'Satisfait',
            'Helemaal tevreden': 'Entièrement satisfait',
            loading_app: 'Loading the application',
            influenced_by: 'est influencé par',
            influences: 'Has influence on',
            for: 'pour',
            apply: 'appliquer',
            cancel: 'annuler',
            save: 'sauvegarder',
            saved:'stocké',
            add: 'ajouter',
            company: 'entreprise',
            filter: 'filtre',
            'import': 'importer',
            personal: 'personel',
            user: 'utilisateur',
            team: 'équipe',
            segment: 'segment',
            company_tree: 'company tree',
            segment_tree: 'segment tree',
            checkup: 'check-up',
            checkups: 'check-ups',
            all: 'Tous',
            any: 'aux',
            none: 'aucun',
            users_match: 'les utilisateurs correspondent',
            of_the_following_conditions: 'conditions suivantes',
            planned: 'prévu',
            completed: 'completé',
            calendar: 'calendrier',
            profile: 'profile',
            personal_data: 'données personelles',
            account: 'compte',
            firstname: 'prénom',
            first_name: 'prénom',
            lastname: 'nom de famille',
            last_name: 'nom de famille',
            birthdate: 'date de naissance',
            gender: 'Genre',
            male: 'mâle',
            female: 'femellle',
            other: 'autre',
            not_married: 'ne pas marié',
            married: 'marié',
            widow: 'veuve',
            widower: 'veuf',
            education: 'éducation',
            transportation: 'transportation',
            contact_data: 'contact data',
            street: 'rue',
            nr: 'numéro',
            bus: 'bus',
            postal_code: 'code postal',
            city: 'ville',
            country: 'Pays',
            number_of_children: 'nombre d\'enfants',
            search: 'rechercher',
            duration: 'duration',
            graceperiod: 'délai de grâce',
            repeatafter: 'répéter après',
            days: 'jours',
            block: 'bloquer',
            add_a_team_or_segment: 'ajoute une équipe ou un segment',
            team_or_segment: 'team ou segment',
            add_a_checkup_block: 'ajoute un checkup block',
            action: 'action',
            available: 'disponible',
            presets: 'préconfigurations',
            add_a_filter: 'ajoute un filtre',
            add_title: 'ajoute un titre',
            manager: 'manager',
            permissions: 'permissions',
            general: 'general',
            car: 'voiture',
            bike: 'vélo',
            maritalstate: 'état matrimonial',
            notmarried: 'ne pas marié',
            widowed: 'widowed',
            transport: 'transport',
            jobtitle: 'profession',
            developer: 'développeur',
            owner: 'owner',
            projectmanager: 'project manager',
            privacy_notifications: 'notifications de confidentialité',
            field_options: 'options de champs',
            faq: 'FAQ',
            name: 'nom',
            teams: 'équipes',
            teams_tree: 'teams tree',
            base_team_tooltip: 'This is a base team. It sits at the top level in the tree.',
            orphan_team_tooltip: 'This team is orphaned. It has no parent and will not be visible in the tree.',
            primary: 'primair',
            secondary: 'secondair',
            bachelor: 'licence',
            master: 'master',
            phd: 'doctorat',
            foot: 'à pied',
            min_max: 'min/max',
            mean: 'moyenne',
            public: 'publique',
            email: 'E-mail',
            standard_deviation: 'écart-type',
            change_password: 'changer le mot de passe',
            confirm_password: 'confirmer le mot de passe',
            checkup_analytics: 'checkup analyse',
            min: 'min',
            team_average: 'moyenne de l\'équipe',
            max: 'max',
            heatmap: 'heatmap',
            network: 'réseau',
            users: 'utilisateurs',
            settings: 'paramètres',
            privacy: 'privacy',
            emails: 'emails',
            privacy_data: 'données concercant la vie privée',
            field_settings: 'paramètres de champs',
            roles_permissions: 'permissions concernant les rôles',
            histogram: 'histogramme',
            not_translated: 'not localisé',
            translate: 'traduire',
            no_actions_available: 'Pas d\'actions disponible.',
            no_data_available: 'pas de données disponsibles.',
            no_messages_available: 'pas de messages disponsibles.',
            no_longer_employed_since: 'n\'est plus employé depuis',
            country_AD: 'Andorra',
            country_AE: 'United Arab Emirates',
            country_AF: 'Afghanistan',
            country_AG: 'Antigua and Barbuda',
            country_AI: 'Anguilla',
            country_AL: 'Albania',
            country_AM: 'Armenia',
            country_AO: 'Angola',
            country_AQ: 'Antarctica',
            country_AR: 'Argentina',
            country_AS: 'American Samoa',
            country_AT: 'Austria',
            country_AU: 'Australia',
            country_AW: 'Aruba',
            country_AX: 'Åland Islands',
            country_AZ: 'Azerbaijan',
            country_BA: 'Bosnia and Herzegovina',
            country_BB: 'Barbados',
            country_BD: 'Bangladesh',
            country_BE: 'Belgium',
            country_BF: 'Burkina Faso',
            country_BG: 'Bulgaria',
            country_BH: 'Bahrain',
            country_BI: 'Burundi',
            country_BJ: 'Benin',
            country_BL: 'Saint Barthélemy',
            country_BM: 'Bermuda',
            country_BN: 'Brunei Darussalam',
            country_BO: 'Bolivia, Plurinational State of',
            country_BQ: 'Bonaire, Sint Eustatius and Saba',
            country_BR: 'Brazil',
            country_BS: 'Bahamas',
            country_BT: 'Bhutan',
            country_BV: 'Bouvet Island',
            country_BW: 'Botswana',
            country_BY: 'Belarus',
            country_BZ: 'Belize',
            country_CA: 'Canada',
            country_CC: 'Cocos (Keeling) Islands',
            country_CD: 'Congo, the Democratic Republic of the',
            country_CF: 'Central African Republic',
            country_CG: 'Congo',
            country_CH: 'Switzerland',
            country_CI: 'Côte d\'Ivoire',
            country_CK: 'Cook Islands',
            country_CL: 'Chile',
            country_CM: 'Cameroon',
            country_CN: 'China',
            country_CO: 'Colombia',
            country_CR: 'Costa Rica',
            country_CU: 'Cuba',
            country_CV: 'Cape Verde',
            country_CW: 'Curaçao',
            country_CX: 'Christmas Island',
            country_CY: 'Cyprus',
            country_CZ: 'Czech Republic',
            country_DE: 'Germany',
            country_DJ: 'Djibouti',
            country_DK: 'Denmark',
            country_DM: 'Dominica',
            country_DO: 'Dominican Republic',
            country_DZ: 'Algeria',
            country_EC: 'Ecuador',
            country_EE: 'Estonia',
            country_EG: 'Egypt',
            country_EH: 'Western Sahara',
            country_ER: 'Eritrea',
            country_ES: 'Spain',
            country_ET: 'Ethiopia',
            country_FI: 'Finland',
            country_FJ: 'Fiji',
            country_FK: 'Falkland Islands (Malvinas)',
            country_FM: 'Micronesia, Federated States of',
            country_FO: 'Faroe Islands',
            country_FR: 'France',
            country_GA: 'Gabon',
            country_GB: 'United Kingdom',
            country_GD: 'Grenada',
            country_GE: 'Georgia',
            country_GF: 'French Guiana',
            country_GG: 'Guernsey',
            country_GH: 'Ghana',
            country_GI: 'Gibraltar',
            country_GL: 'Greenland',
            country_GM: 'Gambia',
            country_GN: 'Guinea',
            country_GP: 'Guadeloupe',
            country_GQ: 'Equatorial Guinea',
            country_GR: 'Greece',
            country_GS: 'South Georgia and the South Sandwich Islands',
            country_GT: 'Guatemala',
            country_GU: 'Guam',
            country_GW: 'Guinea-Bissau',
            country_GY: 'Guyana',
            country_HK: 'Hong Kong',
            country_HM: 'Heard Island and McDonald Islands',
            country_HN: 'Honduras',
            country_HR: 'Croatia',
            country_HT: 'Haiti',
            country_HU: 'Hungary',
            country_ID: 'Indonesia',
            country_IE: 'Ireland',
            country_IL: 'Israel',
            country_IM: 'Isle of Man',
            country_IN: 'India',
            country_IO: 'British Indian Ocean Territory',
            country_IQ: 'Iraq',
            country_IR: 'Iran, Islamic Republic of',
            country_IS: 'Iceland',
            country_IT: 'Italy',
            country_JE: 'Jersey',
            country_JM: 'Jamaica',
            country_JO: 'Jordan',
            country_JP: 'Japan',
            country_KE: 'Kenya',
            country_KG: 'Kyrgyzstan',
            country_KH: 'Cambodia',
            country_KI: 'Kiribati',
            country_KM: 'Comoros',
            country_KN: 'Saint Kitts and Nevis',
            country_KP: 'Korea, Democratic People\'s Republic of',
            country_KR: 'Korea, Republic of',
            country_KW: 'Kuwait',
            country_KY: 'Cayman Islands',
            country_KZ: 'Kazakhstan',
            country_LA: 'Lao People\'s Democratic Republic',
            country_LB: 'Lebanon',
            country_LC: 'Saint Lucia',
            country_LI: 'Liechtenstein',
            country_LK: 'Sri Lanka',
            country_LR: 'Liberia',
            country_LS: 'Lesotho',
            country_LT: 'Lithuania',
            country_LU: 'Luxembourg',
            country_LV: 'Latvia',
            country_LY: 'Libya',
            country_MA: 'Morocco',
            country_MC: 'Monaco',
            country_MD: 'Moldova, Republic of',
            country_ME: 'Montenegro',
            country_MF: 'Saint Martin (French part)',
            country_MG: 'Madagascar',
            country_MH: 'Marshall Islands',
            country_MK: 'Macedonia, the former Yugoslav Republic of',
            country_ML: 'Mali',
            country_MM: 'Myanmar',
            country_MN: 'Mongolia',
            country_MO: 'Macao',
            country_MP: 'Northern Mariana Islands',
            country_MQ: 'Martinique',
            country_MR: 'Mauritania',
            country_MS: 'Montserrat',
            country_MT: 'Malta',
            country_MU: 'Mauritius',
            country_MV: 'Maldives',
            country_MW: 'Malawi',
            country_MX: 'Mexico',
            country_MY: 'Malaysia',
            country_MZ: 'Mozambique',
            country_NA: 'Namibia',
            country_NC: 'New Caledonia',
            country_NE: 'Niger',
            country_NF: 'Norfolk Island',
            country_NG: 'Nigeria',
            country_NI: 'Nicaragua',
            country_NL: 'Netherlands',
            country_NO: 'Norway',
            country_NP: 'Nepal',
            country_NR: 'Nauru',
            country_NU: 'Niue',
            country_NZ: 'New Zealand',
            country_OM: 'Oman',
            country_PA: 'Panama',
            country_PE: 'Peru',
            country_PF: 'French Polynesia',
            country_PG: 'Papua New Guinea',
            country_PH: 'Philippines',
            country_PK: 'Pakistan',
            country_PL: 'Poland',
            country_PM: 'Saint Pierre and Miquelon',
            country_PN: 'Pitcairn',
            country_PR: 'Puerto Rico',
            country_PS: 'Palestinian Territory, Occupied',
            country_PT: 'Portugal',
            country_PW: 'Palau',
            country_PY: 'Paraguay',
            country_QA: 'Qatar',
            country_RE: 'Réunion',
            country_RO: 'Romania',
            country_RS: 'Serbia',
            country_RU: 'Russian Federation',
            country_RW: 'Rwanda',
            country_SA: 'Saudi Arabia',
            country_SB: 'Solomon Islands',
            country_SC: 'Seychelles',
            country_SD: 'Sudan',
            country_SE: 'Sweden',
            country_SG: 'Singapore',
            country_SH: 'Saint Helena, Ascension and Tristan da Cunha',
            country_SI: 'Slovenia',
            country_SJ: 'Svalbard and Jan Mayen',
            country_SK: 'Slovakia',
            country_SL: 'Sierra Leone',
            country_SM: 'San Marino',
            country_SN: 'Senegal',
            country_SO: 'Somalia',
            country_SR: 'Suriname',
            country_SS: 'South Sudan',
            country_ST: 'Sao Tome and Principe',
            country_SV: 'El Salvador',
            country_SX: 'Sint Maarten (Dutch part)',
            country_SY: 'Syrian Arab Republic',
            country_SZ: 'Swaziland',
            country_TC: 'Turks and Caicos Islands',
            country_TD: 'Chad',
            country_TF: 'French Southern Territories',
            country_TG: 'Togo',
            country_TH: 'Thailand',
            country_TJ: 'Tajikistan',
            country_TK: 'Tokelau',
            country_TL: 'Timor-Leste',
            country_TM: 'Turkmenistan',
            country_TN: 'Tunisia',
            country_TO: 'Tonga',
            country_TR: 'Turkey',
            country_TT: 'Trinidad and Tobago',
            country_TV: 'Tuvalu',
            country_TW: 'Taiwan, Province of China',
            country_TZ: 'Tanzania, United Republic of',
            country_UA: 'Ukraine',
            country_UG: 'Uganda',
            country_UM: 'United States Minor Outlying Islands',
            country_US: 'United States',
            country_UY: 'Uruguay',
            country_UZ: 'Uzbekistan',
            country_VA: 'Holy See (Vatican City State)',
            country_VC: 'Saint Vincent and the Grenadines',
            country_VE: 'Venezuela, Bolivarian Republic of',
            country_VG: 'Virgin Islands, British',
            country_VI: 'Virgin Islands, U.S.',
            country_VN: 'Viet Nam',
            country_VU: 'Vanuatu',
            country_WF: 'Wallis and Futuna',
            country_WS: 'Samoa',
            country_YE: 'Yemen',
            country_YT: 'Mayotte',
            country_ZA: 'South Africa',
            country_ZM: 'Zambia',
            country_ZW: 'Zimbabwe',
            search_users: 'Chercher des utilisateurs',
            descriptives: 'descriptifs',
            manageusers: 'géere des utilisateurs',
            manageteams: 'gérer des équipes',
            managesegments: 'gérer des segments',
            managesettings: 'gérer les paramètres',
            manageanalytics: 'gérer les analyses',
            managecompany: 'gérer l\'entreprise',
            managepermissions: 'gérer les permissions',
            manageschedule: 'gérer le calendrier',
            edit_this_x_action: 'modifier ce {type} d\'action',
            add_a_x_action: 'ajouter un {type} d\'action',
            label: 'l\'étiquette',
            edit_entry_for: 'Modifié la soummission pour',
            value: 'Valeur',
            total_users: 'tous les utilisateurs',
            download_template: 'Download template',
            click_to_add_csv: 'Importer un fichier .csv ',
            import_users: 'Importer les utilisateurs',
            x_invalid_rows: '{amt} invalid rows',
            x_records_processed: '{amt} enregistrements traités.',
            x_users_imported_successfully: '{amt} d\'utilisateurs importés.',
            x_users_could_not_be_imported: '{amt} d\'utilisateurs ne pouvaient users could not be imported.',
            the_following_errors_occurred: 'Les erreurs suivantes se sont produites',
            row: 'row',
            close: 'Fermer',
            first_x_records: 'First {amt} records',
            cannot_be_empty: 'doit être rempli',
            cannot_be_empty_or_must_be_unique: 'doit être rempli ou doit être unique',
            must_be_dmyyyy_or_iso: 'doit être j/m/aaaa ou iso format',
            field_value_restrictions: 'doit être un des: {values}',
            invalid_country: 'pays n\'est rempli pas correcte',
            $ERR_REQ: 'Obligatoire',
            $ERR_INVALID: 'Invalide',
            $ERR_UNIQ: 'Doit être unique',
            import_teams: 'Importer les équipes',
            title: 'titre',
            description: 'La description',
            root: 'root team',
            color: 'couleur',
            parents: 'équipe parental',
            current: 'actuelle',
            former: 'précédent',
            orphaned: 'orphelins',
            orphan_user_tooltip: 'L\'utilisateur n\'est pas partie d\'une équipe.',
            no_parents_in_base: 'Une équipe de base n\'a pas besoin d\'équipe parental.',
            x_teams_imported_successfully: '{amt} équipes ont été importés.',
            x_teams_could_not_be_imported: '{amt} équipes n\'ont pas été importés.',
            number: 'numéro',
            password_mail_sent: 'si l\'e-mail existe dans notre système, nous avons envoyé un lien de réinitialisation du mot de passe à {email}.',
            postalcode: 'code postale',
            stateprovince: 'département/province',
            seniority: 'ancienneté',
            passwords_do_not_match: 'Les mots de passe sont différents passwords do not match',
            password_is_required: 'Un mot de passe est obligatoire',
            firstname_is_required: 'Le prénom est obligatoire',
            lastname_is_required: 'Le nom de famille est obligatoire',
            fill_in_all_fields: 'Veuilliez remplir tous les champs',
            search_teams_or_segments: 'Chercher des équipes ou des segments',
            search_blocks: 'Chercher des blocks',
            employmentstart: 'début d\'emploi',
            employmentend: 'fin d\'emploi',
            terminationreason: 'raison de termination',
            children: 'enfants',
            submitting: 'submitting',
            reset_your_password: 'réinitialiser votre mot de passe',
            professional: 'professionelle',
            starts_on: 'commence à',
            ended_on: 'Fini à',
            fill_all_fields: 'Veuillez remplir tous les champs',
            gender_m: 'Masculin',
            gender_f: 'Fémininmale',
            gender_x: 'autre',
            tags: 'Mots clés',
            EB: 'Ressources énergétiques',
            Eng: 'Engagement',
            ENG: 'Engagement',
            HIN: 'Obstacles',
            PHB: 'Ressources personnelles',
            UIT: 'Défis',
            WB: 'Wellbeing',
            AU: 'Autonomy',
            SL: 'Social support manager',
            SC: 'Social support colleagues',
            VAR: 'Skill utilisation',
            ZIN: 'Meaningfulness',
            TI: 'Task identity',
            LEE: 'Learning opportunities',
            FB: 'Feedback',
            WD: 'Work pace and amount of work',
            COG: 'Cognitive load',
            COM: 'Job complexity',
            EMO: 'Emotional exhaustion',
            ONZ: 'Job insecurity',
            ROA: 'Role ambiguity',
            ROC: 'Role conflict',
            BUR: 'Bureaucracy',
            CON: 'Interpersonal conflicts',
            POL: 'Politics',
            OPT: 'Optimism',
            VEE: 'Resilience',
            ZV1: 'Self-confidence',
            SE: 'Personal resources',
            MOT:'Motivation',
            AT:'Attitudes',
            PR:'Performance',
            ONG:'Insalubrité',
            VIT:'Vitality',
            TOE:'Dedication',
            FLOW: 'Flow',
            TEVRE: 'Job satisfaction',
            BETROK: 'Organizational commitment',
            CPRES: 'Contextual performance',
            TPRES:'Task performance',
            UITPU:'Emotional exhaustion',
            PIEKE: 'Worrying',
            SLAAP: 'Sleep quality',
            drag_to_calendar: 'drag to calendar',
            scheduled_deadline: 'scheduled deadline',
            relative_importance_analyses: 'Analyse de l\'importance relative',
            ria: 'Analyse de l\'importance relative',
            please_log_in: 'Vieuillez vous connecter',
            add_subteams: 'Veuillez comprendre des équipes child',
            download_import_keys: 'Download import keys',
            invalid_reset_code: 'Ce lien pour réinitialiser votre mot de passe n\'est plus valable . Veuillez demandez un nouveau lien.',
            start_date: 'Start date',
            end_date: 'End date',
            action_shared_with_team: 'Cet action à été partager avec votre équipe.',
            drop_your_image_here: 'Drop your image here',
            delete: 'Sumprimer',
            img_max_size: 'Cet image doit être plus petit que {amt}x{amt} pixels',
            unsaved_tooltip: 'Cette modifications n\'a pas encore été sauvegarder.',
            field_required: '{field} est obligatoire.',
            phone: 'téléphone',
            already_logged_in: 'Vous êtes déjà connectés. Vous devez vous deconnecter pour acceder le lien. ',
            x_users_in_segment: 'Un segment à besoin de {amt} personnes.',
            baseline_measurement: 'Mesure zéro.',
            since_last_checkup: 'Depuis le checkup précedent',
            percentiles: 'Percentiles',
            feedback: 'Feedback',
            checkup_feedback: 'Checkup feedback',
            geef_feedback: 'Donne du feedback',
            bedankt: 'Merci',
            je_antwoorden_blijven_prive: 'Vos réponses resteront privées',
            wil_je_ze_met_je_manager_delen_ontgrendel_dan_het_slotje: 'Est-ce que vous voulez partager ces informations avec votre manager, déverrouille la serrure do you want to share them with your manager? click to unlock',
            je_antwoorden_worden_gedeeld_met_je_manager: 'Vos réponses seront partagées avec votre manager',
            wil_je_prive_houden_vergrendel_dan_het_slotje: 'Veuillez cacher vos réponses. Taper pour verrouiller.',
            feedback_na_afronden: 'Veuillez donner du feedback après?',
            afronden: 'finish',
            einde: 'end',
            je_bent_er_bijna: 'Vous êtes presqu\' à la fin.',
            bent_er_bijna_copy: 'Votre soumission est presque complète.',
            vragen_ingevuld: 'questions répondus',
            nog_in_te_vullen: 'questions restantes',
            resterende_dagen: 'jours restants',
            je_antwoorden_zijn_prive: 'Vos réponses sont chachés',
            je_antwoorden_zijn_zichtbaar: 'Vos réponses sont visibles.',
            afwerken: 'finir',
            amt_vragen: '{amt} questions',
            wil_je_voor_jezelf_iets_noteren_in_verband_met: 'Voulez-vous commenter quelque chose concernant',
            ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager: 'Je veux partager les commentaires et en discuter avec mon manager',
            ga_terug_naar_dashboard: 'Retourner au dashboard',
            noteer_hier: 'Ecris ici...',
            notify_user_account: 'Notifiez cet utilisateur',
            geschatte_duur: 'Durée estimée',
            volledig_ingevuld: 'Complété',
            bekijken: 'Voir',
            afgesloten:'Fermée',
            nog_geen_inzendingen_gedetecteerd: 'Aucune soumission détectée',
            deadline_voorbij_zonder_inzending: 'Délai dépassé sans soumission',
            ingevuld_op: 'Complétées sur:',
            password_too_short: "le mot de passe doit contenir au moins {amt} caractères",
            forgot_password: 'Oublié votre mot de passe ?',
            send: 'envoyer',
            password: 'mot de passe',
            log_in: 'connectez',
            logging_in: 'connecter',
            default_branch_tooltip: 'Il s\'agit de votre branche d\'entreprise par défaut. Elle ne peut pas être supprimée',
            add_branch: 'Add branch',
            logo_upload: 'Choose a logo ({size})',
            main_branch: 'Main branch',
            saving: 'saving',
            enable_actions: 'Enable actions',
            require_review: 'Manager review required',
            show_results_before_end: 'Show results before the end of a checkup period',
            minimum_users_segment: 'Minimum amount of users in segments',
            default_submission_anonimity: 'Default submission anonimity',
            private: 'private',
            enable_analytics: 'Enable analytics',
            enable_heatmap: 'Enable heatmap',
            enable_descriptives: 'Enable descriptives',
            enable_network: 'Enable network',
            enable_histograms: 'Enable histograms',
            enable_ria: 'Enable Relative Importance Analysis',
            min_users_population_analytics: 'Minimum amount of users for population analytics',
            user_tags: 'User tags',
            message_tags: 'Message tags',
            statistics: 'statistics',
            small: 'small',
            large: 'large',
            personal_data_period: 'Personal data storage duration',
            loading: 'loading',
            message: 'message',
            subject: 'subject',
            users_with_permissions: 'users with this permission',
            give_user_permission: 'Give user permission',
            enable_actions_description: 'This enables users to create actions on the platform',
            require_review_description: 'A personal review from a manager is required',
            minimum_users_segment_description: 'Segments with less than this amount of users cannot be made.',
            default_submission_anonimity_description: 'Defines if submissions are private or visible to managers by default.',
            enable_analytics_description: 'Enables the analytics page and its different components',
            min_users_population_analytics_description: 'Smaller segments will use the default analytics',
            user_tags_description: 'Tags that can be applied to user accounts',
            add_tag: 'Ajouter des balises',
            message_tags_description: 'Tags that can be applied to messages',
            personal_data_period_description: 'Amount of days personal data will be stored in the application',
            send_email_to: 'Send email to',
            confirm_delete: 'Êtes-vous sûr de vouloir supprimer {title} ?',
            confirm_deletion: 'Confirm deletion',
            checkup_has_entry: 'Checkup has a started entry',
            checkup_will_be_copied: '"{title}" has an entry that has already started. Continuing will create a copy of this checkup with your changes.',
            save_copy: 'Save a copy',
            connection_error: 'Something went wrong.',
            connection_error_body: 'There are some connection issues. Please contact your administrator.',
            edit_new_message: 'Edit this {type} message',
            add_new_message: 'Add a {type} message',
            choose_team: 'Choose a team',
            add_a_manager: 'Add a manager',
            segment_contains: 'Segment contains',
            pick_a_color: 'Pick a color',
            no_answer: 'No answer',
            share_action_with_team: 'Share action with the team',
            dashboard:'dashboard',
            vitamins:'vitamins',
            actions:'actions',
            schedule:'calendrier',
            tree:'structure',
            segments:'segments',
            analytics:'analytics',
            logout:'Se déconnecter',
            overview:'Aperçu',
            absoluteScores:'Scores absolus',
            viewSubmission:'Voir la soumission',
            complete:'Compléter',
            deadline:'Date limite',
            start:'Début',
            noActiveCheckups:'Aucun checkup actif',
            zoomedIn:'Zoomé',
            yourOverallPerformance:'Votre performance globale',
            personalActions:'Actions personelles',
            teamActions:"Actions d'équipe",
            companyActions:"Actions de l'entreprise",
            teamMessages:"Messages d'équipe",
            companyMessages:"Messages de l'entreprise",
            addToPersonalCalendar:'Ajouter au calendrier personnel',
            thereAreNoVitaminsAvailable:"Il n'y a pas de vitamines disponibles",
            edit: 'Éditer',
            editEntry:"Modifier l'entrée",
            selected: 'choisi',
            writtenBy:'Écrit par',
            editMessage:'Modifier le message',
            deleteMessage:'Supprimer le message',
            goBack:'Retourner',
            addingMembers:'Ajout de membres',
            confirm:'Confirmer',
            selectYourAnalysis:'Sélectionnez votre analyse',
            average:'Moyenne',
            questions: 'Des questions',
            newPassword:'Nouveau mot de passe',
            submit:'Soumettre',
            resetting:'Réinitialisation',
            goToLogin:'Aller à la connexion',
            by:'Par',
            readMore:'Lire plus',
            checkupEnds: 'Checkup: Se terminé',
            prive:'Privé',
            shared: 'Partagé',
            active: 'Actif',
            starts:'Départs',
            ends:'Prend fin',
            grace:'La grâce',
            selectAnOption:'Sélectionner une option',
            deleteUserAccount:'Supprimer le compte utilisateur',
            thisIsAMainBranch:"C'est une branche principale",
            parentTeams:'Équipes de parents',
            myTeams:'Mes équipes',
            standard:'Standard',
            allMyTeams:'Toutes mes équipes',
            allCompanyTeams:"Toutes les équipes de l'entreprise",
            comments: 'Commentaires',
            thisUserHasNoSubmissions:"Cet utilisateur n'a pas de soumissions",
            operator:'Opérateur',
            manages:'Gère',
            jobLevel:"Le niveau d'emploi",
            saveAsAPreset:'Enregistrer en tant que préréglage',
            showAll:'Afficher tout',
            factor:'Facteur',
            searchToAdd: 'Rechercher {userListType} pour ajouter ...',
            enterATitle:'Ajouter un titre',
            newTag:'Nouveau balise',
            unlimited:'Illimité',
            all_questions: 'Toutes les questions',
            unanswered_only: 'sans réponse',
            managers: 'managers',
            language: 'langue',
            user_has_been_saved: 'Cet utilisateur a été sauvegardé avec succès',
            user_has_been_deleted: 'Cet utilisateur a été supprimé',
            company_branch: 'Branche de l\'entreprise',
            no_branch: 'Pas de branche d\'entreprise',
            position: 'position',
            delete_teams: 'supprime {amt} équipes',
            select_all: 'sélectionnez tout',
            all_teams: 'toutes les équipes',
            import_managers: 'importer des managers',
            participation: 'participation',
            password_reset_mail_sent: "L'e-mail a été envoyé à {email}",
            definitions: 'definitions',
            processed_on: 'traité le',
            this_message: 'ce message',
            analytics_team_too_small: 'Les analyses pour cette équipe ne sont pas disponibles car elle n\'atteint pas la taille minimale de l\'équipe.',
            minimum_users_team: 'Minimum amount of users for team visibility',
            minimum_users_team_message: 'The amount of users needed for a team to show up in analytics',
        }
    }
}