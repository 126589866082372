<template>
  <div class="input-group mb-0" :class="isOpen ? 'open' : 'closed'">
    <!--    {{isOpen}}-->
    <input
      type="text"
      :class="size === 'lg' ? 'input-lg' : ''"
      :placeholder="placeholder || $t('message.search')"
      aria-label="Username"
      v-model="val"
      @focusout.prevent=""
      @keyup="handleKeyup($event)"
      @blur="handleBlur($event)"
      aria-describedby="basic-addon1"
    />
    <span
      tabindex="0"
      @click="handleClick($event)"
      class="input-group-text btn btn-secondary p-0"
    >
      <img
        class="hide-dark"
        src="../../../assets/icons/Icon-navigation-search.svg"
        alt=""
      />
      <img
        class="hide-light"
        src="../../../assets/icons/Icon-navigation-search-light.svg"
        alt=""
      />
    </span>
  </div>
</template>
<script>
export default {
  props: ["size", "placeholder", "alwaysOpen"],
  data() {
    return {
      val: "",
      isOpen: !!this.alwaysOpen,
    };
  },
  methods: {
    search() {
      // console.log('searching from', this.$el.querySelector('input'))
      this.$emit("search", this.val);
    },

    handleBlur(e) {
      // console.log('search val', this.val, e)
      let hide = true;
      if (this.val) {
        hide = false;
      }
      if (e.relatedTarget) {
        if (e.relatedTarget.classList.contains("input-group-text")) {
          hide = false;
        }
      }
      if (hide) {
        this.val = "";
        if (!this.alwaysOpen) {
          this.isOpen = false;
        }
      }
    },
    handleKeyup(e) {
      if (e.key === "Enter" || e.keyCode === 13 || !this.val) {
        this.search();
      }
    },
    handleClick(e) {
      //if shown: search, else: open
      if (this.isOpen) {
        this.search();
      } else {
        this.isOpen = true;
      }
      this.$emit("click", e);
      this.$el.querySelector("input").focus();
    },
  },
};
</script>
<style lang="scss" scoped>
.closed {
  input {
    padding: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;

    &.input-lg {
      //width: 40px;
    }
  }

  .input-group-text {
    //z-index: 1;
    margin-left: 0 !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}

.open {
  input {
    width: calc(100% - 32px);

    &.input-lg {
      width: calc(100% - 42px);
    }
  }

  .input-group-text {
    border-left: 0 !important;
  }
}

input {
  transition: 0.3s all;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
}

.input-lg {
  + .input-group-text {
    width: 42px;
  }
}

@media all and (max-width: 768px) {
  .open,
  .closed {
    input {
      width: calc(100% - 32px);
      padding: 0 12px;
      overflow: initial;
      opacity: 1;

      &.input-lg {
        width: calc(100% - 42px);
      }
    }

    .input-group-text {
      border-left: 0 !important;
      margin-left: -1px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
}
</style>