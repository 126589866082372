import {createStore} from 'vuex'
import {Modal} from 'bootstrap';
import authModule from './modules/auth/index.js'
import settingsModule from './modules/settings/index.js'

export default createStore({
    state: {
        activeTreeTeam: null,
        modal: null,
        modalContent: null,
        modalProps: null,
        modalEvents: {},
        modalOptions: {},
        actions: [],
        faq: {},
        company: null,
        activeVitaminsCount: 0,
        message: null,
    },
    mutations: {
        setActiveVitaminsCount(state, vitamins) {
            state.activeVitaminsCount = vitamins
        },
        setCompany(state, company) {
            state.company = company
        },
        setFaq(state, faq) {
            state.faq = faq
        },
        setActions(state, actions) {
            state.actions = actions
        },
        addAction(state, action) {
            state.actions.push(action)
        },
        setActiveTreeTeam(state, team) {
            state.activeTreeTeam = team
        },
        setModal(state, {element, options}) {
            if (state.modal) {
                // console.log('found modal', options)
                state.modal.dispose()
                state.modal = null
            }
            state.modal = new Modal(element, options)
        },
        setModalContent(state, component) {
            state.modalContent = component
        },
        setModalProps(state, props) {
            state.modalProps = props
        },
        setModalEvents(state, events) {
            state.modalEvents = events
        },
        setModalOptions(state, options) {
            state.modalOptions = options
        },
        setMessage(state, message) {
            state.message = message;
        },
        toggleModal(state, status) {
            if (status) {
                state.modal.show()
            } else {
                state.modal.hide()
            }
        },
    },
    actions: {
        addAction({commit}, action) {
            commit('addAction', action);
        },
        addMessage({commit}, message) {
            commit('setMessage', message);
        },
        removeMessage({commit}) {
          commit('setMessage', null);
        },
        openModal({commit}, {component, properties, options, events = null}) {
            commit('setModalProps', properties);
            commit('setModalEvents', events);
            if (options) {
                commit('setModalOptions', options);
            }
            commit('setModalContent', component);
            const el = document.getElementById('primaryModal');
            commit('setModal', {
                element: el,
                options: {backdrop: options && options.clickOutside ? true : 'static'}
            })
            commit('toggleModal', true)
        },
        closeModal({commit, dispatch}) {
            // TODO: Fix up togglemodal: hiding re-triggers closeModal
            commit('toggleModal', false)
            dispatch('clearModal')
            // commit('setModalProps', null);
            // commit('setModalEvents', {});
            // commit('setModalOptions', {});
            // commit('setModalContent', null);
        },
        clearModal({ commit }) {
            commit('setModalProps', null);
            commit('setModalEvents', {});
            commit('setModalOptions', {});
            commit('setModalContent', null);
        }
    },
    modules: {
        auth: authModule,
        settings: settingsModule,

    },
    getters: {
        getActiveVitaminsCount(state) {
            return state.activeVitaminsCount
        },
        getFaq(state) {
            return state.faq
        },
        getMainCompany(state) {
            return state.company
        },
        getModalComponent(state) {
            return state.modalContent
        },
        getModalProps(state) {
            return state.modalProps
        },
        getModalEvents(state) {
            return state.modalEvents
        },
        getModalOptions(state) {
            return state.modalOptions
        },
        getModal(state) {
            return state.modal
        },
        getActiveTreeTeam(state) {
            return state.activeTreeTeam
        },
        getActions(state) {
            return state.actions
        },
        getMessage(state) {
            return state.message
        }
    }
})

//store active tree item in store for Tree
//easier to keep track