<template>
  <div class="chartwrapper bar">
    <canvas id="chart"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  mounted() {
    try {
      this.initChart();
      // this.loadData()
    } catch (e) {
      console.error(e);
    }
  },
  data() {
    let _this = this;
    return {
      options: {
        plugins: {
          legend: {
            display: !this.hidelegend,
          },
        },
        animation: {
          duration: 0,
        },
        scales: {
          y: {
            max: 100,
            min: 0,
          },
          // labels:{
          //   position:'bottom'
          // },
          x: {
            display: !this.hidexlabels,
            min: 0,
            max: 5,
            type: "linear",
            position: "bottom",
            ticks: {
              stepSize: 1,
              callback: function (val, ind) {
                return _this.labels[ind];
              },
            },
            title: {
              display: true,
              // text:this.labels[0]
            },
          },
        },
      },
      chart: null,
      // config: {
      //   type: this.hideLine ? 'bar' : 'scatter',
      //   data : {
      //     labels: this.labels,
      //     datasets: this.datasets
      //   },
      //   options: this.options
      // }
    };
  },
  computed: {
    config() {
      return {
        type: this.hideLine ? "bar" : "scatter",
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: this.options,
      };
    },
  },
  props: [
    "hidelegend",
    "hidexlabels",
    "labels",
    "datasets",
    "hideLine",
    // 'options',
  ],
  watch: {
    datasets() {
      // console.log('data changed')
      this.initChart();
      // this.initChart()
    },
  },
  methods: {
    // loadData() {
    //   // console.log('loading data')
    //   this.chart.data.labels = this.labels
    //   this.chart.data.datasets= this.datasets
    //   // console.log('starting update', this.chart)
    //   // this.chart.update()
    // },
    initChart() {
      if (this.chart && this.chart.destroy) {
        this.chart.destroy();
      }
      this.chart = new Chart(
        this.$el.querySelector("canvas#chart"),
        this.config
      );
    },
  },
};
</script>