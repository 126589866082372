import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Signin from "../views/Signin";
import store from '../store/index.js';
import Vitamins from "@/views/Vitamins";
import CheckupForm from "@/views/CheckupForm";
import UserResetPassword from "@/views/UserResetPassword";
// import axios from "@/axios-auth";

const routes = [
    {
        path: '/',
        alias:'/dashboard',
        meta: {requiresAuth: true},
        name: 'dashboard',
        component: Home,

    },
    {
        path: '/login',
        meta: {requiresUnAuth: true},
        name: 'login',
        component: Signin
    },
    {
        path: '/reset',
        meta: {
            requiresUnAuth: true,
            passThrough: true
        },
        name: 'reset',
        component: UserResetPassword,
        async beforeEnter(to, from, next) {
            /*
            * check if user is authenticated.
            * if user is authenticated attach a message on redirect to dashboard
            */
            const auth = store.getters.isAuthenticated
            if (auth) {
                await store.dispatch('addMessage', {
                    type: 'danger',
                    content: 'message.already_logged_in',
                    duration: 5000,
                });
                next('/');
            } else {
                next();
            }
        }
    },
    {
        path: '/vitamins',
        meta: {requiresAuth: true},
        name: 'vitamins',
        component: Vitamins
        // component: () => import(/* webpackChunkName: "vitamins" */ '../views/Vitamins.vue')
    },
    {
        path: '/vitamins/checkup/:id',
        meta: {requiresAuth: true},
        name: 'checkup',
        // props: true,
        // route level code-splitting
        // this generates a separate chunk (vitamins.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CheckupForm/*() => import('../views/CheckupForm.vue')*/
    },
    {
        path: '/actions',
        meta: {requiresAuth: true},
        name: 'actions',
        component: () => import('../views/Actions.vue'),
        async beforeEnter(to, from, next) {
            let settings = store.getters.getPublicSettings;
            if (!settings) {
                await store.dispatch('fetchPublicSettings');
                settings = store.getters.getPublicSettings;
            }
            if (settings.actionsenabled) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/profile',
        meta: {requiresAuth: true},
        name: 'profile',
        component: () => import( '../views/Profile.vue')
    },
    {
        path: '/schedule',
        meta: {requiresAuth: true},
        name: 'schedule',
        component: () => import('../views/Schedule.vue'),
        beforeEnter(to, from, next) {
            const user = store.getters.getUser
            if (user.manageschedule || user.managecompany) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/checkups',
        meta: {requiresAuth: true},
        name: 'checkups',
        component: () => import('../views/Checkups.vue'),
        beforeEnter(to, from, next) {
            const user = store.getters.getUser
            if (user.ismanager || user.manageteams || user.managecompany) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/tree',
        meta: {requiresAuth: true},
        name: 'tree',
        component: () => import(/* webpackChunkName: "tree" */ '../views/Tree.vue'),
        beforeEnter(to, from, next) {
            const user = store.getters.getUser
            if (user.ismanager || user.manageteams || user.managecompany) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/segments',
        meta: {requiresAuth: true},
        name: 'segments',
        component: () => import(/* webpackChunkName: "segments" */ '../views/Segments.vue'),
        async beforeEnter(to, from, next) {
            const user = store.getters.getUser
            const settings = store.getters.getPublicSettings;

            if (!settings) {
                await store.dispatch('fetchPublicSettings');
            }
            if (user.managesegments || user.managecompany) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/analytics',
        meta: {requiresAuth: true},
        name: 'analytics',
        component: () => import(/* webpackChunkName: "heatmap" */ '../views/Heatmap.vue'),
        async beforeEnter(to, from, next) {
            let settings = store.getters.getPublicSettings;
            const user = store.getters.getUser
            if (!settings) {
                await store.dispatch('fetchPublicSettings');
                settings = store.getters.getPublicSettings;
            }
            if (settings.statsenabled && (user.manageanalytics || user.managecompany)) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/users',
        meta: {requiresAuth: true},
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
        beforeEnter(to, from, next) {
            const user = store.getters.getUser
            if (user.manageusers || user.managecompany) {
                next()
            } else {
                next('/')
            }
        }
    },
    {
        path: '/settings',
        meta: {requiresAuth: true},
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        beforeEnter(to, from, next) {
            const user = store.getters.getUser
            if (user.managesettings || user.managecompany) {
                next()
            } else {
                next('/')
            }
        }
    },
    { path: '/:catchAll(.*)', redirect: '/' }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async function (to, from, next) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const userStorage = localStorage.getItem('user');
    let user = null;
    if (userStorage) {
        user = JSON.parse(localStorage.getItem('user'));
    }
    if(token){
        store.commit('setToken', token)
        store.commit('setUser', {
            token: token,
            userId: userId,
            user: user
        })
    }
    const auth = store.getters.isAuthenticated
    // console.log('trying to route: ', to ,', auth:',auth)
    if (to.meta.requiresAuth && !auth) {
        next('/login');
    } else if (to.meta.requiresUnAuth && auth) {
        if (to.meta.passThrough) next(); //* Allow routes with passthrough meta attribute to pass to their own navigation guards
        next('/');
    } else {
        next();
    }
})

export default router
