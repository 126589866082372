import axios from 'axios';

// fetch('/config.json').then(res => res.json()).then(data => {
//
// });
//
// let url = process.env.VUE_APP_ROOT_API + ':' + process.env.VUE_APP_ROOT_API_PORT;

// const authrequest = axios.create({
//     baseURL: url
// });

async function createAxios() {
    let url = '';
    try {
        if (process.env.NODE_ENV == "production") {
            const res = await fetch('/config.json');
            const config = await res.json();

            url = config.API_HOST + ':' + config.API_PORT;
        } else {
            url = process.env.VUE_APP_ROOT_API + ':' + process.env.VUE_APP_ROOT_API_PORT;
        }
    } catch (err) {
        url = process.env.VUE_APP_ROOT_API + ':' + process.env.VUE_APP_ROOT_API_PORT;
    }

    const axiosClient = axios.create({
        baseURL: url
    });

    return axiosClient;
}

export default createAxios();


// export default authrequest;