<template>
  <form @submit.stop.prevent="submitAction" class="action-form">
    <!--    {{type}}-->
    <!--    id: {{ id }}-->
    <select
      required
      v-model="teamid"
      class="w-100 mb-3"
      v-if="type === 'team'"
      name=""
      id=""
    >
      <option disabled value="">{{ $t('message.choose_team') }}</option>
      <option v-bind:key="team" v-for="team in teams" :value="team.id">
        {{ team.title }}
      </option>
    </select>
    <textarea
      v-model="body"
      :placeholder="$t('message.description')"
      name=""
      id=""
      cols="30"
      rows="5"
      class="w-100 mb-3"
    ></textarea>
    <div class="bottom-actions d-flex mb-0">
      <div v-if="type !== 'personal'" class="col d-flex align-items-center">
        <checkbox v-model="visible"></checkbox>
        <label class="mb-0 text-sentence" for="">{{ $t('message.share_action_with_team') }}</label>
      </div>
      <div class="ms-auto col-auto">
        <button v-if="!loading" type="submit" class="btn btn-primary">
          <span class="text-sentence">{{ $t('message.save') }}</span>
        </button>
        <button v-else class="btn disabled d-flex" type="button" disabled>
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{ $t("message.loading") }}</span>
          </div>
          <span class="ms-2 text-sentence">{{ $t("message.submitting") }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Checkbox from "@/views/components/simple/Checkbox";
import qs from "qs";

export default {
  props: {
    type: {
      default() {
        return "personal";
      },
    },
    defer: {
      default() {
        return false;
      },
    },
    id: null,
  },
  created() {
    // console.log('')
    this.getManagedTeams();
    if (this.id) {
      this.getAction();
    }
  },
  components: { Checkbox },
  emits: ["submitActionForm"],
  data() {
    return {
      loading: false,
      teams: [],
      body: "",
      completed: false,
      visible: false,
      teamid: "",
    };
  },
  computed: {
    isbase() {
      return this.type === "company" ? true : false;
    },
  },
  methods: {
    async getManagedTeams() {
      const q = qs.stringify({
        _where: [
          {
            _or: [{ isbase_ne: true }, { isbase_null: true }],
          },
          {
            _or: [{ issegment_ne: true }, { issegment_null: true }],
          },
        ],
      });
      // const get = await this.axios.get('/swarms/overlords?issegment_ne=true&isbase_ne=true')
      const get = await this.axios.get("/swarms/overlords?" + q);
      this.teams = get.data;
    },
    async getAction() {
      const get = await this.axios.get("/actions/" + this.id);
      const action = get.data;
      this.body = action.body;
      this.completed = action.completed;
      this.visible = action.visible;
      this.teamid = action.teamid;
    },
    async submitAction() {
      this.loading = true;
      const action = {
        isbase: this.isbase,
        body: this.body,
        completed: this.completed,
        visible: this.visible,
        teamid: String(this.teamid),
      };
      if (this.defer) {
        this.$emit("submitActionForm", action);
      } else {
        if (this.id !== null) {
          const request = await this.axios.put("/actions/" + this.id, action);
          this.$emit("submitActionForm", request.data);
        } else {
          const request = await this.axios.post("/actions", action);
          this.$emit("submitActionForm", request.data);
        }
      }
      this.loading = false;
    },
  },
};
</script>