<template>
  <form class="select-wrapper" autocomplete="off" @submit.prevent="">
    <div class="selected-options" v-if="showSelected">
      <div
        class="selected-option"
        v-bind:key="option"
        v-for="(option, ind) in selected"
        :data-value="option[value]"
      >
        <span class="title">{{ option[label] }}</span>
        <span @click.stop="unSelectOption($event, option, ind)" class="remove"
          ><img src="../../../assets/icons/icon-navigation-close.svg" alt=""
        /></span>
      </div>
    </div>
    <input
      @keyup="handleSearch($event)"
      @focusout.stop="handleBlur"
      @focus="handleFocus"
      v-model="search"
      autocomplete="off"
      name="search"
      :placeholder="placeholder"
      type="text"
      class="select-search"
    />
    <div v-show="listVisible" class="select-options" style="z-index:1">
      <div
        @mousedown.stop="selectOption($event, option, ind)"
        v-bind:key="option"
        v-for="(option, ind) in filteredOptions"
        class="select-option"
        :data-value="option[value]"
      >
        {{ parsedLabel(option) }}
      </div>
    </div>
    <!--    {{listOptions}}-->
  </form>
</template>
<script>
//receive option objects and which keys to use as value and label
//ajax = boolean, if true, pass query
import qs from "qs";

export default {
  props: [
    "options",
    "value",
    "label",
    "ajax",
    "query",
    "showSelected",
    "placeholder",
    "clearOnSelect",
    "showFocus",
  ],
  data() {
    return {
      listVisible: false,
      selected: [],
      limit: 10,
      listOptions: [],
      search: "",
      filteredOptions: [],
    };
  },
  computed: {
    foundOptions() {
      return this.listOptions.filter((option) => {
        return (
          option.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  emits: ["selectOption", "search"],
  methods: {
    parsedLabel(option) {
      let s = [];
      if (Array.isArray(this.label)) {
        this.label.forEach((label) => {
          s.push(option[label]);
        });
        s = s.join(" ");
      } else {
        s = option[this.label];
      }
      return s;
    },
    async handleFocus() {
      if (this.showFocus && this.ajax) {
        this.listOptions = await this.getOptions();
        this.filteredOptions = this.listOptions.slice(0, 5);
        this.listVisible = true;
      }
      // this.listVisible = true;
      // if (this.ajax) {
      //   this.listOptions = await this.getOptions()
      //   this.filteredOptions = this.listOptions
      // }
    },
    handleBlur() {
      this.listVisible = false;
      // console.log('blur')
      //if target is NOT an option, listVisible = false
    },
    async handleSearch(e) {
      this.$emit("search", e.target.value);
      if (e.target.value.length > 2) {
        if (this.ajax) {
          this.listOptions = await this.getOptions();
          this.filteredOptions = this.listOptions;
        } else {
          this.filteredOptions = this.foundOptions;
        }
        if (this.filteredOptions.length > 0) {
          this.listVisible = true;
        }
      } else if (e.target.value.length === 0) {
        this.listVisible = false;
        this.listOptions = [];
        // this.listOptions = await this.getOptions()
        this.filteredOptions = this.listOptions;
      }
    },
    selectOption(e, option, ind) {
      if (this.clearOnSelect !== false) {
        this.search = "";
      } else {
        if (Array.isArray(this.label)) {
          let s = [];
          this.label.forEach((label) => {
            s.push(option[label]);
          });
          this.search = s.join(" ");
        } else {
          this.search = option[this.label];
        }
      }
      this.listOptions.splice(ind, 1);
      this.selected.push(option);
      this.$emit("selectOption", option);
      this.listVisible = false;
      // console.log('selecting option')
    },
    unSelectOption(e, option, ind) {
      this.selected.splice(ind, 1);
      this.listOptions.push(option);
    },
    async getOptions() {
      let query =
        this.label + "_contains=" + this.search + "&_limit=" + this.limit;
      if (Array.isArray(this.label)) {
        const queryOb = {
          _where: {
            _or: [],
          },
          _limit: this.limit,
        };
        this.label.forEach((label) => {
          queryOb._where._or.push({ [label + "_contains"]: this.search });
        });
        query = qs.stringify(queryOb);
      }

      let s = "?";
      if (this.query.indexOf("?") > -1) {
        s = "&";
      }
      // let limit = s + '_limit=' + this.limit
      // const get = await this.axios.get(this.query + limit + '&' + this.label + '_contains=' + this.search
      const get = await this.axios.get(this.query + s + query);
      return get.data;
    },
  },
  async mounted() {
    this.listOptions = this.options;
    this.filteredOptions = this.listOptions;
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  position: relative;
}

.select-search {
  width: 100%;
}

.select-options {
  position: absolute;
  left: 8px;
  width: calc(100% - 16px);
  background-color: #fff;
  padding: 5px;
  border: 1px solid #eee;

  .select-option {
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }
}
</style>