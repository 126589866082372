export default {
    userId(state) {
        return state.userId;
    },
    token(state) {
        return state.token;
    },
    isAuthenticated(state) {
        // const token = localStorage.getItem('token')
        // console.log('getting token', token)
        // if (token) {
        //     console.log('token found')
        //     return true
        // } else {
        //     console.log('no token')
        //     return false
        // }
        // return !!token;
        return !!state.token;
        // return state.auth;
    },
    isAuthDataFetched(state) {
        return state.auth;
    },
    getAxios(state) {
        return state.axios
    },
    getUser(state) {
        return state.user
    },
}