<template>
  <div>
    <div class="page-checkup mb-3" v-if="retrieved">
      <div
        class="
          mb-md-3
          checkup-top
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <h2 class="d-none d-md-block">
          <span class="text-sentence"
            >{{ $t("message.vitamins") }} <em>{{ $t("message.for") + " " }}</em>
            <span v-if="user">{{ user.firstname }}</span></span
          ><!--Jonas-->
        </h2>
        <div class="w-25 d-none d-md-block">
          <div>{{ completedPct.toFixed(0) }}% {{ $t("message.complete") }}</div>
          <div class="progress">
            <div
              class="progress-bar bgc-theme c-600"
              role="progressbar"
              :style="'width: ' + completedPct + '%'"
              :aria-valuenow="completedPct"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!finishing">
        <div
          class="col-md-6 d-md-block"
          v-if="activeVitamin"
          :class="showNotes ? 'd-none' : 'd-block'"
        >
          <div class="h-100 card">
            <div class="p-0 card-header" :class="'bgc-' + activeVitamin.color">
              <div class="nav" :class="'bgc-' + activeVitamin.color + ' c-50'">
                <ul class="nav nav-pills p-0">
                  <li class="nav-item dropdown p-0">
                    <a
                      class="card-header-nav-btn nav-link p-0"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      <img
                        src="../assets/icons/icon-navigation-expand.svg"
                        alt=""
                      />
                    </a>
                    <ul class="dropdown-menu">
                      <li
                        v-bind:key="vitamin"
                        v-for="(vitamin, index) in vitamins.vitamins"
                      >
                        <a
                          @click.prevent="
                            () => {
                              showNotes = false;
                              activeVitaminIndex = index;
                              activeFactorIndex = 0;
                              activeQuestionIndex = 0;
                            }
                          "
                          class="dropdown-item"
                          href="#"
                        >
                          {{
                            $i18n.locale === "en"
                              ? vitamin.title
                              : vitamin["title_" + $i18n.locale] ||
                                vitamin.title
                          }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <span class="ps-3 text-sm overline">
                {{ activeVitaminIndex + 1 }}/
                {{ vitamins.vitamins.length }}
                {{
                  $i18n.locale === "en"
                    ? activeVitamin.title
                    : activeVitamin["title_" + $i18n.locale] ||
                      activeVitamin.title
                }}
                •
                <!--              {{ activeVitamin.title }} •-->
                <!--              {{ vitaminQuestionLength }} -->
                {{ $t("message.amt_vragen", { amt: vitaminQuestionLength }) }}
              </span>
            </div>
            <div class="card-body">
              <div class="btn-group d-block mb-2">
                <button class="btn btn-sm btn-secondary text-sm" @click="onlyEmptyQuestions = false" :class="!onlyEmptyQuestions && 'active'">{{ $t('message.all_questions') }}</button>
                <button class="btn btn-sm btn-secondary text-sm" @click="onlyEmptyQuestions = true"  :class="onlyEmptyQuestions && 'active'">{{ $t('message.unanswered_only') }}</button>
              </div>
              <!--          <template>-->
              <h5 :class="'c-' + activeVitamin.color + ' c-200 text-sentence'">
                {{
                  $i18n.locale === "en"
                    ? activeFactor.title
                    : activeFactor["title_" + $i18n.locale] ||
                      activeFactor.title
                }}
              </h5>
              <p>
                {{ activeQuestionIndex + 1 }}.
                {{
                  $i18n.locale === "en"
                    ? activeQuestion.title
                    : activeQuestion["title_" + $i18n.locale] ||
                      activeQuestion.title
                }}
              </p>
              <ul class="checkup-question-options list-group list-group-flush">
                <template
                  v-for="scaleOption in activeFactor.scalegroup.scaleoptions"
                  v-bind:key="scaleOption.id"
                >
                  <li class="px-0 list-group-item">
                    <label class="cursor-pointer text-md text-sentence">
                      <input
                        type="radio"
                        @change="handleAnswerQuestion"
                        :name="'question-' + activeQuestion.id"
                        :value="scaleOption.value"
                        v-model="questionValues[activeMainIndex]"
                      />&nbsp;
                      <span class="text-sentence">{{
                        $t("message." + scaleOption.title)
                      }}</span>
                    </label>
                  </li>
                </template>
              </ul>
              <div
                    class="
                      position-absolute
                      top-50
                      start-50
                      translate-middle
                      container-spinner
                    "
                    :class="questionLoader ? '' : 'd-none'"
                  >
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden"
                        >{{ $t("message.loading") }}...</span
                      >
                    </div>
                  </div>

              <nav aria-label="Page navigation example" class="mt-3">
                <ul class="pagination justify-content-between mb-0">
                  <li
                    v-if="
                      activeVitaminIndex > 0 ||
                      activeFactorIndex > 0 ||
                      activeQuestionIndex > 0
                    "
                    class="page-item"
                    @click.prevent.stop="pageBack"
                  >
                    <a class="btn" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <img
                          class="d-block img-fluid"
                          src="../assets/icons/previous.svg"
                          alt=""
                        />
                      </span>
                    </a>
                  </li>

                  <li class="ms-md-autopage-item d-block d-md-none">
                    <div>
                      {{ completedPct.toFixed(0) }}%
                      {{ $t("message.complete") }}
                    </div>
                    <div class="progress">
                      <div
                        class="progress-bar bgc-theme c-600"
                        role="progressbar"
                        :style="'width: ' + completedPct + '%'"
                        :aria-valuenow="completedPct"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </li>
                  <li
                    v-if="
                      activeVitaminIndex < vitamins.vitamins.length - 1 ||
                      activeFactorIndex < activeVitamin.factors.length - 1 ||
                      activeQuestionIndex < activeFactor.questions.length - 1
                    "
                    class="ms-md-auto page-item"
                    @click.prevent.stop="submitCheckup"
                  >
                    <a class="btn" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <img
                          class="d-block img-fluid"
                          src="../assets/icons/next.svg"
                          alt=""
                        />
                      </span>
                    </a>
                  </li>

                  <template v-else>
                    <!--                  <li class="ms-auto page-item" @click.prevent.stop="submitCheckup(false)">-->
                    <!--                    <a class="btn bgc-koraalrood" href="#" aria-label="Next">-->
                    <!--                  <span aria-hidden="true">-->
                    <!--                    <img class="d-block img-fluid" src="../assets/icons/next.svg" alt="">-->
                    <!--                  </span>-->
                    <!--                    </a>-->
                    <!--                  </li>-->
                    <li
                      class="ms-md-auto page-item"
                      @click.prevent.stop="submitCheckup()"
                    >
                      <a class="btn" href="#" aria-label="Next">
                        <span aria-hidden="true">
                          <img
                            class="d-block img-fluid"
                            src="../assets/icons/next.svg"
                            alt=""
                          />
                        </span>
                      </a>
                    </li>
                  </template>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 d-md-block"
          v-if="activeVitamin"
          :class="showNotes ? 'd-block' : 'd-none'"
        >
          <div class="h-100 card">
            <div
              class="d-none d-md-flex c-50 card-header"
              :class="'bgc-' + activeVitamin.color"
            ></div>
            <div
              class="p-0 card-header d-flex d-md-none"
              :class="'bgc-' + activeVitamin.color"
            >
              <div class="nav" :class="'bgc-' + activeVitamin.color + ' c-50'">
                <ul class="nav nav-pills p-0">
                  <li class="nav-item dropdown p-0">
                    <a
                      class="card-header-nav-btn nav-link p-0"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      <img
                        src="../assets/icons/icon-navigation-expand.svg"
                        alt=""
                      />
                    </a>
                    <ul class="dropdown-menu">
                      <li
                        v-bind:key="vitamin"
                        v-for="(vitamin, index) in vitamins.vitamins"
                      >
                        <a
                          @click.prevent="
                            () => {
                              showNotes = false;
                              activeVitaminIndex = index;
                              activeFactorIndex = 0;
                              activeQuestionIndex = 0;
                            }
                          "
                          class="dropdown-item"
                          href="#"
                          >{{ vitamin.title }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <span class="ps-3 text-sm overline">
                {{ activeVitaminIndex + 1 }}/
                {{ vitamins.vitamins.length }}
                {{ activeVitamin.title }} • {{ vitaminQuestionLength }}
                {{ $t("message.questions") }}
              </span>
            </div>
            <div class="card-body">
              <h5>&nbsp;</h5>
              <p class="text-sentence">
                {{
                  $t("message.wil_je_voor_jezelf_iets_noteren_in_verband_met", {
                    title:
                      $i18n.locale === "en"
                        ? activeFactor.title
                        : activeFactor["title_" + $i18n.locale] ||
                          activeFactor.title,
                  })
                }}
              </p>
              <textarea
                class="w-100"
                name=""
                id=""
                cols="30"
                rows="10"
                v-model="notes[activeNoteIndex]"
                :placeholder="$t('message.noteer_hier')"
              ></textarea>
              <!--            <checkbox :modelValue="currentNoteStatus"></checkbox>-->
              <input
                class="invisible styled-checkbox"
                v-model="currentNoteStatus[activeNoteIndex]"
                type="checkbox"
                name="notePrivacy"
                id="notePrivacy"
              />
              <label for="notePrivacy" class="d-inline-block cursor-pointer text-sentence">
                <img
                  v-show="currentNoteStatus[activeNoteIndex]"
                  src="../assets/icons/icon-toggle-check-box.svg"
                  alt=""
                />
                <img
                  v-show="!currentNoteStatus[activeNoteIndex]"
                  src="../assets/icons/icon-toggle-check-box_off.svg"
                  alt=""
                />
              </label>
              <span class="text-sm text-sentence">{{
                $t(
                  "message.ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager"
                )
              }}</span>

              <nav
                aria-label="Page navigation example"
                class="mt-3 d-md-none"
                :class="showNotes ? 'd-block' : 'd-none'"
              >
                <ul class="pagination justify-content-between mb-0">
                  <li
                    v-if="
                      activeVitaminIndex > 0 ||
                      activeFactorIndex > 0 ||
                      activeQuestionIndex > 0
                    "
                    class="page-item"
                    @click.prevent.stop="pageBack"
                  >
                    <a class="btn" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <img
                          class="d-block img-fluid"
                          src="../assets/icons/previous.svg"
                          alt=""
                        />
                      </span>
                    </a>
                  </li>

                  <li class="ms-md-auto page-item d-block d-md-none">
                    <div>
                      {{ completedPct.toFixed(0) }}%
                      {{ $t("message.complete") }}
                    </div>
                    <div class="progress">
                      <div
                        class="progress-bar bgc-theme c-600"
                        role="progressbar"
                        :style="'width: ' + completedPct + '%'"
                        :aria-valuenow="completedPct"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </li>
                  <li
                    v-if="
                      activeVitaminIndex < vitamins.vitamins.length - 1 ||
                      activeFactorIndex < activeVitamin.factors.length - 1 ||
                      activeQuestionIndex < activeFactor.questions.length - 1
                    "
                    class="page-item"
                    @click.prevent.stop="submitCheckup"
                  >
                    <a class="btn" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <img
                          class="d-block img-fluid"
                          src="../assets/icons/next.svg"
                          alt=""
                        />
                      </span>
                    </a>
                  </li>

                  <template v-else>
                    <li
                      class="ms-md-auto page-item"
                      @click.prevent.stop="submitCheckup()"
                    >
                      <a class="btn" href="#" aria-label="Next">
                        <span aria-hidden="true">
                          <img
                            class="d-block img-fluid"
                            src="../assets/icons/next.svg"
                            alt=""
                          />
                        </span>
                      </a>
                    </li>
                  </template>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else-if="validated && done">
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header"><span class="overline"></span></div>
            <div class="card-body d-flex flex-column">
              <h4 class="text-sentence">{{ $t("message.feedback") }}</h4>
              <p class="text-sentence">{{ $t("message.checkup_feedback") }}</p>
              <textarea
                v-show="!feedbackSent"
                v-model="feedback"
                class="w-100"
                name=""
                id="checkup-feedback"
                cols="30"
                rows="10"
              ></textarea>
              <div class="actions row">
                <div class="col-auto mx-auto">
                  <button
                    v-if="!feedbackSent"
                    @click="sendFeedback"
                    class="btn btn-primary me-2"
                  >
                    <div
                      style="height: 12px; width: 12px"
                      v-if="loadingFeedback"
                      class="spinner-border"
                      role="status"
                    >
                      <span class="visually-hidden"
                        >{{ $t("message.loading") }}...</span
                      >
                    </div>
                    <span v-if="!loadingFeedback" class="text-sentence">{{
                      $t("message.geef_feedback")
                    }}</span>
                  </button>
                  <router-link
                    :to="{
                      name: 'dashboard',
                    }"
                    class="btn btn-secondary"
                    ><span v-if="feedbackSent" class="text-sentence"
                      >{{ $t("message.bedankt") + ", " }} </span
                    >{{ $t("message.ga_terug_naar_dashboard") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else-if="validated">
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header bgc-groen c-200 text-white">
              <span class="text-sm overline">{{
                $t("message.completed")
              }}</span>
            </div>
            <div class="card-body d-flex flex-column">
              <h4 class="text-sentence">{{ $t("message.bedankt") }}</h4>
              <!--              <div class="chart mb-3">-->
              <!--                CHART-->
              <!--              </div>-->
              <div class="row privatemode mt-3 mb-5">
                <div class="col-auto">
                  <span
                    v-show="!isUpdatingPrivacy"
                    class="status cursor-pointer"
                    @click="updatePrivacy()"
                  >
                    <span v-show="isLocked"
                      ><img src="../assets/icons/Icon-survey-locked.svg" alt=""
                    /></span>
                    <span v-show="!isLocked"
                      ><img
                        src="../assets/icons/Icon-survey-un-locked.svg"
                        alt=""
                    /></span>
                  </span>
                  <div
                    style="height: 12px; width: 12px"
                    v-show="isUpdatingPrivacy"
                    class="spinner-border"
                    role="status"
                  >
                    <span class="visually-hidden"
                      >{{ $t("message.loading") }}...</span
                    >
                  </div>
                </div>
                <div class="col">
                  <span v-if="isLocked" class="text-sentence">
                    <strong>{{
                      $t("message.je_antwoorden_blijven_prive")
                    }}</strong
                    ><br />
                    <p class="text-sentence">
                      {{
                        $t(
                          "message.wil_je_ze_met_je_manager_delen_ontgrendel_dan_het_slotje"
                        )
                      }}
                    </p>
                  </span>
                  <span v-else class="text-sentence">
                    <strong>{{
                      $t("message.je_antwoorden_worden_gedeeld_met_je_manager")
                    }}</strong
                    ><br />
                    <p class="text-sentence">
                      {{
                        $t(
                          "message.wil_je_prive_houden_vergrendel_dan_het_slotje"
                        )
                      }}
                    </p>
                  </span>
                </div>
              </div>
              <div class="actions row flex-grow-1">
                <div class="col-12">
                  <p class="text-sentence">
                    {{ $t("message.feedback_na_afronden") }}
                  </p>
                </div>
                <div class="col-auto mx-auto d-flex align-items-end">
                  <button @click="finalizeSubmission" class="btn btn-secondary">
                    <span class="text-sentence">{{
                      $t("message.afronden")
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-6">
          <div class="card h-100">
            <div class="card-header">
              <span class="overline text-sm ">{{
                $t("message.einde")
              }}</span>
            </div>
            <div class="card-body d-flex flex-column">
              <h4 class="text-sentence">
                {{ $t("message.je_bent_er_bijna") }}
              </h4>
              <p class="text-sentence">
                {{ $t("message.bent_er_bijna_copy") }}
              </p>
              <ul class="checkup-details list-group list-group-flush">
                <li
                  class="
                    px-0
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-baseline
                  "
                >
                  <div class="me-auto">
                    <img
                      src="../assets/icons/icon-toggle-check-box.svg"
                      alt=""
                    />&nbsp;<span class="text-sentence">{{
                      $t("message.vragen_ingevuld")
                    }}</span>
                  </div>
                  <div>{{ answeredQuestions }}</div>
                </li>
                <li
                  class="
                    px-0
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-baseline
                  "
                >
                  <div class="me-auto">
                    <img
                      src="../assets/icons/icon-toggle-check-box_off.svg"
                      alt=""
                    />&nbsp;<span class="text-sentence">{{
                      $t("message.nog_in_te_vullen")
                    }}</span>
                  </div>
                  <div>{{ vitamins.questionCount - answeredQuestions }}</div>
                </li>
                <li
                  class="
                    px-0
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-baseline
                  "
                >
                  <div class="me-auto">
                    <img
                      src="../assets/icons/Icon-survey-Calendar.svg"
                      alt=""
                    />&nbsp;<span class="text-sentence">{{
                      $t("message.resterende_dagen")
                    }}</span>
                  </div>
                  <div>{{ vitamins.deadline.diff(moment(), "days") }}</div>
                </li>
                <li
                  class="
                    px-0
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-baseline
                  "
                >
                  <div class="me-auto">
                    <span class="status cursor-pointer" @click="updatePrivacy">
                      <span v-show="isLocked"
                        ><img
                          src="../assets/icons/Icon-survey-locked.svg"
                          alt=""
                        />&nbsp;<span class="text-sentence">{{
                          $t("message.je_antwoorden_zijn_prive")
                        }}</span>
                      </span>
                      <span v-show="!isLocked"
                        ><img
                          src="../assets/icons/Icon-survey-un-locked.svg"
                          alt=""
                        />&nbsp;<span class="text-sentence">{{
                          $t("message.je_antwoorden_zijn_zichtbaar")
                        }}</span>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
              <div class="actions row">
                <div class="col-auto mx-auto">
                  <button
                    class="btn btn-cta"
                    @click.prevent="toFirstEmptyQuestion"
                  >
                    <span class="text-sentence">{{
                      $t("message.afwerken")
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden ">{{ $t("message.loading") }}...</span>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from "@/views/components/simple/Checkbox";
import moment from "moment";
import _ from 'lodash';

export default {
  components: Checkbox,
  data() {
    return {
      showNotes: false,
      vitamins: null,
      submission: null,
      isLocked: true,
      activeQuestionIndex: 0,
      questionValues: {},
      activeVitaminIndex: 0,
      activeFactorIndex: 0,
      finishing: false,
      validated: false,
      retrieved: false,
      done: false,
      moment: moment,
      notes: {},
      currentNoteStatus: {},
      feedback: "",
      loadingFeedback: false,
      feedbackSent: false,
      isUpdatingPrivacy: false,
      onlyEmptyQuestions: false,
      questionLoader: false,
    };
  },
  methods: {
    handleAnswerQuestion() {
      if (window.outerWidth < 768) {
        this.questionLoader = true;
        this.submitCheckup();
      }
    },
    async finalizeSubmission() {
      this.submission.isfinished = true;
      await this.updateSubmission();
      this.done = true;
    },
    async sendFeedback() {
      this.loadingFeedback = true;
      await this.axios.put("/checkupsubmissions/" + this.submission.id, {
        feedback: this.feedback,
      });
      this.loadingFeedback = false;
      this.feedbackSent = true;
    },
    async updatePrivacy() {
      this.isUpdatingPrivacy = true;
      const status = this.submission.private;
      let sharedQuestions = [];
      if (!status == false) {
        sharedQuestions = [...this.submission.submission.questions];
      }
      let updateRequest = await this.axios.put(
        "/checkupsubmissions/" + this.submission.id,
        {
          private: !status,
          sharedsubmission: {
            questions: sharedQuestions,
            notes: this.submission.submission.notes.filter(
              (note) => note.isShared == true
            ),
          },
        }
      );
      this.submission = updateRequest.data;
      this.isLocked = this.submission.private;
      this.isUpdatingPrivacy = false;
    },
    async getVitamins(id) {
      let request = await this.axios.get("/checkups/" + id + "/vitamins");
      this.vitamins = request.data;
      if (!this.vitamins) return this.$router.push("/vitamins");
      this.vitamins.deadline = moment(this.vitamins.deadline);
      if (this.vitamins.deadline.isBefore(moment())) {
        return this.$router.push("/vitamins");
      }
      let submissionRequest = await this.axios.get(
        "/checkupsubmissions?checkupentry=" + id
      );
      if (submissionRequest.data.length > 0) {
        this.submission = submissionRequest.data[0];

        // Go back to vitamins page when checkup is finished
        if (this.submission.isfinished) {
          return this.$router.push("/vitamins");
        }

        this.feedback = this.submission.feedback;
        this.validated =
          this.submission.submission.answeredCount ==
          this.vitamins.questionCount;
        this.finishing = this.submission.submission.isFinished;
        if (!this.validated) {
          this.toFirstEmptyQuestion();
        }
      } else {
        // If there is no submission, create a new one
        let submissionPost = await this.axios.post("/checkupsubmissions", {
          checkupentry: id,
          submission: {
            questions: [],
            notes: [],
            answeredCount: 0,
            isFinished: false,
          },
          sharedsubmission: {
            questions: [],
            notes: [],
          },
        });
        this.submission = submissionPost.data;
      }
      // this.questionValues = this.submission.submission.questions.map(question => question.value);
      if (this.submission) {
        // console.log(this.submission.private);
        this.isLocked = this.submission.private;
        for (const question of this.submission.submission.questions) {
          let [vitamin, factor, questionIndex] = question.index.split("#");
          let index = parseInt(
            vitamin.toString() + factor.toString() + questionIndex.toString()
          );
          this.questionValues[index] = question.value;
        }
        // this.notes = this.submission.submission.notes.map(note => note.content);
        for (const note of this.submission.submission.notes) {
          this.notes[note.index] = note.content;
          this.currentNoteStatus[note.index] = note.isShared;
        }
      }
      // this.currentNoteStatus = this.submission.submission.notes.map(note => note.isShared);
      this.retrieved = true;
    },
    findFirstEmptyIndex() {
      const {vitaminIndex, factorIndex, questionIndex} = _.differenceBy(this.flatQuestions, this.submission.submission.questions.filter(question => question.value !== 'none'), 'questionkey')[0];
      return {
        vitaminIndex,
        factorIndex,
        questionIndex
      }
    },
    toFirstEmptyQuestion() {
      let { vitaminIndex, factorIndex, questionIndex } =
        this.findFirstEmptyIndex();
      this.activeQuestionIndex = parseInt(questionIndex);
      this.activeVitaminIndex = parseInt(vitaminIndex);
      this.activeFactorIndex = parseInt(factorIndex);
      this.onlyEmptyQuestions = true;
      this.finishing = false;
    },
    async submitCheckup() {
      // console.log(this.answeredQuestions);
      if (
        this.answeredQuestions + 1 == this.vitamins.questionCount ||
        this.answeredQuestions == this.vitamins.questionCount
      ) {
        this.submission.submission.isFinished = true;
      }
      await this.updateSubmission();
      // console.log(this.answeredQuestions, this.vitamins.questionCount, this.activeQuestion.id);
      if (this.answeredQuestions == this.vitamins.questionCount) {
        this.validated = true;
        this.finishing = true;
      } else if (this.activeQuestion.key == this.finalQuestionKey) {
        this.finishing = true;
      } else {
        if (this.onlyEmptyQuestions) {
          await this.toFirstEmptyQuestion();
        } else {
          await this.pageForward();
        }
        if (this.submission.isfinished) {
          return this.$router.push("/vitamins");
        }
      }
      this.questionLoader = false;
    },
    async updateSubmission() {
      // Find question in submission
      let questionData = {
        questionid: this.activeQuestion.id,
        questionkey: this.activeQuestion.key,
        value: this.questionValues[this.activeMainIndex] || "none",
        vitaminid: this.activeVitamin.id,
        scalefactor: this.activeFactor.scalegroup.id,
        factorid: this.activeFactor.id,
        index: `${this.activeVitaminIndex}#${this.activeFactorIndex}#${this.activeQuestionIndex}`,
      };

      let index = this.submission.submission.questions.findIndex(
        (question) =>
          question.questionid == this.activeQuestion.id &&
          question.factorid == this.activeFactor.id &&
          question.vitaminid == this.activeVitamin.id
      );
      if (index == -1) {
        this.submission.submission.questions.push(questionData);
        if (!this.submission.private) {
          this.submission.sharedsubmission.questions.push(questionData);
        }
      } else {
        this.submission.submission.questions.splice(index, 1, questionData);
        if (!this.submission.private) {
          this.submission.sharedsubmission.questions.splice(
            index,
            1,
            questionData
          );
        }
      }

      // Find notes for factor
      let currentNote = this.notes[this.activeNoteIndex];
      // Update notes or add notes
      if (currentNote) {
        let noteData = {
          factorid: this.activeFactor.id,
          content: this.notes[this.activeNoteIndex],
          isShared: this.currentNoteStatus[this.activeNoteIndex],
          index: this.activeNoteIndex,
        };
        let noteIndex = this.submission.submission.notes.findIndex(
          (note) => note.index == this.activeNoteIndex
        );
        if (noteIndex == -1) {
          this.submission.submission.notes.push(noteData);
          if (noteData.isShared) {
            this.submission.sharedsubmission.notes.push(noteData);
          }
        } else {
          this.submission.submission.notes.splice(noteIndex, 1, noteData);
          if (noteData.isShared) {
            this.submission.sharedsubmission.notes.splice(
              noteIndex,
              1,
              noteData
            );
          }
        }
      }
      // Update answered count
      this.submission.submission.answeredCount = this.answeredQuestions;
      let updateRequest = await this.axios.put(
        "/checkupsubmissions/" + this.submission.id,
        this.submission
      );
      this.submission = updateRequest.data;
    },
    async pageForward() {
      // console.log('indices fwd',
      //     this.activeQuestionIndex,
      //     this.activeFactorIndex,
      //     this.activeVitaminIndex
      // )
      // Update submission on page forward
      // await this.updateSubmission();
      if (this.activeQuestionIndex < this.activeFactor.questions.length - 1) {
        //next question
        this.activeQuestionIndex++;
      } else if (
        this.activeFactorIndex <
        this.activeVitamin.factors.length - 1
      ) {
        //next factor
        //if on last page of factor/vitamin, open notes instead - if notes are already open, proceed
        if (this.showNotes || window.outerWidth > 767) {
          this.activeQuestionIndex = 0;
          this.activeFactorIndex++;
          this.showNotes = false;
        } else {
          this.showNotes = true;
        }
      } else if (this.activeVitaminIndex < this.vitamins.vitamins.length - 1) {
        //next vitamin
        if (this.showNotes || window.outerWidth > 767) {
          this.activeQuestionIndex = 0;
          this.activeFactorIndex = 0;
          this.activeVitaminIndex++;
          this.showNotes = false;
        } else {
          this.showNotes = true;
        }
      }
    },
    pageBack() {
      if (this.activeQuestionIndex > 0) {
        //to previous question
        if (this.showNotes && window.outerWidth <= 767) {
          this.showNotes = false;
        } else {
          this.activeQuestionIndex--;
        }
      } else if (this.activeFactorIndex > 0) {
        //to previous factor
        if (!this.showNotes || window.outerWidth > 767) {
          this.activeFactorIndex--;
          this.activeQuestionIndex = this.activeFactor.questions.length - 1;
          this.showNotes = true;
        }
      } else if (this.activeVitaminIndex > 0) {
        //to previous vitamin
        if (!this.showNotes || window.outerWidth > 767) {
          this.activeVitaminIndex--;
          this.activeFactorIndex = this.activeVitamin.factors.length - 1;
          this.activeQuestionIndex = this.activeFactor.questions.length - 1;
          this.showNotes = true;
        }
      }
    },
  },
  computed: {
    completedPct() {
      return (this.answeredQuestions / this.vitamins.questionCount) * 100;
    },
    answeredQuestions() {
      if (this.retrieved) {
        return this.submission.submission.questions.filter(
          (question) => question.value != "none"
        ).length;
      } else {
        return 0;
      }
    },
    allQuestionLength() {
      if (this.retrieved) {
        let l = 0;
        for (const vitamin of this.vitamins.vitamins) {
          vitamin.factors.forEach((factor) => {
            l += factor.questions.length;
          });
        }

        return l;
      } else {
        return 0;
      }
    },
    finalQuestionKey() {
      if (this.vitamins.vitamins) {
        const vitamin = this.vitamins.vitamins[this.vitamins.vitamins.length - 1];
        const factor = vitamin.factors[vitamin.factors.length -1];
        return factor.questions[factor.questions.length - 1].key;
      }
      return null;
    },
    vitaminQuestionLength() {
      if (this.retrieved) {
        let l = 0;
        this.activeVitamin.factors.forEach((factor) => {
          l += factor.questions.length;
        });
        return l;
      } else {
        return 0;
      }
    },
    activeNoteIndex() {
      return parseInt(
        this.activeVitaminIndex.toString() + '#' + this.activeFactorIndex.toString()
      );
    },
    activeMainIndex() {
      // Make an integer value for Vitamin/Factor/Question
      return parseInt(
        this.activeVitaminIndex.toString() +
          this.activeFactorIndex.toString() +
          this.activeQuestionIndex.toString()
      );
    },
    activeQuestion() {
      if (this.retrieved) {
        return this.vitamins.vitamins[this.activeVitaminIndex].factors[
          this.activeFactorIndex
        ].questions[this.activeQuestionIndex];
      } else {
        return 0;
      }
    },
    activeFactor() {
      if (this.retrieved) {
        return this.vitamins.vitamins[this.activeVitaminIndex].factors[
          this.activeFactorIndex
        ];
      } else {
        return 0;
      }
    },
    activeVitamin() {
      if (this.retrieved) {
        return this.vitamins.vitamins[this.activeVitaminIndex];
      } else {
        return 0;
      }
    },
    flatQuestions() {
      const questions = [];
      for (const [i, vitamin] of this.vitamins.vitamins.entries()) {
        for (const [j, factor] of vitamin.factors.entries()) {
          for (const [k, question] of factor.questions.entries()) {
            questions.push({
              questionkey: question.key,
              questionIndex: k,
              vitaminIndex: i,
              factorIndex: j,
            })
          }
        }
      }
      return questions;
    }
  },
  // props: ['submission', 'checkup'],
  emits: ["loadcomplete"],
  async created() {
    let id = this.$route.params.id;
    await this.getVitamins(id);
    this.mitt.emit("loadcomplete", { complete: true });
    //if this.checkup is null, set fetch it with id from router
  },
  mounted() {
    // console.log('checkup',
    //     this.checkup
    // )
  },
};
</script>

<style lang="scss" scoped>
.styled-checkbox {
  width: 0;
  height: 0;
  position: absolute;
}

@media all and (max-width: 767px) {
  .checkup-question-options {
    .list-group-item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
</style>