export default {
    setUser(state, payload) {
        if (payload.userId) {
            state.userId = payload.userId;
        }

        state.user = payload.user;
    },
    async setToken(state, token) {
        if (token == null) {
            state.token = null;
            state.axios.defaults.headers = {}
        } else {
            state.token = token;
            state.axios.defaults.headers = {
                Authorization:
                    'Bearer ' + token,
            };
        }
    },
    // setAuth(state, status) {
    //     console.log('setting auth',status)
    //     state.auth = status
    // },
    setAxios(state, axios) {
        state.axios = axios;
    }
};