<template>
  <div class="action-list">
    <div class="action-filters row">
      <div class="col-md-auto col-12 mb-md-4 mb-2">
        <search @search="handleSearch"></search>
      </div>
      <div class="col-md-auto col-12 mb-md-4 mb-2">
        <div class="w-100 btn-group" role="group" aria-label="Basic example">
          <button
            @click="getActions('all')"
            :class="status === 'all' ? 'active' : ''"
            type="button"
            class="btn btn-sm btn-secondary"
          >
            <span>{{ $t("message.all") }}</span>
          </button>
          <button
            @click="getActions('active')"
            :class="status === 'active' ? 'active' : ''"
            type="button"
            class="btn btn-sm btn-secondary"
          >
            <span>{{ $t("message.active") }}</span>
          </button>
          <button
            @click="getActions('completed')"
            :class="status === 'completed' ? 'active' : ''"
            type="button"
            class="btn btn-sm btn-secondary"
          >
            <span>{{ $t("message.completed") }}</span>
          </button>
        </div>
      </div>
      <div class="ms-auto col-auto mb-md-4 mb-2">
        <button @click="openActionModal(null)" class="btn btn-primary btn-sm">
          <img
            class="hide-dark"
            src="../../assets/icons/icon-navigation-add-cropped.svg"
            alt=""
          />
          <img
            class="hide-light"
            src="../../assets/icons/icon-navigation-add-cropped-light.svg"
            alt=""
          />
          &nbsp;<span>{{$t("message.action") }}</span>
        </button>
        <button
          v-if="type != 'personal'"
          @click="openMessageModal(null)"
          class="btn btn-secondary btn-sm ms-2"
        >
          <img
            class="hide-dark"
            src="../../assets/icons/icon-navigation-add-cropped.svg"
            alt=""
          />
          <img
            class="hide-light"
            src="../../assets/icons/icon-navigation-add-cropped-light.svg"
            alt=""
          />
          &nbsp;<span>{{ $t("message.message") }}</span>
        </button>
      </div>
    </div>
    <div class="actions-items">
      <paginated
        style="min-height: 150px"
        @change="handlePagination"
        :request="request"
        :total-rows="totalRows"
        :per-page="10"
      >
        <!--        <div v-if="actions.length < 1">-->
        <!--          <p class="text-center text-sentence d-block">{{$t('message.no_actions_available')}}</p>-->
        <!--        </div>-->
        <div
          class="rounded-8 action-item bgc-theme c-200 p-3 mb-2"
          v-for="action in actions"
          v-bind:key="action"
        >
          <div class="row">
            <div class="col-auto flex-shrink-0">
              <checkbox
                @change="updateAction($event, action.id)"
                v-model="action.completed"
              ></checkbox>
            </div>
            <div class="col">
              {{ action.body }}
            </div>
            <div class="col-auto tip tip-shared" v-if="action.visible">
              <span class="text-sm tip-content text-sentence">
                {{ $t("message.action_shared_with_team") }}
              </span>
              <i class="fas fa-user-friends"></i>
            </div>
            <div class="col-auto flex-shrink-0">
              <ul
                class="nav nav-pills p-0"
                v-if="
                  action.authorid == user.id ||
                  (user.manageteams && action.teamid) ||
                  user.managecompany
                "
              >
                <li class="nav-item dropdown p-0">
                  <a
                    class="nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../../assets/icons/Icon-navigation-More-vertical.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        @click.prevent="openActionModal(action.id)"
                        class="dropdown-item text-sentence"
                        href="#"
                        >{{ $t("message.edit") }}</a
                      >
                    </li>
                    <li>
                      <a
                        @click.prevent="handleDeleteAction(action)"
                        class="dropdown-item text-sentence"
                        href="#"
                        >{{ $t("message.delete") }}</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
              <div v-else style="width: 24px"></div>
            </div>
          </div>
        </div>
      </paginated>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/views/components/simple/Checkbox";
import Search from "@/views/components/simple/Search";
import ActionModal from "@/views/components/modals/ActionModal";
import Paginated from "@/views/components/simple/Paginated";
import NewMessageModal from "@/views/components/modals/NewMessageModal";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";

export default {
  components: { Paginated, Search, Checkbox },
  props: ["type"],
  created() {
    this.getActions("active");
  },
  watch: {
    type() {
      this.getActions();
    },
  },
  methods: {
    handleSearch(val) {
      this.search = val;
      this.getActions();
    },
    handlePagination(data) {
      this.actions = data;
    },
    async handleDeleteAction(action) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        options: { centered: true },
        properties: {
          title: action.body,
          id: action.id,
        },
        events: {
          submitModal: this.deleteAction,
        },
      });
    },
    async deleteAction(id) {
      await this.axios.delete("/actions/" + id);
      await this.getActions(this.status);
    },
    async updateAction(e, id) {
      await this.axios.put("/actions/" + id, {
        completed: e.target.checked,
      });
      await this.getActions(this.status);
    },
    async getActions(status = this.status) {
      this.totalRows = 0;
      this.status = status;
      let filter = "";
      let action_type = "";
      if (this.type !== "personal") {
        action_type = "/" + this.type;
      }
      if (this.status !== "all") {
        switch (this.status) {
          case "active":
            filter = "?completed_ne=true";
            break;
          case "completed":
            filter = "?completed=true";
            break;
        }
      }
      let search = "";
      let s = "?";
      if (filter) {
        s = "&";
      }
      if (this.search.length > 0) {
        search = s + "body_contains=" + this.search;
      }
      this.request = "/actions" + action_type + filter + search;
      const count = await this.axios.get(
        "/actions" + action_type + "/count" + filter + search
      );
      this.totalRows = count.data;
      this.$emit("actionsRefresh");
    },
    async handleModalSubmit() {
      // console.log('handling modal submit')
      await this.getActions(this.status);
    },
    openActionModal(id = null) {
      this.$store.dispatch("openModal", {
        component: ActionModal,
        properties: {
          type: this.type,
          id: id,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.handleModalSubmit,
        },
      });
    },
    openMessageModal(id = null) {
      this.$store.dispatch("openModal", {
        component: NewMessageModal,
        properties: {
          type: this.type,
          id,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.handleModalSubmit,
        },
      });
    },
  },

  data() {
    return {
      search: "",
      totalRows: 0,
      request: "",
      actions: [],
      status: "active",
    };
  },
};
</script>