<template>
  <div
    class="card h-100 checkup-submission-card"
    :class="isClosed ? '' : 'closed'"
  >
    <div
      class="card-header pe-1"
      :class="'bgc-' + checkup.color + (isClosed ? ' c-50' : '')"
    >
      <div class="text-sm title flex-grow-1 overline">
        <span>{{ $t("message.checkupEnds") }} {{ checkup.deadline.format("DD/MM/YYYY") }}</span>
      </div>
    </div>
    <div class="card-body">
      <h5 class="checkup-title" :class="isClosed ? 'text-disabled' : ''">
        {{ checkup.title }}
      </h5>
      <ul class="checkup-details list-group list-group-flush">
        <li
          v-if="sub && completedPct == 100"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
          :class="isClosed ? 'text-disabled' : ''"
        >
          <div class="me-auto">
            <div
              @click="updatePrivacy"
              v-if="sub.private"
              :style="sub ? 'cursor:pointer;' : ''"
            >
              <img
                v-if="isClosed"
                src="../../assets/icons/Icon-survey-locked-disabled.svg"
                alt=""
              />
              <img
                v-else
                src="../../assets/icons/Icon-survey-locked.svg"
                alt=""
              />
              <span class="text-sentence">{{ $t("message.prive") }}</span>
            </div>
            <div
              @click="updatePrivacy"
              v-else
              :style="sub ? 'cursor:pointer;' : ''"
            >
              <img
                v-if="isClosed"
                src="../../assets/icons/Icon-survey-un-locked-disabled.svg"
                alt=""
              />
              <img
                v-else
                src="../../assets/icons/Icon-survey-un-locked.svg"
                alt=""
              />
              <span class="text-sentence">{{ $t("message.shared") }}</span>
            </div>
          </div>
        </li>
        <li class="list-group-item border-bottom-0" v-else>
          <span v-if="isClosed" class="text-disabled text-sentence"
            >{{ $t("message.deadline_voorbij_zonder_inzending") }}</span
          >
          <span v-else class="text-sentence">{{ $t("message.nog_geen_inzendingen_gedetecteerd") }}</span>
        </li>
        <li
          v-if="sub && completedPct == 100"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
          :class="isClosed ? 'text-disabled' : ''"
        >
          <div class="me-auto">
            <img
              v-if="isClosed"
              src="../../assets/icons/Icon-survey-Calendar-disabled.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/icons/Icon-survey-Calendar.svg"
              alt=""
            />
            <span class="text-sentence">{{ $t("message.ingevuld_op") }}</span>
          </div>
          <div>{{ moment(sub.created_at).format("DD/MM/YYYY") }}</div>
        </li>
        <li
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
          :class="isClosed ? 'text-disabled' : ''"
        >
          <div class="me-auto">
            <img
              v-if="isClosed"
              src="../../assets/icons/Icon-survey-Calendar-disabled.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/icons/Icon-survey-Calendar.svg"
              alt=""
            />
            <!-- &nbsp; -->
            <span v-if="!isClosed" class="text-sentence"
              >{{ $t("message.resterende_dagen") }}</span
            >&nbsp;
            <span v-else class="text-sentence">{{ $t("message.afgesloten") }}</span>
          </div>
          <div v-if="!isClosed">
            {{
              checkup.deadline.diff(moment(), "days") > 0
                ? checkup.deadline.diff(moment(), "days")
                : 0
            }}
          </div>
        </li>
      </ul>
      <div class="d-flex justify-content-between align-items-center mt-2">
        <router-link
          v-if="!isClosed && (!sub || !sub.isfinished)"
          class="btn"
          :class="
            (isClosed ? 'btn-secondary' : 'btn-primary') +
            'btn-cta-' +
            checkup.color
          "
          tag="button"
          :to="{
            name: 'checkup',
            params: {
              id: checkup.id,
              checkup: JSON.stringify(checkup),
              submission: JSON.stringify(sub),
            },
          }"
        >
          <span v-if="!isClosed && sub && !sub.isfinished">{{
            $t("message.afwerken")
          }}</span>
          <span v-else-if="!isClosed && !sub">{{ $t("message.start") }}</span>
        </router-link>
        <button
          v-else-if="sub && sub.isfinished"
          @click="openSubmissionModal"
          class="btn"
          :class="
            (isClosed ? 'btn-secondary' : 'btn-primary') +
            'btn-cta-' +
            checkup.color
          "
        >
          <span>{{ $t("message.bekijken") }}</span>
        </button>
        <button v-else class="btn btn-link disabled">
          <i class="far fa-frown"></i>
        </button>
        <div class="checkup-progress">
          <progress-chart
            class="h-100 w-100"
            :pct="completedPct"
            :border-width="4"
            :f-size="'sm'"
            :color="checkup.color"
          >
          </progress-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressChart from "@/views/components/ProgressChart";
import moment from "moment";
import SubmissionModal from "@/views/components/modals/SubmissionModal";

export default {
  components: {
    ProgressChart,
  },
  methods: {
    openSubmissionModal() {
      this.$store.dispatch("openModal", {
        component: SubmissionModal,
        options: {
          size: "lg",
        },
        properties: {
          // checkupdetail: this.checkup,
          submissionid: this.sub.id,
        },
      });
    },
    async updatePrivacy() {
      //console.log('updatePrivacy');
      if (this.sub) {
        this.sub.private = !this.sub.private;

        if (this.sub.private) {
          this.sub.sharedsubmission.questions = [];
        } else {
          const { questions } = this.sub.submission;
          this.sub.sharedsubmission = {
            ...this.sub.sharedsubmission,
            questions,
          };
        }

        const { data: submission } = await this.axios.put(
          "/checkupsubmissions/" + this.sub.id,
          this.sub
        );
        this.sub = submission;
      }
    },
  },
  computed: {
    submitted() {
      return this.completedPct == 100;
    },
    isClosed() {
      return (
          moment(this.checkup.grace).isBefore(moment())
      );
    },
    completedPct() {
      if (this.sub && this.checkup.questionCount > 0) {
        return (
          Math.min((this.sub.submission.answeredCount / this.checkup.questionCount) * 100, 100)
        );
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      moment: moment,
      sub: this.submission,
    };
  },
  props: ["submission", "checkup"],
};
</script>

<style lang="scss" scoped>
.checkup-progress {
  height: 64px;
  width: 64px;
}
</style>