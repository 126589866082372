<template>
  <div class="card h-100">
    <div
      class="card-header pe-1"
      :class="'bgc-' + checkup.color + (isClosed ? ' c-50' : '')"
    >
      <div class="text-sm title flex-grow-1 overline">
        <span>{{ $t("message.checkupEnds") }} {{ checkup.deadline.format("DD/MM/YYYY") }}</span>
      </div>
      <!--      <div class="nav">-->
      <!--        <ul class="nav nav-pills p-0">-->
      <!--          <li class="nav-item dropdown p-0">-->
      <!--            <a class="nav-link p-0" data-bs-toggle="dropdown" href="#" role="button"-->
      <!--               aria-expanded="false">-->
      <!--              <img src="../../assets/icons/Icon-navigation-More-vertical.svg" alt="">-->
      <!--            </a>-->
      <!--            <ul class="dropdown-menu">-->
      <!--              <li><a class="dropdown-item" href="#">Action</a></li>-->
      <!--              <li><a class="dropdown-item" href="#">Another action</a></li>-->
      <!--              <li><a class="dropdown-item" href="#">Something else here</a></li>-->
      <!--              <li>-->
      <!--                <hr class="dropdown-divider">-->
      <!--              </li>-->
      <!--              <li><a class="dropdown-item" href="#">Separated link</a></li>-->
      <!--            </ul>-->
      <!--          </li>-->
      <!--        </ul>-->

      <!--      </div>-->
    </div>
    <div class="card-body">
      <h3 class="text-center" :class="isClosed && 'text-disabled'">
        {{ checkup.title }}
      </h3>
      <div class="mx-auto checkup-progress mb-4">
        <progress-chart
          class="h-100 w-100"
          :pct="completedPct"
          :border-width="6"
          :color="checkup.color"
          :f-size="'lg'"
        >
        </progress-chart>
      </div>
      <p class="checkup-description" :class="isClosed && 'text-disabled'">
        {{ checkup.description }}
      </p>
      <ul class="checkup-details list-group list-group-flush">
        <li
          v-if="!isClosed"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto" :class="isClosed && 'text-disabled'">
            <img src="../../assets/icons/Icon-survey-time.svg" alt="" />&nbsp;<span class="text-sentence">{{$t("message.geschatte_duur")}}</span>
          </div>
          <div>{{ checkup.duration }}'</div>
        </li>
        <li
          v-if="sub && !isClosed && !submitted"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/icon-toggle-check-box.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.vragen_ingevuld") }}</span>
          </div>
          <div>{{ sub.submission.answeredCount }}</div>
        </li>
        <li
          v-if="sub && !isClosed && !submitted"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/icon-toggle-check-box_off.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.nog_in_te_vullen") }}</span>
          </div>
          <div>{{ checkup.questionCount - sub.submission.answeredCount }}</div>
        </li>
        <li
          v-if="sub && submitted"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/icon-toggle-check-box.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.volledig_ingevuld") }}</span>
          </div>
        </li>
        <li
          v-else-if="!sub"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto" :class="isClosed && 'text-disabled'">
            <img src="../../assets/icons/Icon-survey-List.svg" alt="" />&nbsp;
            <span class="text-sentence">{{$t("message.amt_vragen")}}</span>
          </div>
          <div>{{ checkup.questionCount }}</div>
        </li>
        <li
          v-if="sub && submitted"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
          :class="isClosed ? 'text-disabled' : ''"
        >
          <div class="me-auto">
            <img
              v-if="isClosed"
              src="../../assets/icons/Icon-survey-Calendar-disabled.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/icons/Icon-survey-Calendar.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t('message.ingevuld_op') }}</span>
          </div>
          <div>{{ moment(sub.created_at).format("DD/MM/YYYY") }}</div>
        </li>
        <li
          v-if="!isClosed"
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto">
            <img
              src="../../assets/icons/Icon-survey-Calendar.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.resterende_dagen") }}</span>
          </div>
          <div>
            {{
              checkup.deadline.diff(moment(), "days") > 0
                ? checkup.deadline.diff(moment(), "days")
                : 0
            }}
          </div>
        </li>
        <li
          v-else
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div class="me-auto text-disabled">
            <img
              src="../../assets/icons/Icon-survey-Calendar-disabled.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.afgesloten") }}</span>
          </div>
        </li>
        <li
          class="
            px-0
            list-group-item
            d-flex
            justify-content-between
            align-items-baseline
          "
        >
          <div
            v-if="(sub && sub.private) || !sub"
            class="me-auto"
            :class="isClosed && 'text-disabled'"
          >
            <img
              @click="updatePrivacy"
              :style="sub ? 'cursor:pointer;' : ''"
              src="../../assets/icons/Icon-survey-locked.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.je_antwoorden_zijn_prive") }}</span>
          </div>
          <div v-else class="me-auto" :class="isClosed && 'text-disabled'">
            <img
              @click="updatePrivacy"
              :style="sub ? 'cursor:pointer;' : ''"
              src="../../assets/icons/Icon-survey-un-locked.svg"
              alt=""
            />&nbsp;<span class="text-sentence">{{ $t("message.je_antwoorden_zijn_zichtbaar") }}</span>
          </div>
        </li>
      </ul>
      <div class="text-center">
        <router-link
          v-if="!isClosed && (!sub || !sub.isfinished)"
          class="btn btn-cta btn-lg"
          :class="'btn-cta-' + checkup.color"
          tag="button"
          :to="{
            name: 'checkup',
            params: {
              // checkup: checkup.entry ?? '',
              // submission:JSON.stringify(submission),
              id: checkup.id,
            },
          }"
        >
          <span>{{
            isClosed ? "Bekijken" : sub ? $t("message.afwerken") : "Start"
          }}</span>
        </router-link>
        <button
          @click="openSubmissionModal"
          v-else-if="sub && sub.isfinished"
          class="btn btn-cta btn-lg"
          :class="'btn-cta-' + checkup.color"
        >
          <span>{{ $t("message.bekijken") }}</span>
        </button>
        <button
            v-else
          class="btn btn-link disabled btn-cta btn-lg"
          :class="'btn-cta-' + checkup.color"
        >
          <i class="far fa-frown"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkup-progress {
  width: 114px;
  height: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import ProgressChart from "@/views/components/ProgressChart";
import moment from "moment";
import SubmissionModal from "@/views/components/modals/SubmissionModal";

export default {
  components: {
    ProgressChart,
  },
  computed: {
    submitted() {
      return this.completedPct == 100;
    },
    isClosed() {
      // return false;
      return (
        moment(this.checkup.grace).isBefore(moment())
      );
    },
    completedPct() {
      if (this.sub) {
        return (
            Math.min((this.sub.submission.answeredCount / this.checkup.questionCount) * 100, 100)
        );
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      moment: moment,
      sub: this.submission,
    };
  },
  methods: {
    openSubmissionModal() {
      this.$store.dispatch("openModal", {
        component: SubmissionModal,
        options: {
          size: "lg",
        },
        properties: {
          // checkupdetail: this.checkup,
          submissionid: this.sub.id,
        },
      });
    },
    async updatePrivacy() {
      //console.log('updatePrivacy');
      if (this.sub) {
        this.sub.private = !this.sub.private;

        if (this.sub.private) {
          this.sub.sharedsubmission.questions = [];
        } else {
          const { questions } = this.sub.submission;
          this.sub.sharedsubmission = {
            ...this.sub.sharedsubmission,
            questions,
          };
        }

        const { data: submission } = await this.axios.put(
          "/checkupsubmissions/" + this.sub.id,
          this.sub
        );
        this.sub = submission;
      }
    },
  },
  props: ["submission", "checkup"],
};
</script>