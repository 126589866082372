<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div class="modal-header c-50" :class="'bgc-'+color">
      <div :class="'c-'+color" class=" c-200 text-sm modal-title w-100 flex-grow-1">
        <span v-if="id" class="text-sentence">{{ $t('message.edit_new_message', { type: typeName }) }}</span>
        <span v-else class="text-sentence">{{ $t('message.add_new_message', { type: typeName }) }}</span>
      </div>
      <button type="button" class="d-block ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <message-form :id="id" :type="type" @submit-message-form="handleSubmit" />
    </div>
  </div>
</template>
<script>
import MessageForm from "@/views/components/MessageForm";

export default {
  components: {MessageForm},
  emits:['submitModal'],
  methods: {
    handleSubmit(message) {
      this.$emit('submitModal',message);
      this.$store.dispatch('closeModal')
    }
  },
  computed: {

    typeName() {
      return this.$t('message.' + this.type);
    },
    color() {
      switch (this.type) {
        case 'team':
          return 'blauw';
        case 'company':
          return 'okergeel';
        default:
          return 'paars'
      }
    }
  },
  props: [
    'type', 'id'
  ],

}
</script>