<template>
  <div class="mb-3 checkup-top row justify-content-between align-items-baseline">
    <h2 class="col-md-6 text-sentence">{{ $t("message.vitamins") }} <em>{{ $t("message.for") }}</em> <span v-if="user">{{ user.firstname }}</span></h2>
    <div class="col-md-auto">
      <input class="w-100" type="text" id="vitaminsrange">
    </div>
  </div>
  <div v-if="checkups.length > 0" class="page-vitamins">
    <div class="row checkup-row">
      <div class="latest-checkup col-md-4 mb-gutter" v-if="activeCheckupPageIndex === 0 && checkups.length > 0">
        <checkup-card-large :submission="checkupsOnPage[0].submission"
                            :checkup="checkupsOnPage[0]"></checkup-card-large>
      </div>
      <div class="past-vitamins " :class="activeCheckupPageIndex === 0 ? 'col-md-8': 'col-md-12'">
        <div class="row" v-if="activeCheckupPageIndex === 0 && checkups.length > 0">
          <template v-bind:key="checkup" v-for="(checkup) in checkupsOnPage.slice(1)">
            <div class="col-md-6 mb-gutter">
              <checkup-card :submission="checkup.submission" :checkup="checkup"></checkup-card>
            </div>
          </template>
        </div>
        <div class="row" v-else>
          <template v-bind:key="checkup" v-for="(checkup) in checkupsOnPage">
            <div class="col-md-4 mb-gutter">
              <checkup-card :submission="checkup.submission" :checkup="checkup"></checkup-card>
            </div>
          </template>
        </div>
      </div>
    </div>
    <nav aria-label="Page navigation example" class="mt-3">
      <ul class="pagination justify-content-center">
        <li v-if="activeCheckupPageIndex" class="page-item" @click.prevent.stop="previousPage()">
          <a class="btn  mx-1" href="#" aria-label="Previous">
            <span aria-hidden="true">
              <img class="d-block img-fluid" src="../assets/icons/previous.svg" alt="">
            </span>
          </a>
        </li>

        <li v-if="(activeCheckupPageIndex+1) < (checkupPages)" class="page-item"
            @click.prevent.stop="nextPage()">
          <a class="btn  mx-1" href="#" aria-label="Next">
            <span aria-hidden="true">
              <img class="d-block img-fluid" src="../assets/icons/next.svg" alt="">
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div v-else-if="isLoading" class="position-absolute top-50 start-50 translate-middle container-spinner">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ $t("message.loading") }}...</span>
    </div>
  </div>
  <div v-else class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <img src="../assets/icons/Icon-tree-user-vitamins-1.svg" alt="">
    <p class="text-sentence">{{ $t("message.thereAreNoVitaminsAvailable") }}</p>
  </div>

  <pre>

</pre>
</template>
<script>
import CheckupCard from "@/views/components/CheckupCard";
import CheckupCardLarge from "@/views/components/CheckupCardLarge";
import moment from 'moment';
import daterangepicker from 'daterangepicker'
import jquery from 'jquery'

const $ = jquery

export default {
  components: {CheckupCardLarge, CheckupCard},
  emits: ['loadcomplete'],
  mounted() {
    this.getCheckups();
    this.initDaterange()
  },

  computed: {
    checkupsOnPage() {
      if (this.checkups.length < 1) {
        return [];
      }
      if (this.activeCheckupPageIndex > 1) {
        return this.checkups.slice((this.activeCheckupPageIndex * 6) - 1, (this.activeCheckupPageIndex * 6) + 5)
      } else if (this.activeCheckupPageIndex === 1) {
        return this.checkups.slice(5, 11);
      } else {
        return this.checkups.slice(0, 5);
      }
    },
    checkupPages() {
      if (this.checkups.length > 5) {
        return Math.ceil((this.checkups.length - 5) / 6) + 1
      } else {
        return 1
      }
    }
  },
  methods: {
    initDaterange() {
      let _this = this;
      setTimeout(function () {
        const el = document.getElementById('vitaminsrange')
        const drp = new daterangepicker(el, {
          "locale": {
            "format": "DD/MM/YYYY",
          }
        })
        $('#vitaminsrange').on('apply.daterangepicker', function () {
          _this.startDate = drp.startDate.format('YYYY-MM-DD')
          _this.endDate = drp.endDate.format('YYYY-MM-DD')
          _this.getCheckups()
        })

      }, 1000)
    },
    previousPage() {
      if (this.activeCheckupPageIndex > 0) {
        this.activeCheckupPageIndex--
      }

    },
    nextPage() {
      if (this.activeCheckupPageIndex < (this.checkupPages - 1)) {
        this.activeCheckupPageIndex++
      }

    },

    async getCheckups(/*filters = ''*/) {
      this.isLoading = true;
      this.checkups = []
      let r = '/checkups/own?start_lte=' + moment().startOf('day') + '&_sort=end:desc';
      if (this.startDate && this.endDate) {
        r = '/checkups/own?end_gte=' + this.startDate + '&end_lte=' + this.endDate + '&_sort=end:desc'
      }
      const request = await this.axios.get(r);
      for (const checkup of request.data) {
        checkup.deadline = moment(checkup.deadline);
        if (checkup.submission) {
          // checkup.submission.answeredCount = Math.floor(Math.random() * 66);
          // TODO: get better submission data from the response
          checkup.submission.date = moment(checkup.submission.updated_at);
        }
      }
      this.checkups = request.data;
      this.isLoading = false;
      this.mitt.emit('loadcomplete', {complete: true})
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      isLoading: true,
      activeCheckupPageIndex: 0,
      checkups: []
    }
  }
}
</script>