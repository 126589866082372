<template>
  <div id="page-wrapper" class="login-page-wrapper">
    <user-auth @forgot="forgot = true" v-show="!forgot"></user-auth>
    <user-forgot-password @forgot="forgot = false" v-show="forgot"></user-forgot-password>
  </div>
</template>

<script>


import UserAuth from "@/views/components/UserAuth";
import UserForgotPassword from "@/views/components/UserForgotPassword";
export default {
  mounted() {
    this.mitt.emit('loadcomplete', { complete: true })
  },
  components: {UserAuth,UserForgotPassword},
  data() {
    return {
      showPass: false,
      errors: [],
      email: '',
      password: '',
      forgot:false,
    }
  },
  methods: {

    async forgotPassword(e) {
      const el = e.target;
      el.classList.add('loading');
      this.errors = [];
      this.$store.dispatch('forgotPassword', {email: this.email})
          .then(() => {
            this.errors.push(this.$t('message.reset_mail_sent'))
            // console.log('re', r)
            el.classList.remove('loading');
          })
          .catch(e => {
            el.classList.remove('loading');
            const messages = e.response.data.message;
            messages.forEach((messages) => {
              messages.messages.forEach((message) => {
                this.errors.push(message.message)
              })
            });
          })
    },
    async onSubmit() {
      this.errors = [];
      const formData = {
        email: this.email,
        password: this.password,
      };

      if (this.validateLogin()) {
        await this.$store.dispatch('signin', {email: formData.email, password: formData.password, vm: this})
            .then(() => {
              // console.log(r)
            }).catch(e => {
              //  console.log('error', e.response)
              const messages = e.response.data.message;
              messages.forEach((messages) => {
                messages.messages.forEach((message) => {
                  // console.log('id', message.id)
                  // this.errors.push(message.message)
                  if (message.id === 'Auth.form.error.invalid') {
                    this.errors.push(this.$t('message.email_or_password_invalid'))
                  }
                  if (message.id === 'Auth.form.error.password.provide') {
                    this.errors.push(this.$t('message.please_provide_password'))
                  }
                })
              });
            })
      }
    }
  }
}
</script>

<style>
#company-logo {
  max-height: 50px;
  width: auto;
}
</style>
