<template>
  <transition name="slide">
    <div v-if="message" id="top-message" class="text-white py-2 px-5 shadow position-fixed w-100 start-0 top-0 d-flex justify-content-between align-items-center"
         :class="'bg-' + message.type" style="z-index: 9999">
      <span class="text-sentence">{{ $t(message.content) }}</span>
      <div>
        <button @click="removeMessage" class="btn btn-sm border-0 text-white"><i class="far fa-times"></i></button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['message'],
  watch: {
    message(val) {
      if (val) {
        setTimeout(() => {
          this.removeMessage();
        }, val.duration);
      }
    }
  },
  methods: {
    removeMessage() {
      this.$store.dispatch('removeMessage');
    }
  }
}
</script>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease !important;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-100%) !important;
}
</style>