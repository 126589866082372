<template>
  <div id="page-wrapper" class="login-page-wrapper">
    <div
      v-if="!received"
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("message.loading") }}...</span>
      </div>
    </div>
    <form
      v-else-if="codeExists"
      class="card login-form"
      action=""
      @submit.prevent="submitForm"
    >
      <div class="card-header bgc-groen c-50">
        <div class="overline text-sm">
          <span>{{ $t("message.reset_your_password") }}</span>
        </div>
      </div>
      <div class="card-body">
        <!--        <pre>-->
        <!--          {{code}}-->
        <!--        </pre>-->
        <!--      <p>-->
        <!--        <label for="username">E-mail</label>-->
        <!--        <input class="w-100" id="username" name="username" v-model="email" type="text">-->
        <!--      </p>-->
        <p>
          <label for="passwordnew" class="text-sentence">{{ $t("message.newPassword") }}</label>
          <input
            required
            class="w-100"
            id="passwordnew"
            name="passwordnew"
            v-model="passwordnew"
            type="password"
          />
        </p>
        <p>
          <label for="passwordconfirm" class="text-sentence d-block">{{
            $t("message.confirm_password")
          }}</label>
          <input
            required
            class="w-100"
            id="passwordconfirm"
            name="passwordconfirm"
            v-model="passwordconfirm"
            type="password"
          />
        </p>
        <p v-if="error" class="c-koraalrood c-200">
          {{ error }}
        </p>
        <p
          style="margin-top: 37px"
          class="mb-0 d-flex justify-content-end align-items-center"
        >
          <!--        <a type="button" @click="$emit('forgot')" class="text-sm">Forgot password?</a>-->
          <button v-if="!isLoading" class="btn btn-primary" type="submit">
            <span>{{ $t("message.submit") }}</span>
          </button>
          <button v-else class="btn disabled d-flex" type="button" disabled>
            <div
              style="height: 12px; width: 12px"
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden"
                >{{ $t("message.loading") }}...</span
              >
            </div>
            <span class="ms-2">{{ $t("message.resetting") }}</span>
          </button>
        </p>
      </div>
    </form>
    <div v-else class="card login-form">
      <div class="card-header bgc-koraalrood c-50">
        <div class="overline text-sm">
          <span>{{ $t("message.reset_your_password") }}</span>
        </div>
      </div>
      <div class="card-body">
        <p class="text-sentence">{{ $t("message.invalid_reset_code") }}</p>
        <div class="text-center">
          <router-link to="/login" class="btn btn-primary">
          <span>{{$t("message.goToLogin")}}</span></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // email: '',
      received: false,
      passwordnew: "",
      passwordconfirm: "",
      valid: true,
      isLoading: false,
      error: null,
      code: "",
      codeExists: null,
    };
  },
  async mounted() {
    this.code = this.$route.query.code;
    const { data: codeExists } = await this.axios.get(
      "/customuser/code?code=" + this.code
    );
    this.codeExists = codeExists;
    this.received = true;
    this.mitt.emit("loadcomplete", { complete: true });
    // console.log(this.$route)
  },
  methods: {
    async resetPassword() {
      const post = await this.axios.post("/auth/reset-password", {
        code: this.code, // code contained in the reset link of step 3.
        password: this.passwordnew,
        passwordConfirmation: this.passwordconfirm,
      });
      return post;
    },
    async submitForm() {
      this.errors = [];
      this.valid = true;
      if (this.passwordnew.length < 6) {
        this.valid = false;
        this.error = this.$t("message.password_too_short", { amt: 6 });
        return;
      }
      if (!this.passwordnew || !this.passwordconfirm) {
        this.valid = false;
        this.error = this.$t("message.fill_in_all_fields");
        return;
      }
      if (this.passwordnew !== this.passwordconfirm) {
        this.valid = false;
        this.error = this.$t("message.passwords_do_not_match");
        return;
      }
      try {
        this.isLoading = true;
        const resetResponse = await this.resetPassword();
        await this.$store.dispatch("doLogin", resetResponse.data);
        const redirectUrl = "/" + (this.$route.query.redirect || "");
        // console.log('after commit dologin',redirectUrl)
        // const {data: company} = await this.axios.get('/companybranches?isdefault=true')
        // this.$store.commit('setCompany', company[0]);
        this.$router.replace(redirectUrl);
      } catch (err) {
        this.error = err || "Authentication failure";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.login-page-wrapper {
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  width: 320px;
}
</style>