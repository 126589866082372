<template>
  <div class="calendar-wrapper">
    <div id="calendar" class="fullcalendar"></div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
// import {Popover} from "bootstrap";
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';

export default {
  mounted() {
      this.initCalendar();
  },
  beforeUnmount() {
    this.mitt.off('langChanged')
  },
  props: ["events"],
  methods: {
    initCalendar() {
      // let _this = this;
      const calendarEl = this.$el.querySelector("#calendar");

      let calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        locales: [ frLocale, nlLocale ],
        locale: this.$i18n.locale,
        droppable: true,
        events: this.events,
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,today,next",
          center: "title",
          right: "dayGridMonth,listMonth",
        },
        aspectRatio: window.outerWidth < 1025 ? 0.5 : 1.35,
      });

      this.mitt.on('langChanged', () => {
        calendar.setOption('locale', this.$i18n.locale);
      })

      calendar.render();
    },
  },
};
</script>