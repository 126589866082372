<template>
  <form class="card login-form" action="" @submit.prevent="submitForm">
    <div class="card-header bgc-groen c-50">
      <div class="overline text-sm ">
        {{ $t("message.please_log_in") }}
      </div>
    </div>
    <div class="card-body">
      <p>
        <label for="username" class="text-sentence">{{ $t("message.email") }}</label>
        <input
          class="w-100"
          id="username"
          name="username"
          v-model="email"
          type="text"
        />
      </p>
      <p>
        <label class="text-sentence" for="password">{{ $t('message.password') }}</label>
        <input
          class="w-100"
          id="password"
          name="password"
          v-model="password"
          type="password"
        />
      </p>
      <p v-if="error" class="c-koraalrood c-200">
        {{ error }}
      </p>
      <p
        style="margin-top: 37px"
        class="mb-0 d-flex justify-content-between align-items-center"
      >
        <a type="button" @click="$emit('forgot')" class="text-sm text-sentence"
          >{{ $t('message.forgot_password') }}</a
        >
        <button v-if="!isLoading" class="btn btn-primary" type="submit">
          <span>{{ $t('message.log_in') }}</span>
        </button>
        <button v-else class="btn disabled d-flex" type="button" disabled>
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{ $t("message.loading") }}...</span>
          </div>
          <span class="ms-2">{{ $t('message.logging_in') }}</span>
        </button>
      </p>
    </div>
  </form>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      valid: true,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    async submitForm() {
      this.errors = [];
      this.valid = true;
      if (this.email === "" || !this.password/* || this.password.length < 6*/) {
        this.valid = false;
        this.error = this.$t("message.fill_in_all_fields");
        return;
      }
      try {
        this.isLoading = true;
        // this.$store.commit('setAuth', false)
        // console.log("dispatch login");
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        // console.log("login complate");
        const redirectUrl = "/" + (this.$route.query.redirect || "");
        // const {data: company} = await this.axios.get('/companybranches?isdefault=true')
        // this.$store.commit('setCompany', company[0]);
        await this.$router.replace(redirectUrl);
      } catch (err) {
        this.error = err || "Authentication failure";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.login-page-wrapper {
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  width: 320px;
}
</style>