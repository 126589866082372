<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div
      class="modal-header c-50"
      :class="type == 'company' ? 'bgc-okergeel' : 'bgc-blauw'"
    >
      <div
        class="c-200 text-sm modal-title w-100 flex-grow-1"
        :class="type == 'company' ? 'c-okergeel' : 'c-blauw'"
      >
        {{ moment(message.created_at).format("YYYY-MM-DD") }}
        <template v-if="message.type === 'message'"
          >&nbsp;•&nbsp;<span class="text-sentence">{{ $t("message.writtenBy") }}</span>
          {{
            message.author
              ? message.author.firstname + " " + message.author.lastname
              : "Anonymous"
          }}
        </template>
      </div>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <h4>{{ message.title }}</h4>
      <span v-html="message.body"></span>

      <div class="mt-5">
        <template v-if="message.type === 'message'">
          <span
            v-bind:key="tag"
            v-for="tag in message.tags"
            class="badge c-50 rounded-8 fw-normal c-body me-2"
            :class="type == 'company' ? 'bgc-okergeel' : 'bgc-blauw'"
            >{{ tag.tagtext }}</span
          >
        </template>
        <template v-else>
          <span
            class="badge c-50 rounded-8 fw-normal c-body me-2 text-sentence"
            :class="type == 'company' ? 'bgc-okergeel' : 'bgc-blauw'"
            >{{ $t("message.action") }}</span
          >
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: ["message", "type"],
  data() {
    return {
      moment: moment,
    };
  },
};
</script>