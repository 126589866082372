import qs from 'qs'
import moment from "moment";

export default {
    logout(context) {
        //clear store and localstorage
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        context.commit('setUser', {
            userId: null,
            user: {},
        })
        context.commit('setToken', null)
    },
    async login(context, payload) {
        const response = await context.state.axios.post('/auth/local', {
            identifier: payload.email,
            password: payload.password,
        }).catch(function (error) {
            const msg = error.response.data.message[0].messages[0].message
            throw new Error(msg || 'Authentication failure.');
        })

        const responseData = response.data;
        localStorage.setItem('token', responseData.jwt);
        localStorage.setItem('userId', responseData.user.id);
        return await context.dispatch('doLogin', responseData)
    },
    async doLogin(context, userData) {
        //fetch additional user data, such as managed swarms, and update user object
        //also fetch logged in data such as settings and company info and faq
        context.commit('setToken', userData.jwt)
        const user = userData.user

        const q = qs.stringify({
            _where: {
                _or: [
                    {isbase_ne: true},
                    {isbase_null: true},
                ]
            }
        })
        const {data: swarms} = await context.state.axios.get('/swarms/overlords?' + q)
        const teams = swarms.filter(team => {
            return team.issegment !== true
        });
        const segments = swarms.filter(team => {
            return team.issegment === true
        });

        const teamcount = teams.length;
        const segmentcount = segments.length;

        user.ismanager = teamcount > 0;
        user.issegmentmanager = segmentcount > 0;

        localStorage.setItem('user', JSON.stringify(user));

        context.commit('setUser', {
            userId: userData.user.id,
            user: user
        })

        await context.dispatch('setLoggedInData')
        await context.dispatch('fetchPublicSettings')
        return true
    },
    async setLoggedInData(context) {
        //fetch global data for logged in user, such as company info and faq
        const {data: company} = await context.state.axios.get('/companybranches?isdefault=true')
        context.commit('setCompany', company[0]);
        const {data: faq} = await context.state.axios.get('/settings/faq')
        context.commit('setFaq', faq)
        const {data: vitamins} = await context.state.axios.get('/checkups/owncount?start_lte=' + moment().startOf('day') + '&end_gt=' + moment().startOf('day'));
        context.commit('setActiveVitaminsCount', vitamins)
    },

};