<template>
  <div class="modal-content">
    <div class="modal-header c-50" :class="'bgc-koraalrood'">
      <div :class="'c-koraalrood'" class=" c-200 text-sm modal-title w-100 flex-grow-1">
        <span class="text-sentence">{{ $t('message.connection_error') }}</span>
      </div>
      <button type="button" class="d-block ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <span class="text-sentence">{{ $t('message.connection_error_body') }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['err'],
}
</script>