<template>
  <div>
    <div v-if="editor" class="mb-2">
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
<!--        <strong>B</strong>-->
        <i class="fas fa-bold"></i>
      </button>

      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
<!--        <em>I</em>-->
        <i class="far fa-italic"></i>
      </button>

      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
<!--        <span style="text-decoration: line-through;" >&nbsp;S&nbsp;</span>-->
        <i class="far fa-strikethrough"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        <i class="far fa-code"></i>
      </button>
      <button type="button" class="btn btn-link" @click="clearAllFormatting">
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().unsetAllMarks().run()">-->
<!--        clear marks-->
        <i class="far fa-remove-format"></i>
      </button>
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().clearNodes().run()">-->
<!--        clear nodes-->
<!--      </button>-->
      <button type="button" class="btn btn-link" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        <i class="far fa-paragraph"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        <i class="fas fa-h1"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        <i class="fas fa-h2"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        <i class="fas fa-h3"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        <i class="fas fa-h4"></i>
      </button>
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">-->
<!--        <i class="fas fa-h5"></i>-->
<!--      </button>-->
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">-->
<!--        <i class="fas fa-h6"></i>-->
<!--      </button>-->
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <i class="far fa-list-ul"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <i class="far fa-list-ol"></i>
      </button>
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">-->
<!--        code block-->
<!--      </button>-->
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">-->
<!--        blockquote-->
<!--      </button>-->
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().setHorizontalRule().run()">-->
<!--        horizontal rule-->
<!--      </button>-->
<!--      <button type="button" class="btn btn-link" @click="editor.chain().focus().setHardBreak().run()">-->
<!--        hard break-->
<!--      </button>-->
      <button type="button" class="btn btn-link" @click="editor.chain().focus().undo().run()">
        <i class="far fa-undo"></i>
      </button>
      <button type="button" class="btn btn-link" @click="editor.chain().focus().redo().run()">
        <i class="far fa-redo"></i>
      </button>
    </div>
<!--    <div class="form-control">-->
      <editor-content class="form-control" :editor="editor" />
<!--    </div>-->
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },


  methods:{
    clearAllFormatting(){
      this.editor.chain().focus().unsetAllMarks().run()
      this.editor.chain().focus().clearNodes().run()
    }
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.modelValue, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss">
.ProseMirror{
  outline:0 !important;
  &:focus{
  }
}

button.is-active {
  background: #f2f2f5;
}
</style>