<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div class="modal-header c-50" :class="'bgc-'+color">
      <div :class="'c-'+color" class=" c-200 text-sm modal-title w-100 flex-grow-1 text-sentence">
        <span v-if="id" class="text-sentence">
          {{ $t('message.edit_this_x_action', {type: type}) }}
        </span>
        <span v-else class="text-sentence">
          {{ $t('message.add_a_x_action', {type: type}) }}
        </span>
      </div>
      <button type="button" class="d-block ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <action-form :id="id" :type="type" @submit-action-form="handleSubmit"></action-form>
    </div>
  </div>
</template>
<script>
import ActionForm from "@/views/components/ActionForm";

export default {
  components: {ActionForm},
  emits: ['submitModal'],
  methods: {
    handleSubmit(action) {
      this.$emit('submitModal', action);
      this.$store.dispatch('closeModal')
    }
  },
  computed: {

    typeName() {
      return this.$t('message.' + this.type);
    },
    color() {
      switch (this.type) {
        case 'personal':
          return 'paars';
        case 'team':
          return 'blauw';
        case 'company':
          return 'okergeel';
        default:
          return 'paars'
      }
    }
  },
  props: [
    'type', 'id'
  ],

}
</script>