<template>
  <div class="page-home">
    <!--    {{user}}-->
    <div
      class="
        mb-4
        title
        d-flex
        justify-content-between
        align-items-center
        flex-wrap
      "
    >
      <h2 class="text-sentence">
        {{ $t("message.overview") }} <em class="">{{ $t("message.for") }}</em>
        <span v-if="user">{{ " " + user.firstname }} {{ user.lastname }}</span>
      </h2>
      <select v-model="scoretype" name="" id="" class="home-toggle-scores">
        <option value="abs">{{ $t("message.absoluteScores") }}</option>
        <option v-if="normeringData && normeringData.length" value="pctl">
          <!--Percentielen -->{{ $t("message.percentiles") }}
        </option>
      </select>
    </div>
    <div class="row top-cards">
      <div class="col-md-3 mb-4">
        <div class="h-100 card rounded-16">
          <div class="card-body p-3">
            <div class="top mb-1 d-flex justify-content-between">
              <div class="overline text-sm">
                <span>{{ $t("message.WB") }}</span>
              </div>
              <ul v-if="wellbeing" class="nav nav-pills p-0">
                <li class="nav-item dropdown p-0">
                  <a
                    class="nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/icons/Icon-navigation-More-vertical.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        type="button"
                        @click="openSubmissionModal"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="text-sentence">{{
                          $t("message.viewSubmission")
                        }}</span>
                      </button>
                    </li>
                    <!--<li><a class="dropdown-item" href="#">View raw data</a></li>-->
                    <!--                    <li>-->
                    <!--                      <hr class="dropdown-divider">-->
                    <!--                    </li>-->
                    <!--                    <li><a class="dropdown-item" href="#">Separated link</a></li>-->
                  </ul>
                </li>
              </ul>
            </div>
            <div class="content row align-items-center">
              <div class="col-auto">
                <progress-chart
                  v-if="wellbeing"
                  style="width: 96px; height: 96px"
                  :pct="wellbeing"
                  :color="getColorByVitamin('wellbeing')"
                  border-width="6"
                  f-size="lg"
                ></progress-chart>
              </div>
              <div v-if="wb_change" class="col ms-n3 pb-2 border-bottom">
                <div v-if="wb_change" class="mb-0 d-flex align-items-end">
                  <h5
                    :class="wb_change < 0 ? 'c-error' : 'c-success'"
                    class="mb-0 c-error me-1"
                  >
                    {{ wb_change }}%
                  </h5>
                  <img
                    v-show="wb_change < 0"
                    src="../assets/icons/icon-navigation-downward-arrow-red.svg"
                    alt=""
                  />
                  <img
                    v-show="wb_change > 0"
                    src="../assets/icons/icon-navigation-upward-arrow-green.svg"
                    alt=""
                  />
                </div>
                <p class="small mb-0 text-sentence d-block">
                  {{ $t("message.since_last_checkup") }}
                </p>
              </div>
              <div
                v-else-if="!wellbeing && !wb_change"
                class="col mt-2 ms-n3 pb-2"
              >
                <p class="small mb-0 text-sentence d-block">
                  {{ $t("message.no_data_available") }}
                </p>
              </div>
              <div v-else class="col ms-n3 pb-2 border-bottom">
                <p class="small mb-0 text-sentence d-block">
                  {{ $t("message.baseline_measurement") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="h-100 card rounded-16">
          <div class="card-body p-3">
            <div class="top mb-1 d-flex justify-content-between">
              <div class="overline text-sm">
                <span>{{ $t("message.Eng") }}</span>
              </div>
              <ul class="nav nav-pills p-0" v-if="engagement">
                <li class="nav-item dropdown p-0">
                  <a
                    class="nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/icons/Icon-navigation-More-vertical.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        type="button"
                        @click="openSubmissionModal"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="text-sentence">{{
                          $t("message.viewSubmission")
                        }}</span>
                      </button>
                    </li>
                    <!-- <li><a class="dropdown-item" href="#">View raw data</a></li>-->
                  </ul>
                </li>
              </ul>
            </div>
            <div class="content row align-items-center">
              <div class="col-auto">
                <progress-chart
                  v-if="engagement"
                  style="width: 96px; height: 96px"
                  :pct="engagement"
                  :color="getColorByVitamin('engagement')"
                  border-width="6"
                  f-size="lg"
                ></progress-chart>
              </div>
              <div v-if="eng_change" class="col ms-n3 pb-2 border-bottom">
                <div v-if="eng_change" class="mb-0 d-flex align-items-end">
                  <h5
                    :class="eng_change < 0 ? 'c-error' : 'c-success'"
                    class="mb-0 c-error me-1"
                  >
                    {{ eng_change }}%
                  </h5>
                  <img
                    v-show="eng_change < 0"
                    src="../assets/icons/icon-navigation-downward-arrow-red.svg"
                    alt=""
                  />
                  <img
                    v-show="eng_change > 0"
                    src="../assets/icons/icon-navigation-upward-arrow-green.svg"
                    alt=""
                  />
                </div>
                <p class="small mb-0 text-sentence d-block">
                  {{ $t("message.since_last_checkup") }}
                </p>
              </div>
              <div
                v-else-if="!engagement && !eng_change"
                class="col mt-2 ms-n3 pb-2"
              >
                <p class="small mb-0 text-sentence d-block">
                  {{ $t("message.no_data_available") }}
                </p>
              </div>
              <div v-else class="col ms-n3 pb-2 border-bottom">
                <p class="small mb-0 text-sentence d-block">
                  {{ $t("message.baseline_measurement") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="h-100 card rounded-16">
          <div v-if="activeCheckup" class="card-body p-3">
            <div class="top mb-2 d-flex justify-content-between">
              <div class="overline text-sm">
                <!--Current checkup-->{{ activeCheckup.title }}
              </div>
              <!--              <ul class="nav nav-pills p-0">-->
              <!--                <li class="nav-item dropdown p-0">-->
              <!--                  <a class="nav-link p-0" data-bs-toggle="dropdown" href="#" role="button"-->
              <!--                     aria-expanded="false">-->
              <!--                    <img src="../assets/icons/Icon-navigation-More-vertical.svg" alt="">-->
              <!--                  </a>-->
              <!--                  <ul class="dropdown-menu">-->
              <!--                    <li><a class="dropdown-item" href="#">Action</a></li>-->
              <!--                    <li><a class="dropdown-item" href="#">Another action</a></li>-->
              <!--                    <li><a class="dropdown-item" href="#">Something else here</a></li>-->
              <!--                    <li>-->
              <!--                      <hr class="dropdown-divider">-->
              <!--                    </li>-->
              <!--                    <li><a class="dropdown-item" href="#">Separated link</a></li>-->
              <!--                  </ul>-->
              <!--                </li>-->
              <!--              </ul>-->
            </div>
            <div class="text-sm mb-2">
              {{ activeCheckupPct }}% {{ $t("message.complete") }}
            </div>
            <div class="progress">
              <div
                class="progress-bar bgc-turkoois c-200"
                :class="'bgc-' + activeCheckup.color"
                role="progressbar"
                :style="'width: ' + activeCheckupPct + '%'"
                :aria-valuenow="activeCheckupPct"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="mt-2 mb-4 text-sm">
              <span class="text-sentence"
                >{{ $t("message.deadline") }}:
                {{ moment(activeCheckup.deadline).format("DD/MM/YYYY") }}</span
              >
            </div>
            <!--            <p class="mb-0 mt-4 text-right">-->
            <router-link
              :to="{
                name: 'checkup',
                params: {
                  // checkup: checkup.entry ?? '',
                  // submission:JSON.stringify(submission),
                  id: activeCheckup.id,
                },
              }"
              v-if="activeCheckup.submission"
              class="d-block w-auto ms-auto btn btn-link mb-n3"
              style="max-width: fit-content"
            >
              <span>{{ $t("message.afwerken") }}</span>
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'checkup',
                params: {
                  // checkup: checkup.entry ?? '',
                  // submission:JSON.stringify(submission),
                  id: activeCheckup.id,
                },
              }"
              class="d-block ms-auto btn btn-link mb-n3"
              style="max-width: fit-content"
              ><span>{{ $t("message.start") }}</span>
            </router-link>
            <!--            </p>-->
            <div
              class="d-flex justify-content-center"
              v-if="checkups.length > 1"
            >
              <button
                @click="setActiveCheckup(i)"
                v-for="(checkup, i) in checkups"
                :key="checkup.id"
                :id="i"
                class="rounded-circle bgc-theme border-0 c-400 mx-1"
                :class="activeCheckup == checkup ? ' bgc-theme c-700' : ''"
                style="height: 12px; width: 12px"
              ></button>
            </div>
          </div>
          <div
            v-else-if="checkups && checkups.length == 0"
            class="
              card-body
              p-3
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <div class="overline text-sm">
              <span>{{ $t("message.noActiveCheckups") }}</span>
            </div>
          </div>
          <div
            v-else
            class="
              card-body
              p-3
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden"
                >{{ $t("message.loading") }}...</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row main-cards">
      <div class="col-xl-6 col-lg-12 mb-4">
        <div class="card h-100">
          <div class="card-header bgc-paars c-50 d-flex">
            <div class="title col overline text-sm">
              <span>{{ $t("message.zoomedIn") }}</span>
            </div>
            <div class="nav col-auto"></div>
          </div>
          <div class="card-body" v-if="personalDatasets.data.length">
            <div
              class="vitamins h-100 d-flex flex-column justify-content-between"
            >
              <div
                class="vitamin border-bottom pb-2 mb-3"
                v-for="(val, i) in zoomedinSet"
                :key="i"
              >
                <div class="row">
                  <div class="col-auto">
                    <!--                    <pre>src: {{ val.z }}</pre>-->
                    <progress-chart
                      style="height: 64px; width: 64px"
                      :pct="val.y"
                      border-width="6"
                      f-size="md"
                      :color="
                        getColorByVitamin(
                          personalLabelsLocal[i + zoomedinStart]
                        )
                      "
                    ></progress-chart>
                  </div>
                  <div class="col">
                    <h5 class="text-capitalize">
                      {{ getVitaminTitle(personalLabels[i + zoomedinStart]) }}
                    </h5>
                    <!--                    <pre>type: {{types[i + zoomedinStart]}}</pre>-->
                    <p class="text-sm">
                      {{ getVitaminBody(personalLabels[i + zoomedinStart]) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button
                  @click="zoomedinPage = i"
                  v-for="i in Math.ceil(personalDatasets.data.length / 3)"
                  :key="i"
                  :id="i"
                  class="rounded-circle bgc-theme border-0 c-400 mx-1"
                  :class="zoomedinPage == i ? ' bgc-theme c-700' : ''"
                  style="height: 12px; width: 12px"
                ></button>
              </div>
              <!--              <div class="vitamin  border-bottom pb-2 mb-3">-->
              <!--                <div class="row">-->
              <!--                  <div class="col-auto">-->
              <!--                    <progress-chart style="height: 64px;width: 64px;" :pct="25" border-width="6"-->
              <!--                                    f-size="md"></progress-chart>-->
              <!--                  </div>-->
              <!--                  <div class="col">-->
              <!--                    <h5>Emotionele belasting</h5>-->
              <!--                    <p class="text-sm">-->
              <!--                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis doloremque eaque nemo numquam-->
              <!--                      odit porro recusandae sed, totam voluptates!-->
              <!--                    </p>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="vitamin mb-2">-->
              <!--                <div class="row">-->
              <!--                  <div class="col-auto">-->
              <!--                    <progress-chart style="height: 64px;width: 64px;" :pct="25" border-width="6"-->
              <!--                                    f-size="md"></progress-chart>-->
              <!--                  </div>-->
              <!--                  <div class="col">-->
              <!--                    <h5>Emotionele belasting</h5>-->
              <!--                    <p class="text-sm">-->
              <!--                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis doloremque eaque nemo numquam-->
              <!--                      odit porro recusandae sed, totam voluptates!-->
              <!--                    </p>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="h-100 d-flex justify-content-center align-items-center">
              <p class="text-sentence">
                {{ $t("message.no_data_available") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 mb-4">
        <div class="card h-100">
          <div class="card-header bgc-okergeel d-flex">
            <div class="title col overline text-sm">
              <span>{{ $t("message.yourOverallPerformance") }}</span>
            </div>
            <div class="nav col-auto"></div>
          </div>
          <div class="card-body">
            <bar-chart
              :hide-line="true"
              :hidelegend="true"
              :labels="personalLabelsLocal"
              :datasets="[personalDatasets]"
            ></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex flex-column mb-4" v-if="user">
        <div class="mb-gutter card action-card">
          <div
            class="ps-0 card-header c-200 text-white"
            :class="'bgc-' + color(action_type)"
          >
            <div
              v-if="user.ismanager || user.manageteams || user.managecompany"
              class="card-nav-main nav"
              :class="'bgc-' + color(action_type)"
            >
              <ul class="nav nav-pills p-0">
                <li class="nav-item dropdown p-0">
                  <a
                    class="card-header-nav-btn nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/icons/icon-navigation-expand-light.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        :class="
                          (action_type === 'personal' ? 'd-none' : 'c-50') +
                          ' bgc-' +
                          color('personal')
                        "
                        @click.prevent="action_type = 'personal'"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="overline text-sm ps-3 ">{{
                          $t("message.personalActions")
                        }}</span>
                      </a>
                    </li>
                    <li
                      v-if="
                        user.ismanager || user.manageteams || user.managecompany
                      "
                    >
                      <a
                        :class="
                          (action_type === 'team' ? 'd-none' : 'c-50') +
                          ' bgc-' +
                          color('team')
                        "
                        @click.prevent="action_type = 'team'"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="overline text-sm ps-3 ">{{
                          $t("message.teamActions")
                        }}</span>
                      </a>
                    </li>
                    <li v-if="user.managecompany">
                      <a
                        :class="
                          (action_type === 'company' ? 'd-none' : 'c-50') +
                          ' bgc-' +
                          color('company')
                        "
                        @click.prevent="action_type = 'company'"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="overline text-sm ps-3 ">{{
                          $t("message.companyActions")
                        }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <span class="ps-3 overline text-sm "
              >{{ $t("message." + action_type) }}
              {{ $t("message.actions") }}</span
            >
          </div>
          <div class="card-body" style="overflow-x: auto">
            <action-list :type="action_type"></action-list>
          </div>
        </div>

        <div class="card message-card">
          <div v-if="message_type === 'team'" class="card-header bgc-blauw">
            <span class="overline text-sm">{{
              $t("message.teamMessages")
            }}</span>
          </div>
          <div
            v-if="message_type === 'company'"
            class="card-header bgc-okergeel"
          >
            <span class="overline text-sm">{{
              $t("message.companyMessages")
            }}</span>
          </div>
          <div class="card-body" style="overflow-x: auto">
            <message-list
              @changeType="handleChangeMessageType"
              :type="message_type"
            ></message-list>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="card calendar-card">
          <div class="card-header bgc-groengrijs c-50 d-flex">
            <div class="title col overline text-sm">
              <span>{{ $t("message.calendar") }}</span>
            </div>
            <div class="nav col-auto">
              <ul class="nav nav-pills p-0">
                <li class="nav-item dropdown p-0">
                  <a
                    class="nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/icons/Icon-navigation-More-vertical.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        type="button"
                        class="dropdown-item"
                        @click="createICSFile"
                      >
                        <i class="far fa-calendar"></i>
                        <span class="text-sentence">{{ $t("message.addToPersonalCalendar") }}</span>
                        (.ics)
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="loaded" class="card-body">
            <calendar :events="getEvents"></calendar>
          </div>
        </div>
      </div>
    </div>
    <!--    <pre>{{personalLabels}}</pre>-->
  </div>
</template>

<script>
// @ is an alias to /src
import ProgressChart from "@/views/components/ProgressChart";
import Calendar from "@/views/components/Calendar";
import ActionList from "@/views/components/ActionList";
import BarChart from "@/views/components/charts/BarChart";
import moment from "moment";
import qs from "qs";
import MessageList from "@/views/components/MessageList";
import SubmissionModal from "@/views/components/modals/SubmissionModal";

export default {
  name: "Home",
  components: {
    MessageList,
    ActionList,
    Calendar,
    ProgressChart,
    BarChart,
  },
  created() {
    window.addEventListener("resize", () => {
      setTimeout(() => {
        this.fixHeight();
      }, 500);
    });
    this.initDash();
  },
  updated() {
    this.fixHeight();
  },
  mounted() {},
  methods: {
    async initDash() {
      await this.getVitamins();
      await this.getTranslatables();
      await this.getNormeringData();
      await this.getLatestSubmission();
      await this.getCheckups();
      this.mitt.emit("loadcomplete", { complete: true });
      this.loaded = true;
    },
    openSubmissionModal() {
      this.$store.dispatch("openModal", {
        component: SubmissionModal,
        options: { centered: false, size: "lg" },
        properties: {
          submissionid: this.latestSubmissionId,
        },
      });
    },
    async getTranslatables() {
      const { data: translatables } = await this.axios.get(
        "/translatables?key=zoomed_in"
      );
      this.translatables = translatables;
    },
    fixHeight() {
      const calendarcard = this.$el.querySelector(".calendar-card");
      const actioncard = this.$el.querySelector(".action-card");
      const messagecard = this.$el.querySelector(".message-card");
      const calheight = parseFloat(
        getComputedStyle(calendarcard, null).height.replace("px", "")
      );
      // const calheight = calendarcard.offsetHeight
      const style = getComputedStyle(actioncard);
      const actionmargin =
        parseInt(style.marginTop) + parseInt(style.marginBottom);
      const half = calheight / 2 - actionmargin / 2;
      if (calheight > 0 && window.outerWidth > 768) {
        actioncard.style.height = half + "px";
        messagecard.style.height = half + "px";
        // console.log('calheight:', calheight)
      } else {
        actioncard.style.height = "auto";
        messagecard.style.height = "auto";
      }
    },
    handleChangeMessageType(type) {
      this.message_type = type;
    },
    setActiveCheckup(index) {
      this.activeCheckupKey = index;
    },
    getVitaminBody(key) {
      // console.log('find vitamin',type,this.vitamins)
      if (this.translatables) {
        const translatable = this.translatables[this.$i18n.locale];

        return translatable ? translatable[key.toUpperCase()].body : "";
      } else {
        return "";
      }
      // const vit = this.vitamins.find(vit =>
      //     vit.title.toLowerCase().indexOf( type.toLowerCase()) > -1
      // )
      // if (vit) {
      //   return vit.body
      // } else {
      //   return ''
      // }
    },
    getVitaminTitle(key) {
      if (this.translatables) {
        const translatable = this.translatables[this.$i18n.locale];

        return translatable ? translatable[key.toUpperCase()].title : "";
      }
      return "";
    },
    async getCheckups() {
      const { data } = await this.axios.get(
        "/checkups/own?_where[0][start_lte]=" +
          moment().startOf("day") +
          "&_where[1][end_gt]=" +
          moment().startOf("day") +
          "&_sort=end:asc"
      );
      this.checkups = data.filter(
        (entity) =>
          !entity.submission ||
          (entity.submission && !entity.submission.isfinished)
      );
    },
    async getEvents(info, successCallback, failureCallback) {
      try {
        return await this.getCalendarEvents(
          info.start.valueOf(),
          info.end.valueOf()
        );
      } catch (e) {
        failureCallback(e);
      }
    },
    getCalEvent(checkup) {
      return {
        id: checkup.id,
        title: checkup.title,
        display: "block",
        allDay: true,
        body: checkup.body,
        grace: checkup.graceperiod,
        duration: { days: checkup.duration /*+ checkup.graceperiod*/ },
        backgroundColor: this.getColorCode(checkup.color),
        borderColor: this.getColorCode(checkup.color),
        checkup: checkup,
      };
    },
    async getCalendarEvents(start, end) {
      let _this = this;
      const q = qs.stringify({
        _where: {
          _or: [
            {
              start_lt: end,
              end_gte: start,
            },
            {
              start_gte: start,
              start_lte: end,
            },
          ],
        },
      });
      const get = await this.axios.get("/checkupentries?" + q);
      const entries = get.data;
      const events = entries.map((entry) => {
        let event = _this.getCalEvent(entry.checkup);
        event.start = entry.start;
        event.end = entry.end;
        return event;
      });
      // console.log('events got', events)
      return events;
    },
    color(type) {
      switch (type) {
        case "personal":
          return "paars";
        case "team":
          return "blauw";
        case "company":
          return "okergeel";
        default:
          return "paars";
      }
    },
    async getLatestSubmission() {
      //todo only return submission by user in backend
      let q =
        "/checkupsubmissions?isfinished=true&_sort=updated_at:desc&_limit=2";
      const get = await this.axios.get(q);
      const submission = get.data ? get.data[0] : null;
      const submission_prev = get.data ? get.data[1] : null;
      if (submission) {
        this.latestSubmissionId = submission.id;
        this.latestSubmissionData = [];
        this.personalLabels.forEach((label) => {
          this.latestSubmissionData.push(
            submission.data.data[label.toUpperCase()]
          );
        });
        if (submission_prev) {
          // console.log('found previous')
          this.previousCheckup = submission_prev;
        }
      }
    },
    async getVitamins() {
      const get = await this.axios.get("/vitamins");
      this.vitamins = get.data;
    },
    async getNormeringData() {
      const get = await this.axios.get(
        "/analytics-items?isactive=true&type=normering&_sort=subtype:asc"
      );
      this.normeringData = get.data;
    },
    getNormering(type, val) {
      const n = this.normeringData.find((norm) => {
        return norm.subtype.toLowerCase() === type;
      });
      if (n) {
        return n.data[val];
      } else {
        return 0;
      }
    },
    async createICSFile() {
      // Get all events for the rest of the year
      const events = await this.getCalendarEvents(
        moment().startOf("day").toISOString(),
        moment().endOf("year").toISOString()
      );
      // Start ICS file
      let file =
        "BEGIN:VCALENDAR\r\n" +
        "CALSCALE:GREGORIAN\r\n" +
        "METHOD:PUBLISH\r\n" +
        "PRODID:-//Vitamins//EN\r\n" +
        "VERSION:2.0\r\n";

      for (const event of events) {
        // Get correct dates
        const start = moment(event.start)
          .utc(true)
          .toISOString()
          .split("T")[0]
          .replace(/[-]/g, "");
        const end = moment(event.end)
          .startOf("day")
          .utc(true)
          .toISOString()
          .split("T")[0]
          .replace(/[-]/g, "");
        // Add events to ICS file
        file +=
          "BEGIN:VEVENT\r\n" +
          `UID:vitamin-${event.id}\r\n` +
          "DTSTART;VALUE=DATE:" +
          start +
          "\r\n" +
          "DTEND;VALUE=DATE:" +
          end +
          "\r\n" +
          "SUMMARY:Checkup: " +
          event.title +
          "\r\n" +
          "DESCRIPTION:" +
          event.body +
          "\r\n" +
          "END:VEVENT\n";
      }

      // End ICS file
      file += "END:VCALENDAR";

      // Create file and download it
      let data = new File([file], "vitamins.ics", { type: "text/plain" });
      const icsFile = window.URL.createObjectURL(data);

      const link = document.createElement("a");
      link.setAttribute("href", icsFile);
      link.setAttribute("download", "vitamins.ics");
      document.body.appendChild(link);
      link.click();

      // Remove file after download
      window.URL.revokeObjectURL(icsFile);
      document.body.removeChild(link);
    },
  },
  data() {
    return {
      normeringData: null,
      loaded: false,
      vitamins: [],
      scoretype: "abs",
      latestSubmissionId: null,
      latestSubmissionData: [],
      types: [
        "wellbeing",
        "engagement",
        "energiebronnen",
        "hinderpalen",
        "hulpbronnen",
        "uitdagingen",
      ],
      personalLabels: ["WB", "Eng", "EB", "HIN", "PHB", "UIT"],
      translatables: null,
      previousCheckup: null,
      action_type: "personal",
      message_type: "team",
      checkups: null,
      activeCheckupKey: 0,
      zoomedinPage: 1,
      zoomedinrows: 3,
      moment: moment,
    };
  },
  computed: {
    barData() {
      return this.latestSubmissionData.map((data, ind) => {
        // console.log('bardata', ind)
        return {
          x: ind,
          y:
            Math.round(this.scoretype === "abs"
              ? (data / 7) * 100
              : this.getNormering(this.types[ind], data)),
          z: data,
        };
      });
    },

    wb_change() {
      if (this.wb_prev) {
        const pct =
          Math.round(((this.wellbeing / this.wb_prev) * 100 - 100) * 100) / 100;
        return pct;
      } else {
        return null;
      }
    },
    eng_change() {
      if (this.eng_prev) {
        const pct =
          Math.round(((this.engagement / this.eng_prev) * 100 - 100) * 100) /
          100;
        return pct;
      } else {
        return null;
      }
    },
    wb_prev() {
      return this.previousCheckup
        ? (this.previousCheckup.data.data.WB / 7) * 100
        : null;
    },
    eng_prev() {
      return this.previousCheckup
        ? (this.previousCheckup.data.data.ENG / 7) * 100
        : null;
    },
    wellbeing() {
      let wbind = 0;
      this.personalLabels.forEach((label, ind) => {
        if (label === "WB") {
          wbind = ind;
        }
      });
      return this.personalDatasets.data.length > 0
        ? this.personalDatasets.data[wbind].y
        : null;
    },
    engagement() {
      let wbind = 0;
      this.personalLabels.forEach((label, ind) => {
        if (label === "Eng") {
          wbind = ind;
        }
      });
      return this.personalDatasets.data.length > 0
        ? this.personalDatasets.data[wbind].y
        : null;
    },
    personalLabelsLocal() {
      return this.personalLabels.map((label) => {
        return this.$t("message." + label);
      });
    },
    personalDatasets() {
      let colors = [];
      for (const label of this.personalLabels) {
        let opacity = 0.5;
        if (label == "WB" || label == "Eng") {
          opacity = 1;
        }
        colors.push(
          this.setOpacity(
            this.hexToRgb(this.getColorCode(this.getColorByVitamin(label))),
            opacity
          )
        );
        // colors.push(this.getColorByVitamin(label));
      }
      return {
        label: "Current",
        // backgroundColor: [this.setOpacity(this.hexToRgb(this.getColorCode('okergeel-200')), 0.5), this.setOpacity(this.hexToRgb(this.getColorCode('groen')), 0.5)],
        backgroundColor: colors,
        borderColor: this.getColorCode("okergeel-200"),
        data: this.barData,
      };
    },
    zoomedinStart() {
      return (this.zoomedinPage - 1) * this.zoomedinrows;
    },
    zoomedinSet() {
      return this.personalDatasets.data.slice(
        this.zoomedinStart,
        3 * this.zoomedinPage
      );
    },
    activeCheckup() {
      return this.checkups ? this.checkups[this.activeCheckupKey] : null;
    },
    activeCheckupPct() {
      if (this.activeCheckup.submission) {
        return (
          (this.activeCheckup.submission.submission.answeredCount /
            this.activeCheckup.questionCount) *
          100
        ).toFixed(0);
      } else {
        return 0;
      }
    },
  },
};
</script>
<style lang="scss">
.home-toggle-scores {
  @media all and (max-width: 767px) {
    flex-grow: 1;
  }
}

@media all and (max-width: 767px) {
  .fc-header-toolbar {
    flex-wrap: wrap;
  }
  .fc-toolbar-chunk {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
}

@media all and (min-width: 768px) {
}

@media all and(max-width:1366px) {
  .fc .fc-toolbar-title {
    font-size: 16px;
    text-align: center;
  }
  .fc-button {
    padding: 4px !important;
  }
}

@media all and(max-width:768px) {
  .fc .fc-toolbar-title {
    font-size: 14px;
  }
}

.no-focus {
  &:focus {
    outline: 0 !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
}
</style>