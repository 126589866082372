<template>
  <div class="chart position-relative">
    <div
      class="chart-score position-absolute top-50 start-50 translate-middle"
      :class="'size-' + fSize"
    >
      <div class="pt-2 text-center progress-score">{{ roundedPct }}</div>
      <div class="text-center progress-pct text-lg">%</div>
    </div>
    <canvas class="chart-canvas"></canvas>
  </div>
</template>
<script>
import { Chart, DoughnutController, ArcElement } from 'chart.js';
Chart.register(DoughnutController, ArcElement);

export default {
  data() {
    return {
      activeColor: null,
      firstLoad: true,
      i: 0,
    };
  },
  computed: {
    roundedPct() {
      return isNaN(Math.round(this.pct)) ? 0 : Math.round(this.pct);
    },
  },
  props: {
    pct: {
      default() {
        return 0;
      },
    },
    invertColors: {
      type: Boolean,
      default() {
        return false
      }
    },
    borderWidth: {
      default() {
        return 1;
      },
    },
    fSize: {
      default() {
        return "lg";
      },
    },
    color: {
      default() {
        return false;
      },
    },
    type: {
      default() {
        return "";
      },
    },
    analytics: {
      default: ''
    }
  },
  hideAnimation: {
    default() {
      return false;
    },
  },
  watch: {
    pct: function (newval, oldval) {
      if (newval != oldval && this.myChart) {
        this.myChart.data.datasets[0].data = [this.pct, 100 - this.pct]
        this.myChart.data.datasets[0].backgroundColor = [this.getColor(), "#D9DCE8"]
        this.myChart.update();
      }
    },
    color: function (newval, oldval) {
      if (newval !== oldval) {
        this.myChart.data.datasets[0].data = [this.pct, 100 - this.pct]
        this.myChart.data.datasets[0].backgroundColor = [this.getColor(), "#D9DCE8"]
        this.myChart.update();
      }
    },
  },
  created() {
    if (this.firstLoad) {
      this.mitt.on("hiddenLoader", (e) => this.handleLoaded(e));
      this.firstLoad = false;
    }
  },
  mounted() {
    if (!this.firstLoad) {
      this.handleLoaded();
    }
  },
  beforeUnmount() {
    if (this.myChart) {
      this.myChart.destroy();
    }
  },
  methods: {
    getColor() {
      let color = "#FC7C86";
      if (!this.color) {
        let score = this.roundedPct;
        if (this.invertColors) {
          score = 100 - score;
        }
        if (this.type === "sd") {
          if (score < 3) {
            color = "#00C798";
          } else if (score < 7) {
            color = "#FFDE53";
          } else if (score < 15) {
            color = "#FFA441";
          }
        } else {
          if (score > 85) {
            color = "#00C798";
          } else if (score > 65) {
            color = "#7CD578";
          } else if (score > 50) {
            color = "#FFDE53";
          } else if (score > 30) {
            color = "#FFA441";
          }
        }

        // Extra case for uitdagingen
        if (this.analytics === 'UIT') {
          if (score < 5 || score > 95) {
            color = "#FFA441";
          } else if (score < 15 || score > 85) {
            color = "#FFDE53";
          } else {
            color = "#00C798";
          }
        }
      } else {
        switch (this.color) {
          case "85+":
            color = "#00C798";
            break;
          case "65+":
            color = "#7CD578";
            break;
          case "50+":
            color = "#FFDE53";
            break;
          case "30+":
            color = "#FFA441";
            break;
          case "groen":
            color = "#bae193";
            break;
          case "groen-200":
            color = "#91BF62";
            break;
          case "turkoois":
            color = "#32A299";
            break;
          case "paars":
            color = "#8B61B2";
            break;
          case "magenta":
            color = "#C9638D";
            break;
          case "blauw":
            color = "#3C85C6";
            break;
          case "blauw-50":
            color = "#d6e9f9";
            break;
          case "koraalrood":
            color = "#E26C54";
            break;
          case "okergeel":
            color = "#D8A93D";
            break;
          case "groengrijs":
            color = "#629588";
            break;
          default:
            break;
        }
      }

      return color;
    },
    handleLoaded() {
      setTimeout(() => {
        // console.log("graf")
        const ctx = this.$el.getElementsByClassName("chart-canvas");
        const chartData = {
          labels: [],
          datasets: [
            {
              label: "My First Dataset",
              data: [this.pct, 100 - this.pct],
              backgroundColor: [this.getColor(), "#D9DCE8"],
              hoverOffset: 4,
            },
          ],
        };

        const el = this.$el;
        const w = el.clientWidth;
        const b = w / 2 - this.borderWidth;

        const chartOptions = {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          parsing: false,
          normalized: true,
          hover: { mode: null },
          responsive: true,
          borderWidth: 0,
          cutout: String(b),
        };

        if (this.hideAnimation) {
          chartOptions.animation = {
            duration: 0,
          };
        }

        const config = {
          type: "doughnut",
          data: chartData,
          options: chartOptions,
        };
        try {
          this.myChart = new Chart(ctx, config);
        } catch {
          this.myChart.update();
        }
      }, 700);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-sm {
  .progress-score {
    padding-top: 4px !important;
    font-size: 16px !important;
  }

  .progress-pct {
    font-size: 10px !important;
  }
}

.size-lg {
  .progress-score {
    padding-top: 8px !important;
    font-size: 40px !important;
  }

  .progress-pct {
    margin-top: -3px;
    font-size: 18px !important;
  }
}
</style>