<template>
  <div class="message-list">
    <div class="action-filters row">
      <div class="col-md-auto col-12 mb-md-4 mb-2">
        <search @search="handleSearch"></search>
      </div>
      <div class="col-md-auto col-12 mb-md-4 mb-2">
        <div class="w-100 btn-group" role="group" aria-label="Basic example">
          <button
            @click="switchMessageType('team')"
            type="button"
            class="btn btn-sm btn-secondary"
            :class="message_type === 'team' ? 'active' : ''"
          >
            <span>{{ $t("message.team") }}</span>
          </button>
          <button
            @click="switchMessageType('company')"
            type="button"
            class="btn btn-sm btn-secondary"
            :class="message_type === 'company' ? 'active' : ''"
          >
            <span>{{ $t("message.company") }}</span>
          </button>
        </div>
      </div>
      <div class="col-auto mb-md-4 mb-2 d-none">
        <input @change="handleDate" v-model="filterDate" type="date" />
      </div>
    </div>
    <div
      v-if="loaded"
      class="pt-md-4 pt-1 ms-md-3 border-start ps-md-5 ps-3 messages-container"
      style="overflow-x: hidden"
    >
      <div
        v-bind:key="message"
        v-for="(message, index) in messages"
        :class="[
          index > 0 ? 'border-top' : '',
          message_type == 'company' ? 'bc-okergeel' : 'bc-blauw',
          message.body.length > 50 ? 'truncated' : '',
        ]"
        class="message message-item-list mb-3 pt-3 c-200 position-relative"
      >
        <!--                {{message}}-->
        <!--        <pre>{{message.body.length}}</pre>-->
        <div
          class="
            mb-1
            message-header
            row
            justify-content-between
            align-items-center
          "
        >
          <div class="col-auto">
            <div class="text-sm">
              {{ moment(message.created_at).format("YYYY-MM-DD") }}
            </div>
            <div
              v-if="message.type === 'message'"
              class="c-200"
              :class="message_type == 'company' ? 'c-okergeel' : 'c-blauw'"
            >
              <span class="text-sentence">{{ $t("message.by") }}
              {{
                message.author
                  ? message.author.firstname + " " + message.author.lastname
                  : "Anonymous"
              }}</span>
            </div>
          </div>
          <div class="col-auto mb-2">
            <span
              v-if="message.type === 'action'"
              class="badge c-50 rounded-8 fw-normal c-body me-2 text-sentence"
              :class="message_type == 'company' ? 'bgc-okergeel' : 'bgc-blauw'"
            >
              {{ $t("message.action") }}
            </span>
            <span
              v-else
              v-bind:key="tag"
              v-for="tag in message.tags"
              class="badge c-50 rounded-8 fw-normal c-body me-2"
              :class="type == 'company' ? 'bgc-okergeel' : 'bgc-blauw'"
              >{{ tag.tagtext }}</span
            >
            <div
              v-if="message.type == 'message' && allowEdit(message)"
              class="d-inline-block nav"
            >
              <ul class="nav nav-pills p-0">
                <li class="nav-item dropdown p-0">
                  <a
                    class="nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../../assets/icons/Icon-navigation-More-vertical.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        class="dropdown-item"
                        @click="handleEditMessage(message)"
                      >
                        <span class="text-sentence">{{ $t("message.editMessage") }}</span>
                      </button>
                    </li>
                    <li>
                      <button
                        class="dropdown-item"
                        @click="handleDeleteMessage(message)"
                      >
                        <span class="text-sentence">{{ $t("message.deleteMessage") }}</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="position-relative">
          <div class="mb-2 row">
            <div class="col message-body">
              <h3>{{ message.title }}</h3>
              <span v-html="trimmed(message.body)"></span>
            </div>
            <div
              class="d-none d-lg-block col-auto ms-auto mt-auto"
              style="z-index: 9"
            >
              <button
                @click="showMessagePopup(message)"
                class="btn btn-secondary text-uppercase"
              >
                <span>{{ $t("message.readMore") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex d-lg-none message-footer row align-items-end">
          <div class="col-auto ms-auto">
            <button
              @click="showMessagePopup(message)"
              class="btn btn-secondary text-uppercase"
            >
              <span>{{ $t("message.readMore") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="messages.length < 1">
        <p class="text-sentence d-block text-sentence">
          {{ $t("message.no_messages_available") }}
        </p>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("message.loading") }}...</span>
      </div>
    </div>
  </div>
</template>
<script>
import Search from "@/views/components/simple/Search";
import qs from "qs";
import MessageModal from "@/views/components/modals/MessageModal";
import moment from "moment";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import NewMessageModal from "@/views/components/modals/NewMessageModal";

export default {
  components: { Search },
  props: ["type"],
  data() {
    return {
      message_type: this.type,
      moment: moment,
      messages: [],
      filterDate: "",
      query: {},
      loaded: false,
    };
  },
  mounted() {
    this.getMessages();
  },
  methods: {
    trimmed(text) {
      return text;
    },
    allowEdit(message) {
      return (
        (this.user.swarms &&
          this.user.swarms
            .map((entity) => parseInt(entity.id))
            .includes(parseInt(message.teamid))) ||
        this.user.managecompany
      );
    },
    handleEditMessage(message) {
      this.$store.dispatch("openModal", {
        component: NewMessageModal,
        options: { centered: true },
        properties: {
          id: message.id,
          type: this.type,
        },
        events: {
          submitModal: this.getMessages,
        },
      });
    },
    handleDeleteMessage(message) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        options: { centered: true },
        properties: {
          title: this.$t('message.this_message'),
          id: message.id,
        },
        events: {
          submitModal: this.deleteMessage,
        },
      });
    },
    async deleteMessage(id) {
      await this.axios.delete("/messages/" + id);
      await this.getMessages();
    },
    handleDate() {
      // console.log(this.filterDate);
      if (this.filterDate) {
        this.query["created_at_gte"] = moment(this.filterDate)
          .startOf("day")
          .toISOString();
        this.query["created_at_lte"] = moment(this.filterDate)
          .endOf("day")
          .toISOString();
      } else {
        delete this.query["created_at_gte"];
        delete this.query["created_at_lte"];
      }
      // this.getMessages();
    },
    switchMessageType(type) {
      this.messages = [];
      this.loaded = false;
      this.message_type = type;
      this.getMessages();
      this.$emit("changeType", type);
    },
    async getMessages(/*filter = ''*/) {
      // console.log('getting messages')
      const request = await this.axios.get(
        "/actions/" +
          this.message_type +
          "/messages?" +
          qs.stringify(this.query)
      );
      this.messages = request.data;
      this.loaded = true;
      if (this.$route.name === "actions") {
        this.mitt.emit("loadcomplete", { complete: true });
      }
    },
    handleSearch(val) {
      this.loaded = false
      this.query["body_contains"] = val;
      this.getMessages(/*'?body_contains=' + val*/);
    },
    showMessagePopup(message) {
      this.$store.dispatch("openModal", {
        component: MessageModal,
        options: { clickOutside: true },
        properties: {
          message: message,
          type: this.type,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.message-item-list {
  .message-body {
    max-height: 100px;
    overflow-y: hidden;
    //position: relative;
  }

  &.truncated {
    .message-body:before {
      content: "";
      position: absolute;

      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(0, 106, 179, 0) 0%,
        rgba(184, 213, 234, 0) 50%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 100%;
      width: 100%;
    }
  }
}
</style>