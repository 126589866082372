<template>
  <form @submit.prevent="handleSubmit" class="modal-content rounded-16 overflow-hidden">
    <div class="modal-header c-50" :class="'bgc-koraalrood'">
      <div :class="'c-koraalrood'" class=" c-200 text-sm modal-title w-100 flex-grow-1">
        <span class="text-sentence">{{ $t('message.confirm_deletion') }}</span>
      </div>
      <button type="button" class="d-block ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <span class="text-sentence">{{ $t('message.confirm_delete', { title }) }}</span>
    </div>
    <div class="modal-footer">
      <button type="button" data-bs-dismiss="modal" class="btn btn-secondary"><span class="text-sentence">{{ $t('message.cancel') }}</span></button>
      <button class="btn btn-danger"><span class="text-sentence">{{ $t('message.delete') }}</span></button>
    </div>
  </form>
</template>
<script>

export default {
  emits: ['submitModal'],
  methods: {
    handleSubmit() {
      this.$emit('submitModal', this.id);
      this.$store.dispatch('closeModal')
    }
  },
  props: [
    'title','id'
  ],

}
</script>