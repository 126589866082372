// import axios from "@/axios-auth";

export default {
    async fetchSettings(context) {
        const get = await context.rootState.auth.axios.get('/settings');
        const settingsObj = get.data
        context.commit('setSettings', settingsObj)
    },

    async fetchPublicSettings(context) {
        const settings = await context.rootState.auth.axios.get('/settings/1');
        context.commit('setPublicSettings', settings.data)
    },

    async submitSettings(context) {
        const settings = context.getters.getSettings;
        const { data } = await context.rootState.auth.axios.put('/settings/' + settings.id, settings);
        context.commit('setSettings', data);
        context.dispatch('fetchPublicSettings');
    }
}